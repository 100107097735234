import { StageTwoImages } from "Assets/StageTwo";
import { ROUTES, SOCKET_EVENTS, VIDEO_ROUTES } from "Constants/Common";
import {
  setShowCombinationLockOne,
  setShowCombinationLockTwo,
  setTableView,
} from "Store/StageTwo";
import { socket } from "context/socket";

export const getBaseImage = (tableViewOn, stageTwoSolved) => {
  return stageTwoSolved
    ? StageTwoImages.door_highlighted
    : tableViewOn
      ? StageTwoImages.table_zoom_base
      : StageTwoImages.base_image;
};

export const ObjectsToHighlight = {
  chessBoard: {
    name: "chessBoard",
    isVisible: (tableViewOn, stageTwoSolved) => {
      return !tableViewOn && !stageTwoSolved;
    },
    onClick: function (navigate, state, dispatch) {
      navigate(ROUTES.CHESS);
    },
    position: {
      x: 79,
      y: 57,
    },
    height: "calc(3vh + 3vw)",
    width: "calc(8vh + 8vw)",
    getImage: () => {
      return StageTwoImages.chess_board_highlighted;
    },
    path: "",
  },
  chest: {
    name: "chest",
    isVisible: (tableViewOn, stageTwoSolved) => {
      return !tableViewOn && !stageTwoSolved;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(setShowCombinationLockOne(true));
    },
    position: {
      x: 27,
      y: 62,
    },
    height: "calc(5vh + 5vw)",
    width: "calc(10vh + 10vw)",
    getImage: () => {
      return StageTwoImages.chest_highlighted;
    },
    path: "",
  },
  table: {
    name: "table",
    isVisible: (tableViewOn, stageTwoSolved) => {
      return !tableViewOn && !stageTwoSolved;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(setTableView(true));
    },
    position: {
      x: 45,
      y: 55,
    },
    height: "calc(4vh + 4vw)",
    width: "calc(10vh + 10vw)",
    getImage: () => {
      return StageTwoImages.table_highlighted;
    },
    path: "",
  },
  crossword: {
    name: "crossword",
    isVisible: (tableViewOn, stageTwoSolved) => {
      return tableViewOn && !stageTwoSolved;
    },
    onClick: function (navigate, state, dispatch) {
      navigate(ROUTES.CROSSWORD);
    },
    position: {
      x: 55,
      y: 75,
    },
    height: "calc(4vh + 4vw)",
    width: "calc(14vh + 14vw)",
    getImage: () => {
      return StageTwoImages.table_zoom_papers_highlighted;
    },
    path: "",
  },
  box: {
    name: "box",
    isVisible: (tableViewOn, stageTwoSolved) => {
      return tableViewOn && !stageTwoSolved;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(setShowCombinationLockTwo(true));
    },
    position: {
      x: 15,
      y: 38,
    },
    height: "calc(4vh + 4vw)",
    width: "calc(12vh + 12vw)",
    getImage: () => {
      return StageTwoImages.table_zoom_box_highlighted;
    },
    path: "",
  },
  door: {
    name: "door",
    isVisible: (_, stageTwoSolved) => {
      return stageTwoSolved;
    },
    onClick: function (navigate, state, dispatch) {
      socket.emit(SOCKET_EVENTS.STAGE_TWO_DOOR_UNLOCKED, {}, () => {
        navigate(VIDEO_ROUTES.FIVE);
      });
    },
    position: {
      x: 98,
      y: 50,
    },
    height: "calc(15vh + 15vw)",
    width: "calc(4vh + 4vw)",
    getImage: () => {
      return StageTwoImages.door_highlighted;
    },
    path: "",
  },
};
