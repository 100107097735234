export const crossword_data = {
  "0X0": "",
  "0X1": "",
  "0X2": "",
  "0X3": "",
  "0X4": "",
  "0X5": "",
  "0X6": "",
  "0X7": "",
  "0X8": "",
  "0X9": {
    number: 1,
    correctValue: "P",
  },
  "0X10": "",
  "0X11": "",
  "0X12": "",
  "0X13": "",
  "0X14": "",
  "0X15": "",
  "0X16": "",
  "0X17": "",
  "0X18": "",
  "0X19": "",
  "0X20": "",
  "0X21": "",
  "1X0": "",
  "1X1": "",
  "1X2": "",
  "1X3": "",
  "1X4": "",
  "1X5": "",
  "1X6": "",
  "1X7": "",
  "1X8": {
    number: 2,
    correctValue: "Z",
    direction: "down",
  },
  "1X9": { correctValue: "O" },
  "1X10": { correctValue: "O" },
  "1X11": "",
  "1X12": "",
  "1X13": "",
  "1X14": { number: 3, correctValue: "C" },
  "1X15": "",
  "1X16": "",
  "1X17": "",
  "1X18": "",
  "1X19": "",
  "1X20": "",
  "1X21": "",
  "2X0": "",
  "2X1": "",
  "2X2": "",
  "2X3": "",
  "2X4": { number: 4, correctValue: "J" },
  "2X5": "",
  "2X6": "",
  "2X7": "",
  "2X8": "",
  "2X9": { correctValue: "T" },
  "2X10": "",
  "2X11": "",
  "2X12": "",
  "2X13": "",
  "2X14": { correctValue: "O" },
  "2X15": "",
  "2X16": "",
  "2X17": "",
  "2X18": "",
  "2X19": "",
  "2X20": "",
  "2X21": "",
  "3X0": "",
  "3X1": "",
  "3X2": "",
  "3X3": "",
  "3X4": { correctValue: "E" },
  "3X5": "",
  "3X6": "",
  "3X7": "",
  "3X8": "",
  "3X9": { correctValue: "T" },
  "3X10": "",
  "3X11": "",
  "3X12": "",
  "3X13": "",
  "3X14": { correctValue: "N" },
  "3X15": "",
  "3X16": "",
  "3X17": "",
  "3X18": "",
  "3X19": "",
  "3X20": "",
  "3X21": "",
  "4X0": "",
  "4X1": "",
  "4X2": "",
  "4X3": "",
  "4X4": { number: 5, correctValue: "W" },
  "4X5": { correctValue: "I" },
  "4X6": { correctValue: "L" },
  "4X7": { correctValue: "L" },
  "4X8": { correctValue: "I" },
  "4X9": { correctValue: "A" },
  "4X10": { correctValue: "M" },
  "4X11": "",
  "4X12": "",
  "4X13": "",
  "4X14": { correctValue: "Q" },
  "4X15": "",
  "4X16": "",
  "4X17": "",
  "4X18": "",
  "4X19": "",
  "4X20": "",
  "4X21": "",
  "5X0": { number: 6, correctValue: "W" },
  "5X1": { correctValue: "H" },
  "5X2": { correctValue: "I" },
  "5X3": { correctValue: "T" },
  "5X4": { correctValue: "E" },
  "5X5": "",
  "5X6": "",
  "5X7": "",
  "5X8": "",
  "5X9": { correctValue: "G" },
  "5X10": "",
  "5X11": "",
  "5X12": { number: 7, correctValue: "C" },
  "5X13": "",
  "5X14": { correctValue: "U" },
  "5X15": "",
  "5X16": "",
  "5X17": "",
  "5X18": "",
  "5X19": "",
  "5X20": "",
  "5X21": "",
  "6X0": "",
  "6X1": "",
  "6X2": "",
  "6X3": "",
  "6X4": { correctValue: "L" },
  "6X5": "",
  "6X6": { number: 8, correctValue: "L" },
  "6X7": { correctValue: "I" },
  "6X8": { correctValue: "M" },
  "6X9": { correctValue: "E" },
  "6X10": { number: 9, correctValue: "S" },
  "6X11": { correctValue: "T", highlightOnSolve: true },
  "6X12": { correctValue: "O" },
  "6X13": { correctValue: "N" },
  "6X14": { correctValue: "E" },
  "6X15": "",
  "6X16": "",
  "6X17": "",
  "6X18": "",
  "6X19": "",
  "6X20": "",
  "6X21": "",
  "7X0": "",
  "7X1": "",
  "7X2": "",
  "7X3": "",
  "7X4": { correctValue: "S" },
  "7X5": "",
  "7X6": "",
  "7X7": "",
  "7X8": "",
  "7X9": "",
  "7X10": { correctValue: "I" },
  "7X11": "",
  "7X12": { correctValue: "P" },
  "7X13": "",
  "7X14": { correctValue: "R" },
  "7X15": "",
  "7X16": "",
  "7X17": "",
  "7X18": { number: 10, correctValue: "F" },
  "7X19": "",
  "7X20": "",
  "7X21": "",
  "8X0": "",
  "8X1": "",
  "8X2": "",
  "8X3": "",
  "8X4": "",
  "8X5": { number: 11, correctValue: "C" },
  "8X6": "",
  "8X7": "",
  "8X8": "",
  "8X9": "",
  "8X10": { correctValue: "X" },
  "8X11": "",
  "8X12": { correctValue: "P" },
  "8X13": "",
  "8X14": { correctValue: "O" },
  "8X15": "",
  "8X16": { number: 12, correctValue: "T" },
  "8X17": { correctValue: "H" },
  "8X18": { correctValue: "A" },
  "8X19": { correctValue: "M" },
  "8X20": { correctValue: "E" },
  "8X21": { correctValue: "S" },
  "9X0": "",
  "9X1": "",
  "9X2": "",
  "9X3": "",
  "9X4": "",
  "9X5": { correctValue: "O", highlightOnSolve: true },
  "9X6": "",
  "9X7": "",
  "9X8": "",
  "9X9": "",
  "9X10": { correctValue: "T" },
  "9X11": "",
  "9X12": { correctValue: "E" },
  "9X13": "",
  "9X14": { correctValue: "R" },
  "9X15": "",
  "9X16": "",
  "9X17": "",
  "9X18": { correctValue: "W" },
  "9X19": "",
  "9X20": "",
  "9X21": "",
  "10X0": "",
  "10X1": { number: 13, correctValue: "D", highlightOnSolve: true },
  "10X2": "",
  "10X3": "",
  "10X4": "",
  "10X5": { correctValue: "N" },
  "10X6": "",
  // "10X7": { number: 14, correctValue: "C", secondCorrectValue: "K" },
  "10X7": { number: 14, correctValue: "C" },
  "10X8": "",
  "10X9": "",
  "10X10": { correctValue: "H" },
  "10X11": "",
  "10X12": { correctValue: "R" },
  "10X13": "",
  "10X14": "",
  "10X15": "",
  "10X16": "",
  "10X17": "",
  "10X18": { correctValue: "K" },
  "10X19": "",
  "10X20": "",
  "10X21": "",
  "11X0": { number: 15, correctValue: "R" },
  "11X1": { correctValue: "A" },
  "11X2": { correctValue: "V" },
  "11X3": { correctValue: "E" },
  "11X4": { correctValue: "N" },
  "11X5": { correctValue: "S" },
  "11X6": "",
  "11X7": { correctValue: "A" },
  "11X8": "",
  "11X9": { number: 16, correctValue: "B" },
  "11X10": "",
  "11X11": "",
  "11X12": { correctValue: "N" },
  "11X13": "",
  "11X14": "",
  "11X15": "",
  "11X16": "",
  "11X17": "",
  "11X18": { correctValue: "E" },
  "11X19": "",
  "11X20": "",
  "11X21": "",
  "12X0": "",
  "12X1": { correctValue: "I" },
  "12X2": "",
  "12X3": "",
  "12X4": "",
  "12X5": { correctValue: "T" },
  "12X6": "",
  "12X7": { correctValue: "T" },
  "12X8": "",
  "12X9": { correctValue: "E" },
  "12X10": "",
  "12X11": { number: 17, correctValue: "F" },
  "12X12": { correctValue: "O" },
  "12X13": { correctValue: "R" },
  "12X14": { correctValue: "T" },
  "12X15": { correctValue: "R", highlightOnSolve: true },
  "12X16": { correctValue: "E" },
  "12X17": { correctValue: "S" },
  "12X18": { correctValue: "S" },
  "12X19": "",
  "12X20": "",
  "12X21": "",
  "13X0": "",
  "13X1": { correctValue: "L" },
  "13X2": "",
  "13X3": "",
  "13X4": "",
  "13X5": { correctValue: "A" },
  "13X6": "",
  "13X7": { correctValue: "H" },
  "13X8": "",
  "13X9": { correctValue: "H" },
  "13X10": "",
  "13X11": "",
  "13X12": { correctValue: "S" },
  "13X13": "",
  "13X14": "",
  "13X15": "",
  "13X16": "",
  "13X17": "",
  "13X18": "",
  "13X19": "",
  "13X20": "",
  "13X21": "",
  "14X0": "",
  "14X1": { correctValue: "Y" },
  "14X2": "",
  "14X3": "",
  "14X4": "",
  "14X5": { number: 18, correctValue: "B" },
  "14X6": { correctValue: "E" },
  "14X7": { correctValue: "E" },
  "14X8": { correctValue: "F" },
  "14X9": { correctValue: "E" },
  "14X10": { correctValue: "A" },
  "14X11": { correctValue: "T" },
  "14X12": { correctValue: "E" },
  "14X13": { number: 19, correctValue: "R" },
  "14X14": { correctValue: "S" },
  "14X15": "",
  "14X16": "",
  "14X17": "",
  "14X18": "",
  "14X19": "",
  "14X20": "",
  "14X21": "",
  "15X0": "",
  "15X1": "",
  "15X2": "",
  "15X3": "",
  "15X4": "",
  "15X5": { correctValue: "L" },
  "15X6": "",
  "15X7": { correctValue: "R" },
  "15X8": "",
  "15X9": { correctValue: "A" },
  "15X10": "",
  "15X11": "",
  "15X12": "",
  "15X13": { correctValue: "A" },
  "15X14": "",
  "15X15": "",
  "15X16": "",
  "15X17": "",
  "15X18": "",
  "15X19": "",
  "15X20": "",
  "15X21": "",
  "16X0": "",
  "16X1": "",
  "16X2": "",
  "16X3": "",
  "16X4": "",
  "16X5": { correctValue: "E" },
  "16X6": "",
  "16X7": { correctValue: "I" },
  "16X8": "",
  "16X9": { correctValue: "D" },
  "16X10": "",
  "16X11": { number: 20, correctValue: "M" },
  "16X12": { correctValue: "I" },
  "16X13": { correctValue: "N" },
  "16X14": { correctValue: "T" },
  "16X15": "",
  "16X16": "",
  "16X17": "",
  "16X18": "",
  "16X19": "",
  "16X20": "",
  "16X21": "",
  "17X0": "",
  "17X1": "",
  "17X2": "",
  "17X3": "",
  "17X4": "",
  "17X5": "",
  "17X6": "",
  "17X7": { correctValue: "N" },
  "17X8": "",
  "17X9": { correctValue: "I" },
  "17X10": "",
  "17X11": "",
  "17X12": "",
  "17X13": { correctValue: "U", highlightOnSolve: true },
  "17X14": "",
  "17X15": "",
  "17X16": "",
  "17X17": "",
  "17X18": "",
  "17X19": "",
  "17X20": "",
  "17X21": "",
  "18X0": "",
  "18X1": "",
  "18X2": "",
  "18X3": "",
  "18X4": "",
  "18X5": "",
  "18X6": "",
  "18X7": { correctValue: "E" },
  "18X8": "",
  "18X9": { correctValue: "N" },
  "18X10": "",
  "18X11": "",
  "18X12": "",
  "18X13": { correctValue: "L" },
  "18X14": "",
  "18X15": "",
  "18X16": "",
  "18X17": "",
  "18X18": "",
  "18X19": "",
  "18X20": "",
  "18X21": "",
  "19X0": "",
  "19X1": "",
  "19X2": "",
  "19X3": "",
  "19X4": "",
  "19X5": "",
  "19X6": "",
  "19X7": "",
  "19X8": "",
  "19X9": { correctValue: "G" },
  "19X10": "",
  "19X11": "",
  "19X12": "",
  "19X13": { correctValue: "F" },
  "19X14": "",
  "19X15": "",
  "19X16": "",
  "19X17": "",
  "19X18": "",
  "19X19": "",
  "19X20": "",
  "19X21": "",
};

export const hints = {
  Across: [],
  Down: [],
};
