import ColorControl from "Components/ColorControl/ColorControl";
import DrawArea from "Components/DrawArea/DrawArea";
import React, { useContext, useEffect, useState } from "react";

import "./DrawTheKing.scss";
import { SocketContext } from "context/socket";
import { SOCKET_EVENTS, VIDEO_ROUTES } from "Constants/Common";
import {
  clearBoard,
  fillCells,
  rejectDrawing,
  setSavedColors,
  setShowContinueButton,
} from "Store/DrawTheKing";
import { useDispatch, useSelector } from "react-redux";
import KingImagePopup from "Components/KingImagePopUp/KingImagePopup";
import PopUp from "Components/PopUp/PopUp";
import { MESSAGES } from "Constants/DrawTheKing";
import { useNavigate } from "react-router-dom";
import { CommonImages } from "Assets/Common";

function DrawTheKing() {
  const { showAllowStageFourPopup, drawingRejectedOnce } = useSelector(
    (state) => state.drawTheKing,
  );

  const { isHost } = useSelector((state) => state.common);

  const socket = useContext(SocketContext);

  const [showKingImage, setShowKingImage] = useState(false);
  const [showClearPopup, setShowClearPopup] = useState(false);
  const [showConfirmSubmitPopup, setShowConfirmSubmitPopup] = useState(false);
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showMissonPassedPopup, setShowMissonPassedPopup] = useState(false);
  const [showMissonFailedPopup, setShowMissonFailedPopup] = useState(false);
  const [showScreenNudgePopup, setShowScreenNudgePopup] = useState(false);
  const [drawingSubmitted, setDrawingSubmitted] = useState(false);
  const [drawingRejectedTwice, setDrawingRejectedTwice] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    socket.on(SOCKET_EVENTS.SUBMIT_DRAWING, () => {
      if (isHost) {
        setShowSubmitPopup(true);
      } else {
        setDrawingSubmitted(true);
      }
    });

    socket.on(SOCKET_EVENTS.PASS_DRAWING, () => {
      if (isHost) return;
      setShowMissonPassedPopup(true);
    });

    socket.on(SOCKET_EVENTS.FAIL_DRAWING, () => {
      if (isHost) return;
      setShowMissonFailedPopup(true);
    });

    return () => {
      socket.off(SOCKET_EVENTS.SUBMIT_DRAWING);
      socket.off(SOCKET_EVENTS.PASS_DRAWING);
      socket.off(SOCKET_EVENTS.FAIL_DRAWING);
    };
  }, [socket, isHost]);

  useEffect(() => {
    const socketRoomData = sessionStorage.getItem("socket_room_data");
    if (socketRoomData) {
      const { roomCode } = JSON.parse(socketRoomData);
      socket.emit(SOCKET_EVENTS.SYNC_DRAW_BOARD, { roomCode }, ({ data }) => {
        if (data && data.colorData && data.colorData.length > 0) {
          dispatch(fillCells(data.colorData));
        }

        if (data && data.savedColors && data.savedColors.length > 0) {
          dispatch(setSavedColors(data.savedColors));
        }
      });
    }
  }, [socket, dispatch]);

  return (
    <>
      {/* DEV BUTTONS */}
      {isHost && (
        <div
          className="stageOne_skip"
          onClick={() => {
            navigate(VIDEO_ROUTES.TEN);
          }}
        >
          <div
            className="common_btn"
            style={{
              position: "absolute",
              top: "0%",
              right: "0%",
              zIndex: "3",
            }}
          >
            Skip
          </div>
        </div>
      )}
      {/*  DEV BUTTONS END */}
      {/* POPUPS */}
      {showClearPopup && (
        <PopUp
          message={MESSAGES.CLEAR_POPUP.MESSAGE}
          subMessage={MESSAGES.CLEAR_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.CLEAR_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            socket.emit(SOCKET_EVENTS.CLEAR_BOARD, {}, (data) => {
              // clear complete board
              dispatch(clearBoard());
            });
            setShowClearPopup(false);
          }}
          onCancel={() => {
            setShowClearPopup(false);
          }}
        />
      )}

      {showConfirmSubmitPopup && (
        <PopUp
          message={MESSAGES.CONFIRM_SUBMIT_POPUP.MESSAGE}
          subMessage={MESSAGES.CONFIRM_SUBMIT_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.CONFIRM_SUBMIT_POPUP.OK_BUTTON_TEXT}
          cancelBtnText={MESSAGES.CONFIRM_SUBMIT_POPUP.CANCEL_BUTTON_TEXT}
          onOk={() => {
            socket.emit(SOCKET_EVENTS.SUBMIT_DRAWING, {}, (data) => {
              if (data.error) {
                console.error(data.error);
                return;
              }
              setDrawingSubmitted(true);
            });
            setShowConfirmSubmitPopup(false);
          }}
          onCancel={() => {
            setShowConfirmSubmitPopup(false);
          }}
        />
      )}

      {showSubmitPopup && (
        <PopUp
          message={MESSAGES.SUBMIT_POPUP.MESSAGE}
          subMessage={MESSAGES.SUBMIT_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.SUBMIT_POPUP.OK_BUTTON_TEXT}
          cancelBtnText={MESSAGES.SUBMIT_POPUP.CANCEL_BUTTON_TEXT}
          onOk={() => {
            dispatch(setShowContinueButton());
            setShowScreenNudgePopup(true);
            setShowSubmitPopup(false);

            socket.emit(SOCKET_EVENTS.PASS_DRAWING, {}, (data) => {
              if (data.error) {
                console.error(data.error);
                return;
              }
            });
          }}
          onCancel={() => {
            if (!drawingRejectedOnce) {
              // navigate to a video to come back to this stage
              dispatch(rejectDrawing());
              // navigate after 2.5 seconds
              setTimeout(() => {
                navigate(VIDEO_ROUTES.SIX);
              }, 2500);
            } else {
              // one rejection already done so show continue button with rejected twice flag set to true to use it in allow stage four popup
              setDrawingRejectedTwice(true);
              dispatch(setShowContinueButton());
            }
            socket.emit(SOCKET_EVENTS.FAIL_DRAWING, {}, (data) => {
              if (data.error) {
                console.error(data.error);
                return;
              }
            });
            setShowSubmitPopup(false);
          }}
        />
      )}

      {showAllowStageFourPopup && (
        <PopUp
          message={MESSAGES.ALLOW_STAGE_FOUR_POPUP.MESSAGE}
          subMessage={MESSAGES.ALLOW_STAGE_FOUR_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.ALLOW_STAGE_FOUR_POPUP.OK_BUTTON_TEXT}
          cancelBtnText={MESSAGES.ALLOW_STAGE_FOUR_POPUP.CANCEL_BUTTON_TEXT}
          onOk={() => {
            if (drawingRejectedTwice) {
              navigate(VIDEO_ROUTES.SEVEN);
            } else {
              navigate(VIDEO_ROUTES.NINE);
            }
          }}
          onCancel={() => {
            if (drawingRejectedTwice) {
              navigate(VIDEO_ROUTES.EIGHT);
            } else {
              navigate(VIDEO_ROUTES.TEN);
            }
          }}
        />
      )}

      {showScreenNudgePopup && (
        <PopUp
          message={MESSAGES.SCREENSHOT_NUDGE_POPUP.MESSAGE}
          subMessage={MESSAGES.SCREENSHOT_NUDGE_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.SCREENSHOT_NUDGE_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            setShowScreenNudgePopup(false);
          }}
          noCancel={true}
        />
      )}

      {showMissonPassedPopup && (
        <div className="mission_end_popup">
          <div className="mission_end_popup_img">
            <img src={CommonImages.passed} alt="" />
          </div>
        </div>
      )}

      {showMissonFailedPopup && (
        <div className="mission_end_popup">
          <div className="mission_end_popup_img">
            <img src={CommonImages.failed} alt="" />
          </div>
        </div>
      )}
      {/* POPUPS END */}

      <div className="draw_the_king">
        {showKingImage && (
          <KingImagePopup onClose={() => setShowKingImage(false)} />
        )}
        <div className="draw_components_container">
          <DrawArea disabled={drawingSubmitted} />
          <ColorControl
            drawingSubmitted={drawingSubmitted}
            showKingImage={() => {
              setShowKingImage(true);
            }}
            showClearPopup={() => {
              setShowClearPopup(true);
            }}
            showConfirmSubmitPopup={() => {
              setShowConfirmSubmitPopup(true);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default DrawTheKing;
