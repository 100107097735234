import { createSlice } from "@reduxjs/toolkit";
import { RESOLUTION } from "Constants/DrawTheKing";

const drawTheKing = createSlice({
  name: "drawTheKing",
  initialState: {
    activeColor: "#000000",
    cells: new Array(RESOLUTION.COLS * RESOLUTION.ROWS).fill("transparent"),
    showContinueButton: false,
    showAllowStageFourPopup: false,
    drawingRejectedOnce: false,
    savedColors: [
      "transparent",
      "transparent",
      "transparent",
      "transparent",
      "transparent",
      "transparent",
      "transparent",
      "transparent",
    ],
    baseColors: [
      "#E6E3DE",
      "#A69A8C",
      "#E3B14F",
      "#E4AB93",
      "#6C2D1C",
      "#583715",
      "#0D0C08",
    ],
  },
  reducers: {
    fillCell: (state, action) => {
      const { cellIndex, color } = action.payload;
      state.cells[cellIndex] = color;
    },
    fillCells: (state, action) => {
      const cellData = action.payload;
      state.cells = cellData;
    },
    fillCellArray: (state, action) => {
      const cellData = action.payload;
      for (let i = 0; i < cellData.length; i++) {
        state.cells[cellData[i].cellIndex] = cellData[i].color;
      }
    },
    setActiveColor: (state, action) => {
      state.activeColor = action.payload;
    },
    setSavedColors: (state, action) => {
      state.savedColors = action.payload;
    },
    clearBoard: (state) => {
      state.cells = new Array(RESOLUTION.COLS * RESOLUTION.ROWS).fill(
        "transparent",
      );
    },
    setShowContinueButton: (state, action) => {
      state.showContinueButton = true;
    },
    setShowAllowStageFourPopup: (state, action) => {
      state.showAllowStageFourPopup = true;
    },
    rejectDrawing: (state, action) => {
      state.drawingRejectedOnce = true;
    },
  },
});

export const {
  fillCell,
  fillCells,
  fillCellArray,
  clearBoard,
  rejectDrawing,
  setActiveColor,
  setSavedColors,
  setShowContinueButton,
  setShowAllowStageFourPopup,
} = drawTheKing.actions;

export default drawTheKing.reducer;
