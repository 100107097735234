import { useState, useMemo, useEffect, useCallback, useContext } from "react";
import { generateMaze, solve } from "./util";
import { useNavigate } from "react-router-dom";
import { ROUTES, SOCKET_EVENTS } from "Constants/Common";
import { MazeImages } from "Assets/StageFour";
import PopUp from "Components/PopUp/PopUp";
import { MESSAGES } from "Constants/StageFour";
import { useDispatch, useSelector } from "react-redux";
import {
  hideInstructions,
  setMazeCompelete,
  setPositionsVisited,
  setStatus,
  setUserPosition,
} from "Store/StageFour";

import "./MazeGen.scss";
import { SocketContext } from "context/socket";

const ROWS = 50;
const COLS = 25;
const MAZE_HEIGHT = 3.2;
const MAZE_WIDTH = 1.85;

const MazeGenSeed = {
  one: 0.15,
  two: 0.13,
  three: 0.34,
};

const mazeStyles = {
  background: {
    one: MazeImages.bg.one,
    two: MazeImages.bg.two,
    three: MazeImages.bg.three,
  },

  borderColors: {
    one: "rgba(129, 117, 86, 1)",
    two: "rgba(149, 125, 0, 1)",
    three: "rgba(161, 161, 157, 1)",
  },
};

export default function MazePuzzle({ id }) {
  const navigate = useNavigate();

  const socket = useContext(SocketContext);

  const dispatch = useDispatch();

  const {
    showInstructions,
    userPosition,
    positionsVisited,
    status,
    mazeComplete,
  } = useSelector((state) => state.mazePuzzle);

  const { isHost } = useSelector((state) => state.common);

  const [cheatMode, setCheatMode] = useState(false);

  const [showResetPopup, setShowResetPopup] = useState(false);

  const [showBackButtonAfterSolve, setShowBackButtonAfterSolve] =
    useState(false);

  const maze = useMemo(() => generateMaze(ROWS, COLS, MazeGenSeed[id]), [id]);

  const solution = useMemo(() => {
    const s = new Set();
    const solutionPath = solve(maze, userPosition[id][0], userPosition[id][1]);
    solutionPath.forEach((path) => {
      const [x, y] = path;
      s.add(String(x) + "-" + String(y));
    });
    return s;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maze, id, userPosition[id]]);

  const makeClassName = useCallback(
    (i, j) => {
      const rows = maze.length;
      const cols = maze[0].length;
      let arr = [];
      if (maze[i][j][0] === 0) {
        arr.push("topWall");
      }
      if (maze[i][j][1] === 0) {
        arr.push("rightWall");
      }
      if (maze[i][j][2] === 0) {
        arr.push("bottomWall");
      }
      if (maze[i][j][3] === 0) {
        arr.push("leftWall");
      }
      if (i === rows - 1 && j === cols - 1) {
        arr.push("destination");
      }
      if (i === userPosition[id][0] && j === userPosition[id][1]) {
        arr.push("currentPosition");
      }

      if (cheatMode && solution.has(String(i) + "-" + String(j))) {
        arr.push("sol");
      }
      return arr.join(" ");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maze, userPosition[id], cheatMode, solution],
  );

  useEffect(() => {
    const lastRowIndex = maze.length - 1;
    const lastColIndex = maze[0].length - 1;
    if (
      userPosition[id][0] === lastRowIndex &&
      userPosition[id][1] === lastColIndex
    ) {
      socket.emit(SOCKET_EVENTS.STAGE_FOUR_MAZE_SOLVED, { mazeId: id }, () => {
        dispatch(setMazeCompelete({ mazeId: id }));
        dispatch(setStatus({ mazeId: id, status: "won" }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maze, id, userPosition[id]]);

  // this use effects attaches the event listener to the window for the keydown event
  useEffect(() => {
    const handleMove = (e) => {
      e.preventDefault();
      if (status[id] !== "playing") {
        return;
      }
      const key = e.code;

      const [i, j] = userPosition[id];
      if ((key === "ArrowUp" || key === "KeyW") && maze[i][j][0] === 1) {
        dispatch(setUserPosition({ mazeId: id, position: [i - 1, j] }));
      }
      if ((key === "ArrowRight" || key === "KeyD") && maze[i][j][1] === 1) {
        dispatch(setUserPosition({ mazeId: id, position: [i, j + 1] }));
      }
      if ((key === "ArrowDown" || key === "KeyS") && maze[i][j][2] === 1) {
        dispatch(setUserPosition({ mazeId: id, position: [i + 1, j] }));
      }
      if ((key === "ArrowLeft" || key === "KeyA") && maze[i][j][3] === 1) {
        dispatch(setUserPosition({ mazeId: id, position: [i, j - 1] }));
      }

      dispatch(
        setPositionsVisited({
          mazeId: id,
          positions: {
            ...positionsVisited[id],
            // INFO: This is used to clear the visited cells when the user moves back
            // [`${i}-${j}`]: positionsVisied[`${i}-${j}`] ? false : true,
            // INFO: This is used to keep the visited cells when the user moves back
            [`${i}-${j}`]: true,
          },
        }),
      );
    };

    window.addEventListener("keydown", handleMove);

    return () => {
      window.removeEventListener("keydown", handleMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maze, userPosition, status, positionsVisited, id, dispatch, status]);

  // show the back button 1.5 seconds after puzzle is solved
  useEffect(() => {
    let timeout;
    if (mazeComplete[id]) {
      timeout = setTimeout(() => {
        setShowBackButtonAfterSolve(true);
      }, 1500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mazeComplete[id]]);

  return (
    <>
      {showResetPopup && (
        <PopUp
          message={MESSAGES.RESET_POPUP.MESSAGE}
          subMessage={MESSAGES.RESET_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.RESET_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            dispatch(setUserPosition({ mazeId: id, position: [0, 0] }));
            dispatch(setPositionsVisited({ mazeId: id, positions: {} }));
            setShowResetPopup(false);
          }}
          onCancel={() => {
            setShowResetPopup(false);
          }}
        />
      )}
      {showInstructions && (
        <PopUp
          message={MESSAGES.INSTRUCTIONS.MESSAGE}
          img={MazeImages.instruction.keys}
          okBtnText={MESSAGES.INSTRUCTIONS.OK_BUTTON_TEXT}
          onOk={() => {
            dispatch(hideInstructions());
          }}
          noCancel={true}
        />
      )}
      <div
        className="MazePuzzle"
        tabIndex={-1}
        style={{
          backgroundImage: `url(${mazeStyles.background[id]})`,
        }}
      >
        {mazeComplete[id] && (
          <>
            <div className="maze_complete">
              <img
                className="solved_text"
                src={MazeImages[`mission_pass_${id}`]}
                alt=""
              />
              <img
                className="solved_img"
                src={MazeImages[`solved_jewel_${id}`]}
                alt=""
              />
            </div>
          </>
        )}
        <div
          className="button_container"
          style={{
            width: `${ROWS * MAZE_WIDTH + 2}vw`,
          }}
        >
          {isHost && (
            <div
              className="common_btn"
              onClick={() => {
                setCheatMode(!cheatMode);
              }}
            >
              {cheatMode ? "Hide Path" : "Show Path"}
            </div>
          )}
          {!isHost && (
            <div
              className="common_btn"
              onClick={() => {
                setShowResetPopup(true);
              }}
            >
              Reset
            </div>
          )}
          <div
            className="common_btn"
            onClick={() => {
              navigate(ROUTES.MAZE);
            }}
            style={
              showBackButtonAfterSolve
                ? {
                    position: "absolute",
                    zIndex: 5,
                  }
                : {}
            }
          >
            Back
          </div>
        </div>

        <div
          className={`maze_container maze_container_${id}`}
          style={{
            height: `${COLS * MAZE_HEIGHT + 4}vh`,
            width: `${ROWS * MAZE_WIDTH + 2}vw`,
          }}
        >
          <div className="maze_solve_reward">
            <img src={MazeImages[`jewel_${id}`]} alt="" />
          </div>
          <div className="maze_start">
            <img src={MazeImages.start} alt="" />
          </div>
          <table className={"maze maze_" + id}>
            <tbody>
              {maze.map((row, i) => (
                <tr key={`row-${i}`}>
                  {row.map((cell, j) => (
                    <td
                      key={`cell-${i}-${j}`}
                      className={makeClassName(i, j)}
                      style={{
                        height: `${MAZE_HEIGHT}vh`,
                        width: `${MAZE_WIDTH}vw`,
                      }}
                    >
                      {positionsVisited[id][`${i}-${j}`] &&
                        !makeClassName(i, j).includes("currentPosition") &&
                        !makeClassName(i, j).includes("sol") && (
                          <div className="visited"></div>
                        )}

                      {
                        <div
                          style={
                            makeClassName(i, j).includes("currentPosition")
                              ? {
                                  height: `calc(${MAZE_HEIGHT * 0.4}vh + ${MAZE_WIDTH * 0.2}vw)`,
                                  width: `calc(${MAZE_HEIGHT * 0.4}vh + ${MAZE_WIDTH * 0.2}vw)`,
                                }
                              : {}
                          }
                        />
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <table className="maze_shadow">
            <tbody>
              {maze.map((row, i) => (
                <tr key={`row-${i}`}>
                  {row.map((cell, j) => (
                    <td
                      key={`cell-${i}-${j}`}
                      className={makeClassName(i, j)}
                      style={{
                        height: `${MAZE_HEIGHT}vh`,
                        width: `${MAZE_WIDTH}vw`,
                      }}
                    ></td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
