export const MESSAGES = {
  RESET_POPUP: {
    MESSAGE: "Reset",
    SUBMESSAGE:
      "Are you sure you want to reset the maze? You will be sent back to the beginning.",
    OK_BUTTON_TEXT: "Reset",
  },
  INSTRUCTIONS: {
    MESSAGE: "Keyboard Controls",
    OK_BUTTON_TEXT: "OK",
  },
  END_GAME_POPUP: {
    MESSAGE: "Be Warned",
    SUBMESSAGE: "Are you sure you want to end the game? This cannot be undone!",
    OK_BUTTON_TEXT: "Yes",
    CANCEL_BUTTON_TEXT: "Cancel",
  },
};
