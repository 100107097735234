import React, { useContext, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "context/socket";
import { SOCKET_EVENTS } from "Constants/Common";
import { updateInControlTwo } from "Store/Common";

import "./TakeControlTwo.scss";

function TakeControlTwo({
  topBtn = 0,
  leftBtn = 0,
  topHeading = 0,
  leftHeading = 0,
  position = "absolute",
  dark = false,
}) {
  const {
    inControlTwo,
    inControlPlayerNameTwo,
    playerName,
    isHost,
    participants,
  } = useSelector((state) => state.common);

  const socket = useContext(SocketContext);

  const dispatch = useDispatch();

  const handleTakeControl = () => {
    socket.emit(
      SOCKET_EVENTS.TAKE_CONTROL_TWO,
      {
        playerName,
        inControlTwo: true,
        participants: participants.length
          ? participants.map((participant) => participant)
          : [playerName],
      },
      (data) => {
        if (data.error) {
          console.error(data.error);
          return;
        }

        dispatch(
          updateInControlTwo({
            inControlTwo: true,
            inControlPlayerNameTwo: playerName,
          }),
        );
      },
    );
  };

  useEffect(() => {
    // parse connection recovery data from session storage
    const socket_room_data = JSON.parse(
      sessionStorage.getItem("socket_room_data"),
    );

    const roomCode = socket_room_data?.roomCode;

    socket.emit(SOCKET_EVENTS.SYNC_TAKE_CONTROL, { roomCode }, (data) => {
      if (data.error) {
        console.error(data.error);
        return;
      }

      dispatch(
        updateInControlTwo({
          inControlTwo: data.data.inControlTwo === playerName,
          inControlPlayerNameTwo: data.data.inControlTwo,
        }),
      );
    });
  }, [socket, playerName, dispatch]);

  return (
    <div
      className="takeControlInfoAndBtn"
      style={{
        position,
        top: topBtn,
        left: leftBtn,
      }}
    >
      <div
        className={`common_btn takeControlBtn ${inControlTwo || isHost ? "takeControlBtnDisbaled" : ""}`}
        onClick={handleTakeControl}
      >
        Take Control
      </div>
      <div
        className={`takeControlInfo ${dark ? "takeControlInfoDark" : ""}`}
        style={{
          top: topHeading,
          left: leftHeading,
        }}
      >
        <span className="takeControlInfo_label">Currently in control</span>
        <span
          className={`takeControlInfo_value ${dark ? "takeControlInfo_value_dark" : ""}`}
        >
          {inControlPlayerNameTwo}
          {inControlTwo ? " (Me)" : ""}
        </span>
      </div>
    </div>
  );
}

export default TakeControlTwo;
