import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SocketContext } from "context/socket";

import Timeline from "Components/Timeline/Timeline";

import { checkIsInside, checkIsOverlapping } from "Shared/Utilities";
import {
  MESSAGES,
  MUSIC_BOARD_DIVISION,
  MUSIC_BOARD_DURATION_IN_SECONDS,
  MUSIC_BOARD_WIDTH_IN_PIXELS,
  PIXEL_TO_SECOND_OFFSET,
  ROWS_ENUM,
} from "Constants/StageOne";

import HornOne from "Assets/Build/Audios/horn_one.mp3";
import HornTwo from "Assets/Build/Audios/horn_two.mp3";
import HornThree from "Assets/Build/Audios/horn_three.mp3";

import ClarinetOne from "Assets/Build/Audios/clarinet_one.mp3";
import ClarinetTwo from "Assets/Build/Audios/clarinet_two.mp3";
import ClarinetThree from "Assets/Build/Audios/clarinet_three.mp3";
import ClarinetFour from "Assets/Build/Audios/clarinet_four.mp3";
import ClarinetFive from "Assets/Build/Audios/clarinet_five.mp3";

import DrumOne from "Assets/Build/Audios/drum_one.mp3";
import DrumTwo from "Assets/Build/Audios/drum_two.mp3";
import DrumThree from "Assets/Build/Audios/drum_three.mp3";
import DrumFour from "Assets/Build/Audios/drum_four.mp3";

import HornFail from "Assets/Build/Audios/HornsFailSound.mp3";
import ClarinetFail from "Assets/Build/Audios/ClarinetFailSound.mp3";
import DrumFail from "Assets/Build/Audios/DrumFailSound.mp3";

import {
  setHintOneUsed,
  setHintTwoUsed,
  setInstrcutionsSeen,
  updateDraggableObjectLastStopPositionAndLastTargetRow,
  updateDraggableObjectPosition,
  updateDraggableObjectPositionInSeconds,
  updateDraggableObjectTargetRow,
  updateIsTimelinePlaying,
  updateSilentStateInstrument,
} from "Store/StageOne";

import "./StageOne.scss";

import { StageOneImages } from "Assets/StageOne";

import DraggableSound from "Components/DraggableSound/DraggableSound";
import { SOCKET_EVENTS, VIDEO_ROUTES } from "Constants/Common";
import { CommonImages, InstructionScreen } from "Assets/Common";
import TakeControl from "Components/TakeControl/TakeControl";
import PopUp from "Components/PopUp/PopUp";
import VolSlider from "Components/VolSlider/VolSlider";
import { initialPositions } from "StaticData/MusicPuzzle";
import InstructionPop from "Components/InstructionPop/InstructionPop";

let scale = 1;
let scaleOffset = 1;
let stageOneBtnContainerHeightOffsetMultiplier = 1;

const initialHeightMultiplierRowOne = 2.2;
const initialHeightMultiplierRowTwo = 3;

if (window.innerWidth < 1300) {
  scale = 0.8;
  scaleOffset = 1.25;
  stageOneBtnContainerHeightOffsetMultiplier = 3.8;
} else if (window.innerHeight < 750) {
  scale = 0.9;
  scaleOffset = 1.15;
  stageOneBtnContainerHeightOffsetMultiplier = 3.3;
} else if (window.innerHeight < 950) {
  scale = 1.15;
  scaleOffset = 0.9;
  stageOneBtnContainerHeightOffsetMultiplier = 2.6;
} else if (window.innerHeight > 950 && window.innerHeight < 1200) {
  scale = 1.25;
  scaleOffset = 0.75;
  stageOneBtnContainerHeightOffsetMultiplier = 2.4;
} else if (window.innerHeight > 1200 && window.innerHeight < 1450) {
  scale = 1.55;
  scaleOffset = 0.65;
  stageOneBtnContainerHeightOffsetMultiplier = 1.8;
} else {
  scale = 1.8;
  scaleOffset = 0.55;
  stageOneBtnContainerHeightOffsetMultiplier = 1.55;
}

const instructionDataArray = [
  {
    img: "",
    title: "Instructions",
    description:
      "Helpeth the Gatekeeper unlock his musical parchment by placing the scattered music pieces on the board to create a  secret song.",
  },
  {
    img: InstructionScreen.music_puzzle.gifs.two,
    title: "",
    description:
      "Preview any piece in the pool by clicking the Play button on that piece.",
  },
  {
    img: InstructionScreen.music_puzzle.gifs.three,
    title: "",
    description:
      "When ready, click 'Take Control' and drag the piece onto the parchment. Only one player from the team can be in control at any time.",
  },
  {
    img: InstructionScreen.music_puzzle.gifs.four,
    title: "",
    description:
      "Click the ‘Play’ button or press the <Space> bar to play the song. Jump forward or backward by dragging the seeker or clicking at any point in the timestamp area.",
  },
  {
    img: InstructionScreen.music_puzzle.gifs.five,
    title: "",
    description: "Mute any of the tracks to focus on specific instruments.",
  },
  {
    img: InstructionScreen.music_puzzle.gifs.six,
    title: "",
    description:
      "Tip: There are 9 clips that must be placed correctly on the parchment. Only one of these clips will not touch any other clips.",
  },
];

// const audios = {
//   one: new Audio(AudioFiles.horn.one),
//   two: new Audio(AudioFiles.horn.two),
//   three: new Audio(AudioFiles.horn.three),

//   four: new Audio(AudioFiles.clarinet.one),
//   five: new Audio(AudioFiles.clarinet.two),
//   six: new Audio(AudioFiles.clarinet.three),
//   seven: new Audio(AudioFiles.clarinet.four),
//   eight: new Audio(AudioFiles.clarinet.five),

//   nine: new Audio(AudioFiles.drum.one),
//   ten: new Audio(AudioFiles.drum.two),
//   eleven: new Audio(AudioFiles.drum.three),
//   twelve: new Audio(AudioFiles.drum.four),
// };

const audios = {
  one: new Audio(HornOne),
  two: new Audio(HornTwo),
  three: new Audio(HornThree),

  four: new Audio(ClarinetOne),
  five: new Audio(ClarinetTwo),
  six: new Audio(ClarinetThree),
  seven: new Audio(ClarinetFour),
  eight: new Audio(ClarinetFive),

  nine: new Audio(DrumOne),
  ten: new Audio(DrumTwo),
  eleven: new Audio(DrumThree),
  twelve: new Audio(DrumFour),
};

// const failAudios = {
//   one: new Audio(AudioFiles.horn.fail),
//   two: new Audio(AudioFiles.clarinet.fail),
//   three: new Audio(AudioFiles.drum.fail),
// };

const failAudios = {
  one: new Audio(HornFail),
  two: new Audio(ClarinetFail),
  three: new Audio(DrumFail),
};

function StageOne() {
  const {
    draggableObjects,
    timeline: { cursorPositionInSeconds, timelinePlaying },
    instruments,
    correctPositionsX,
    firstHintUsed,
    secondHintUsed,
    volume,
    instructionsSeen,
  } = useSelector((state) => state.stageOne);

  const { isHost, inControl } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const socket = useContext(SocketContext);

  const [showResetPopup, setShowResetPopup] = useState(false);
  const [showHintOnePopup, setShowHintOnePopup] = useState(false);
  const [showCompleteAnimation, setShowCompleteAnimation] = useState(false);
  const [showHintTwoPopup, setShowHintTwoPopup] = useState(false);
  const [pieceSynced, setpieceSynced] = useState(false);

  const [audiosPlaying, setAudiosPlaying] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
    nine: false,
    ten: false,
    eleven: false,
    twelve: false,
  });

  const draggableRefOne = useRef(null);
  const draggableRefTwo = useRef(null);
  const draggableRefThree = useRef(null);
  const draggableRefFour = useRef(null);
  const draggableRefFive = useRef(null);
  const draggableRefSix = useRef(null);
  const draggableRefSeven = useRef(null);
  const draggableRefEight = useRef(null);
  const draggableRefNine = useRef(null);
  const draggableRefTen = useRef(null);
  const draggableRefEleven = useRef(null);
  const draggableRefTwelve = useRef(null);

  const draggableReferences = {
    one: draggableRefOne,
    two: draggableRefTwo,
    three: draggableRefThree,
    four: draggableRefFour,
    five: draggableRefFive,
    six: draggableRefSix,
    seven: draggableRefSeven,
    eight: draggableRefEight,
    nine: draggableRefNine,
    ten: draggableRefTen,
    eleven: draggableRefEleven,
    twelve: draggableRefTwelve,
  };

  const targetReferencesOne = useRef(null);
  const targetReferencesTwo = useRef(null);
  const targetReferencesThree = useRef(null);

  const targetReferences = {
    one: targetReferencesOne,
    two: targetReferencesTwo,
    three: targetReferencesThree,
  };

  const positionByTargetRowMultiplier = useMemo(() => {
    return {
      [ROWS_ENUM.ONE]: -1,
      [ROWS_ENUM.TWO]: 0,
      [ROWS_ENUM.THREE]: 1,
    };
  }, []);

  // draggalble handlers [start, drag, stop]
  const handleStart = (_, data) => {};

  // handle drag set the potential target and the position of the draggable
  const handleDrag = (e, data) => {
    const activeElement = data.node.className.split(" ")[0].split("_")[1];

    // if audio is playing then pause it
    if (audiosPlaying[activeElement]) {
      audios[activeElement].pause();
      audios[activeElement].currentTime = 0;
      setAudiosPlaying({
        ...audiosPlaying,
        [activeElement]: false,
      });
    }

    if (activeElement && draggableReferences[activeElement]) {
      if (
        checkIsInside(
          draggableReferences[activeElement].current,
          targetReferences.one.current,
        )
      ) {
        dispatch(
          updateDraggableObjectTargetRow({
            id: activeElement,
            targetRow: ROWS_ENUM.ONE,
          }),
        );
      } else if (
        checkIsInside(
          draggableReferences[activeElement].current,
          targetReferences.two.current,
        )
      ) {
        dispatch(
          updateDraggableObjectTargetRow({
            id: activeElement,
            targetRow: ROWS_ENUM.TWO,
          }),
        );
      } else if (
        checkIsInside(
          draggableReferences[activeElement].current,
          targetReferences.three.current,
        )
      ) {
        dispatch(
          updateDraggableObjectTargetRow({
            id: activeElement,
            targetRow: ROWS_ENUM.THREE,
          }),
        );
      } else {
        dispatch(
          updateDraggableObjectTargetRow({
            id: activeElement,
            targetRow: ROWS_ENUM.NONE,
          }),
        );
        // might need to set the position of the draggable to the original position
      }

      dispatch(
        updateDraggableObjectPosition({
          id: activeElement,
          position: {
            x: data.x,
            y: data.y,
          },
        }),
      );
    }
  };

  // handle stop does multiple things
  // 1. decide what is the target by data and set the position of the draggable
  // 2. set the position of the draggable in seconds
  // 3, check if draggable overlaps with other draggables
  const handleStop = (_, data) => {
    const activeElement = data.node.className.split(" ")[0].split("_")[1];

    if (!draggableObjects[activeElement].targetRow) {
      // remove the piece from teh board
      socket.emit(SOCKET_EVENTS.DRAGABBLE_POSITION_UPDATE, {
        id: activeElement,
        position: {
          x: draggableObjects[activeElement].position.x,
          y: draggableObjects[activeElement].position.y,
        },
        positionInSeconds: null,
        lastStopPosition: {
          x: initialPositions[activeElement].x,
          y: initialPositions[activeElement].y,
          targetRow: ROWS_ENUM.NONE,
        },
        targetRow: draggableObjects[activeElement].targetRow,
        isFixed: draggableObjects[activeElement].isFixed,
      });

      const targetRect =
        targetReferences[ROWS_ENUM.ONE].current.getBoundingClientRect();

      dispatch(
        updateDraggableObjectLastStopPositionAndLastTargetRow({
          id: activeElement,
          position: {
            x: initialPositions[activeElement].x,
            y: initialPositions[activeElement].y,
            targetRow: ROWS_ENUM.NONE,
          },
        }),
      );

      dispatch(
        updateDraggableObjectLastStopPositionAndLastTargetRow({
          id: activeElement,
          position: {
            x: initialPositions[activeElement].x,
            y: initialPositions[activeElement].poolRowOne
              ? targetRect.height * initialHeightMultiplierRowOne * scaleOffset
              : targetRect.height * initialHeightMultiplierRowTwo * scaleOffset,
            targetRow: ROWS_ENUM.NONE,
          },
        }),
      );

      return;
    }

    let correctedPositionX = null;
    let correctedPositionInSeconds = null;

    // decide what is the target by data and set the position of the draggable
    // console.log("ACTIVE ELEMENT", activeElement);
    // console.log("TARGET", draggableObjects[activeElement].targetRow);

    // check if draggable is overlapping with other draggables
    // if it is then set the position of the draggable to the original position
    const draggableObjectsWithSameTargetRow = Object.keys(
      draggableObjects,
    ).filter(
      (draggable) =>
        draggableObjects[draggable].targetRow ===
          draggableObjects[activeElement].targetRow &&
        draggableObjects[draggable].targetRow !== ROWS_ENUM.NONE &&
        draggable !== activeElement,
    );

    // instead of array create a map of the bounds of the draggables
    // check if the draggable is overlapping with any of the bounds
    for (const draggable of draggableObjectsWithSameTargetRow) {
      if (
        checkIsOverlapping(
          draggableReferences[activeElement].current,
          draggableReferences[draggable].current,
        )
      ) {
        // try to correct the position of the draggable if it is overlapping, if not possible then set the position to the last stop position

        // get from which side the draggable is overlapping

        const activeDraggableRect =
          draggableReferences[activeElement].current.getBoundingClientRect();
        const overlappedRect =
          draggableReferences[draggable].current.getBoundingClientRect();

        let isElementOverlappingFromRight = false;
        let isElementOverlappingFromLeft = false;

        if (overlappedRect.x > activeDraggableRect.x) {
          // overlapping from right
          // console.log("OVERLAPPING FROM LEFT");
          isElementOverlappingFromLeft = true;
        } else {
          // overlapping from left
          // console.log("OVERLAPPING FROM RIGHT");
          isElementOverlappingFromRight = true;
        }

        // console.log("current element", activeDraggableRect.x);
        // console.log("overlappedRect", overlappedRect.x);

        const positionsBoundsInSeconds = [];
        for (const otherdraggable of draggableObjectsWithSameTargetRow) {
          // except the active element and the draggable that it is overlapping
          if (
            otherdraggable === activeElement ||
            otherdraggable === draggable
          ) {
            continue;
          }

          positionsBoundsInSeconds.push([
            draggableObjects[otherdraggable].positionInSeconds,
            draggableObjects[otherdraggable].positionInSeconds +
              draggableObjects[otherdraggable].durationInSeconds,
          ]);
        }

        let isTimelineGoingToEnd = false;
        if (
          (isElementOverlappingFromRight &&
            draggableObjects[draggable].positionInSeconds +
              draggableObjects[draggable].durationInSeconds +
              draggableObjects[activeElement].durationInSeconds >
              MUSIC_BOARD_DURATION_IN_SECONDS) ||
          (isElementOverlappingFromLeft &&
            draggableObjects[draggable].positionInSeconds -
              draggableObjects[activeElement].durationInSeconds <
              0)
        ) {
          isTimelineGoingToEnd = true;
        }

        if (!isTimelineGoingToEnd && isElementOverlappingFromRight) {
          // console.log("CORRECTING POSITION TO RIGHT");
          correctedPositionX =
            draggableObjects[draggable].position.x +
            draggableObjects[draggable].durationInSeconds *
              MUSIC_BOARD_DIVISION;
          correctedPositionInSeconds =
            draggableObjects[draggable].positionInSeconds +
            draggableObjects[draggable].durationInSeconds;
        }

        if (!isTimelineGoingToEnd && isElementOverlappingFromLeft) {
          // console.log("CORRECTING POSITION TO LEFT");
          correctedPositionX =
            draggableObjects[draggable].position.x -
            draggableObjects[activeElement].durationInSeconds *
              MUSIC_BOARD_DIVISION;
          correctedPositionInSeconds =
            draggableObjects[draggable].positionInSeconds -
            draggableObjects[activeElement].durationInSeconds;
        }

        // console.log("CORRECTED POSITION", correctedPositionX);
        // console.log(
        //   "CORRECTED POSITION IN SECONDS",
        //   correctedPositionInSeconds
        // );

        // check if the draggable is overlapping with any of the bounds
        let isOverlappingOtherDraggables = false;
        // console.log("POSITIONS BOUNDS", positionsBoundsInSeconds);
        for (const bounds of positionsBoundsInSeconds) {
          if (
            // is start is inside the bounds
            (correctedPositionInSeconds > bounds[0] &&
              correctedPositionInSeconds < bounds[1]) ||
            // is end is inside the bounds
            (correctedPositionInSeconds +
              draggableObjects[activeElement].durationInSeconds >
              bounds[0] &&
              correctedPositionInSeconds +
                draggableObjects[activeElement].durationInSeconds <
                bounds[1]) ||
            // is bounds inside the draggable
            (bounds[0] > correctedPositionInSeconds &&
              bounds[0] <
                correctedPositionInSeconds +
                  draggableObjects[activeElement].durationInSeconds) ||
            (bounds[1] > correctedPositionInSeconds &&
              bounds[1] <
                correctedPositionInSeconds +
                  draggableObjects[activeElement].durationInSeconds) ||
            // if the element is exacty the same
            (bounds[0] === correctedPositionInSeconds &&
              bounds[1] ===
                correctedPositionInSeconds +
                  draggableObjects[activeElement].durationInSeconds)
          ) {
            isOverlappingOtherDraggables = true;
            break;
          }
        }

        // console.log(
        //   "IS OVERLAPPING OTHER DRAGGABLES",
        //   isOverlappingOtherDraggables
        // );
        // console.log("IS TIMELINE GOING TO END", isTimelineGoingToEnd);

        // If draggable could not be auto corrected then set the position to the last stop position
        if (isOverlappingOtherDraggables || isTimelineGoingToEnd) {
          // discard the corrected position
          correctedPositionX = null;
          correctedPositionInSeconds = null;

          dispatch(
            updateDraggableObjectPosition({
              id: activeElement,
              position: draggableObjects[activeElement].lastStopPosition,
            }),
          );

          dispatch(
            updateDraggableObjectTargetRow({
              id: activeElement,
              targetRow:
                draggableObjects[activeElement].lastStopPosition.targetRow,
            }),
          );

          return;
        }
        // only check if one overlapping is found
        break;
      }
    }

    let newPosition = null;
    let newPositionInSeconds = null;
    let newLastStopPosition = null;

    if (
      activeElement &&
      targetReferences[draggableObjects[activeElement].targetRow]
    ) {
      const targetRect =
        targetReferences[
          draggableObjects[activeElement].targetRow
        ].current.getBoundingClientRect();

      if (draggableObjects[activeElement].targetRow === ROWS_ENUM.ONE) {
        // Center the draggable inside the target
        newPosition = {
          x:
            Math.round(
              draggableObjects[activeElement].position.x / MUSIC_BOARD_DIVISION,
            ) * MUSIC_BOARD_DIVISION,
          y: -targetRect.height * scaleOffset,
        };

        newPositionInSeconds =
          (Math.round(
            draggableObjects[activeElement].position.x / MUSIC_BOARD_DIVISION,
          ) *
            MUSIC_BOARD_DIVISION +
            PIXEL_TO_SECOND_OFFSET) /
          MUSIC_BOARD_DIVISION;

        newLastStopPosition = {
          x:
            Math.round(
              draggableObjects[activeElement].position.x / MUSIC_BOARD_DIVISION,
            ) * MUSIC_BOARD_DIVISION,
          y: -targetRect.height * scaleOffset,
          targetRow: draggableObjects[activeElement].targetRow,
        };
      }

      if (draggableObjects[activeElement].targetRow === ROWS_ENUM.TWO) {
        // Center the draggable inside the target
        newPosition = {
          x:
            Math.round(
              draggableObjects[activeElement].position.x / MUSIC_BOARD_DIVISION,
            ) * MUSIC_BOARD_DIVISION,
          y: 0,
        };

        newPositionInSeconds =
          (Math.round(
            draggableObjects[activeElement].position.x / MUSIC_BOARD_DIVISION,
          ) *
            MUSIC_BOARD_DIVISION +
            PIXEL_TO_SECOND_OFFSET) /
          MUSIC_BOARD_DIVISION;

        newLastStopPosition = {
          x:
            Math.round(
              draggableObjects[activeElement].position.x / MUSIC_BOARD_DIVISION,
            ) * MUSIC_BOARD_DIVISION,
          y: 0,
          targetRow: draggableObjects[activeElement].targetRow,
        };
      }

      if (draggableObjects[activeElement].targetRow === ROWS_ENUM.THREE) {
        // Center the draggable inside the target
        newPosition = {
          x:
            Math.round(
              draggableObjects[activeElement].position.x / MUSIC_BOARD_DIVISION,
            ) * MUSIC_BOARD_DIVISION,
          y: +targetRect.height * scaleOffset,
        };

        newPositionInSeconds =
          (Math.round(
            draggableObjects[activeElement].position.x / MUSIC_BOARD_DIVISION,
          ) *
            MUSIC_BOARD_DIVISION +
            PIXEL_TO_SECOND_OFFSET) /
          MUSIC_BOARD_DIVISION;

        newLastStopPosition = {
          ...newPosition,
          targetRow: draggableObjects[activeElement].targetRow,
        };
      }
    }

    if (
      !newPosition ||
      Number.isNaN(newPositionInSeconds) ||
      !newLastStopPosition
    ) {
      return;
    }

    if (correctedPositionX !== null && correctedPositionInSeconds !== null) {
      newPosition = {
        x: correctedPositionX,
        y: newPosition.y,
      };

      newPositionInSeconds = correctedPositionInSeconds;

      newLastStopPosition = {
        x: correctedPositionX,
        y: newLastStopPosition.y,
        targetRow: newLastStopPosition.targetRow,
      };
    }

    socket.emit(
      SOCKET_EVENTS.DRAGABBLE_POSITION_UPDATE,
      {
        id: activeElement,
        position: newPosition,
        positionInSeconds: newPositionInSeconds,
        lastStopPosition: newLastStopPosition,
        targetRow: draggableObjects[activeElement].targetRow,
        isFixed: draggableObjects[activeElement].isFixed,
      },
      (data) => {
        if (data.error) {
          console.log(
            "ERROR IN UPDATING POSITION, RESETTTING POSITIONS",
            data.error,
          );
          handleDraggablePositionUpdate(data.data);
          return;
        }
        // console.log("POSITION UPDATED SUCCESSFULLY", data.data, {
        //   id: activeElement,
        //   position: newPosition,
        //   positionInSeconds: newPositionInSeconds,
        //   lastStopPosition: newLastStopPosition,
        //   targetRow: draggableObjects[activeElement].targetRow,
        //   isFixed: draggableObjects[activeElement].isFixed,
        // });

        dispatch(
          updateDraggableObjectLastStopPositionAndLastTargetRow({
            id: activeElement,
            position: newLastStopPosition,
          }),
        );

        dispatch(
          updateDraggableObjectPosition({
            id: activeElement,
            position: newPosition,
          }),
        );

        dispatch(
          updateDraggableObjectPositionInSeconds({
            id: activeElement,
            positionInSeconds: newPositionInSeconds,
          }),
        );
      },
    );
  };

  // handleInstrumentSilent updates the instrument silent state
  const handleInstrumentSilent = (instrument) => {
    dispatch(
      updateSilentStateInstrument({
        instrument,
        silent: !instruments[instrument].silent,
      }),
    );
  };

  // toggle audio for inidividual draggable for initial play
  const toggleAudio = (audioNumber) => {
    return () => {
      const newAudiosPlaying = {};
      // play audioNumber and pause all other audios
      for (const audio in audios) {
        if (audio === audioNumber) {
          if (audiosPlaying[audioNumber]) {
            audios[audioNumber].pause();
            audios[audioNumber].currentTime = 0;
            newAudiosPlaying[audio] = false;
          } else {
            audios[audioNumber].play();
            newAudiosPlaying[audio] = true;
          }
        } else {
          audios[audio].pause();
          audios[audio].currentTime = 0;
          newAudiosPlaying[audio] = false;
        }
      }

      setAudiosPlaying({ ...newAudiosPlaying });
    };
  };

  const stopAllMusic = useCallback(() => {
    for (const audio in audios) {
      audios[audio].pause();
      audios[audio].currentTime = 0;
      audiosPlaying[audio] = false;
    }
  }, [audiosPlaying]);

  const handleMusicPlayback = useCallback(() => {
    if (timelinePlaying) {
      stopAllMusic();
    } else {
      // stop all music that is not on the board
      for (const audio in audios) {
        if (
          !draggableObjects[audio].targetRow ||
          draggableObjects[audio].targetRow === ROWS_ENUM.NONE
        ) {
          audios[audio].pause();
          audios[audio].currentTime = 0;

          // set audio playing to false
          audiosPlaying[audio] = false;
        }
      }
    }

    dispatch(updateIsTimelinePlaying(!timelinePlaying));
    // eslint-disable-next-line
  }, [dispatch, timelinePlaying, stopAllMusic]);

  // handleMusicBoardReset resets the music board and all the positions of the draggables
  const handleMusicBoardReset = () => {
    socket.emit(SOCKET_EVENTS.RESET_MUSIC_BOARD, {}, (data) => {
      stopAllMusic();
      dispatch(updateIsTimelinePlaying(false));
      handleDraggablePositionUpdate(data.data);
    });
  };

  // handleDraggablePositionUpdate handle piece movment data from server
  const handleDraggablePositionUpdate = useCallback(
    (data) => {
      for (const draggableId in data) {
        // console.log("DRAGGABLE ID", draggableId);
        // console.log("DRAGGABLE DATA", data[draggableId]);
        const draggable = data[draggableId];

        if (!draggableObjects[draggableId] || !draggable.position) {
          console.log("DRAGGABLE OBJECT NOT FOUND FOR ID: ", draggableId);
          continue;
        }

        // TODO check if this can be optimised
        // // if position in seconds and targetrow is already same then do not update the position
        // if (
        //   draggableObjects[draggableId].positionInSeconds ===
        //     draggable.positionInSeconds &&
        //   draggableObjects[draggableId].targetRow === draggable.targetRow
        // ) {
        //   continue;
        // }

        // TODO check if this can be optimised
        // if the draggable is fixed then set the position of the draggable to the correct position
        if (draggable.isFixed) {
          // console.log("FIXED DRAGGABLE FOUND", draggableId);
          let newPosition = null;

          if (
            !targetReferences[draggableObjects[draggableId].correctRowPosition]
              .current
          ) {
            console.log("FIXED DRAGGABLE TARGET ROW NOT FOUND");
            return;
          }

          const targetRect =
            targetReferences[
              draggableObjects[draggableId].correctRowPosition
            ].current.getBoundingClientRect();

          if (
            draggableObjects[draggableId].correctRowPosition === ROWS_ENUM.ONE
          ) {
            // Center the draggable inside the target
            newPosition = {
              x: correctPositionsX[draggableId].x,
              y: -targetRect.height * scaleOffset,
            };
          }

          if (
            draggableObjects[draggableId].correctRowPosition === ROWS_ENUM.TWO
          ) {
            // Center the draggable inside the target
            newPosition = {
              x: correctPositionsX[draggableId].x,
              y: 0,
            };
          }

          if (
            draggableObjects[draggableId].correctRowPosition === ROWS_ENUM.THREE
          ) {
            // Center the draggable inside the target
            newPosition = {
              x: correctPositionsX[draggableId].x,
              y: +targetRect.height * scaleOffset,
            };
          }

          dispatch(
            updateDraggableObjectPosition({
              id: draggableId,
              position: newPosition,
              isFixed: true,
            }),
          );

          dispatch(
            updateDraggableObjectPositionInSeconds({
              id: draggableId,
              positionInSeconds: draggable.positionInSeconds,
            }),
          );

          dispatch(
            updateDraggableObjectTargetRow({
              id: draggableId,
              targetRow: draggableObjects[draggableId].correctRowPosition,
            }),
          );

          continue;
        }

        // if piece has moved out of the board, reset to initial position
        if (draggable.targetRow === ROWS_ENUM.NONE) {
          // console.log("DRAGGABLE OUT OF BOARD", draggableId);
          // console.log("DRAGGABLE POSITION", draggable);

          const targetRect =
            targetReferences[ROWS_ENUM.ONE].current.getBoundingClientRect();

          dispatch(
            updateDraggableObjectPosition({
              id: draggableId,
              position: {
                x: draggable.position.x,
                y: draggable.position.y,
              },
            }),
          );

          dispatch(
            updateDraggableObjectPositionInSeconds({
              id: draggableId,
              positionInSeconds: null,
            }),
          );

          dispatch(
            updateDraggableObjectLastStopPositionAndLastTargetRow({
              id: draggableId,
              position: {
                x: initialPositions[draggableId].x,
                y: initialPositions[draggableId].poolRowOne
                  ? targetRect.height *
                    initialHeightMultiplierRowOne *
                    scaleOffset
                  : targetRect.height *
                    initialHeightMultiplierRowTwo *
                    scaleOffset,
                targetRow: ROWS_ENUM.NONE,
              },
            }),
          );

          // targetRow is not set when peice is being moved remotely, as targetRow is being set on drag
          dispatch(
            updateDraggableObjectTargetRow({
              id: draggableId,
              targetRow: ROWS_ENUM.NONE,
            }),
          );

          continue;
        } else {
          // if the draggable is not fixed and targetRow is not NONE then set the position of the draggable to the position received from the server
          dispatch(
            updateDraggableObjectPosition({
              id: draggableId,
              position: {
                x: draggable.position.x,
                y:
                  positionByTargetRowMultiplier[draggable.targetRow] *
                  targetReferences[
                    draggable.targetRow
                  ].current?.getBoundingClientRect().height *
                  scaleOffset,
              },
            }),
          );

          dispatch(
            updateDraggableObjectPositionInSeconds({
              id: draggableId,
              positionInSeconds: draggable.positionInSeconds,
            }),
          );

          dispatch(
            updateDraggableObjectLastStopPositionAndLastTargetRow({
              id: draggableId,
              position: {
                x: draggable.position.x,
                // y is dynamically calculated based on the height of any target row [all of them are same height]
                y: draggable.lastStopPosition.targetRow
                  ? positionByTargetRowMultiplier[
                      draggable.lastStopPosition.targetRow
                    ] *
                    targetReferences[
                      draggable.lastStopPosition.targetRow
                    ].current?.getBoundingClientRect().height *
                    scaleOffset
                  : initialPositions[draggableId].poolRowOne
                    ? targetReferences[
                        ROWS_ENUM.ONE
                      ].current?.getBoundingClientRect().height *
                      initialHeightMultiplierRowOne *
                      scaleOffset
                    : targetReferences[
                        ROWS_ENUM.ONE
                      ].current?.getBoundingClientRect().height *
                      initialHeightMultiplierRowTwo *
                      scaleOffset,
                targetRow: draggable.targetRow,
              },
            }),
          );

          dispatch(
            updateDraggableObjectTargetRow({
              id: draggableId,
              targetRow: draggable.targetRow,
            }),
          );
        }
      }
    },
    // eslint-disable-next-line
    [dispatch, draggableObjects, positionByTargetRowMultiplier],
  );

  // This useEffect is used to subscribe to socket events
  useEffect(() => {
    // subscribe to socket events
    socket.on(
      SOCKET_EVENTS.DRAGABBLE_POSITION_UPDATE,
      handleDraggablePositionUpdate,
    );

    socket.on(SOCKET_EVENTS.STAGE_ONE_HINT_ONE, (data) => {
      handleDraggablePositionUpdate(data.data);
      dispatch(setHintOneUsed());
    });

    socket.on(SOCKET_EVENTS.STAGE_ONE_HINT_TWO, (data) => {
      handleDraggablePositionUpdate(data.data);
      dispatch(setHintTwoUsed());
    });

    socket.on(SOCKET_EVENTS.RESET_MUSIC_BOARD, (data) => {
      stopAllMusic();
      dispatch(updateIsTimelinePlaying(false));
      handleDraggablePositionUpdate(data.data);
    });

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket.off(
        SOCKET_EVENTS.DRAGABBLE_POSITION_UPDATE,
        handleDraggablePositionUpdate,
      );
      socket.off(SOCKET_EVENTS.STAGE_ONE_HINT_ONE);
      socket.off(SOCKET_EVENTS.STAGE_ONE_HINT_TWO);
      socket.off(SOCKET_EVENTS.RESET_MUSIC_BOARD);
    };
    // eslint-disable-next-line
  }, [socket, dispatch, positionByTargetRowMultiplier, stopAllMusic]);

  // This useEffect authmatically set is playing to false when the audio ends
  useEffect(() => {
    // Function to handle the event
    const handleAudioEnd = (audio) => () => {
      setAudiosPlaying({
        ...audiosPlaying,
        [audio]: false,
      });
    };

    // Adding event listeners
    for (const audio in audios) {
      const listener = handleAudioEnd(audio);
      audios[audio].addEventListener("ended", listener);

      // Storing the listener to remove it later
      audios[audio].savedListener = listener;
    }

    return () => {
      // Removing event listeners
      for (const audio in audios) {
        if (audios[audio].savedListener) {
          audios[audio].removeEventListener(
            "ended",
            audios[audio].savedListener,
          );
        }
      }
    };
  }, [dispatch, audiosPlaying]);

  // This useEffect is used to play the audio when the cursor position is the same as the draggable position
  useEffect(() => {
    // loop over all draggalbles and check if they are inside the target
    // if they are inside the target check if the position of cursor is the same as the position of the draggable
    // if it is the same then play the audio

    if (!timelinePlaying) {
      return;
    }

    // console.log("CURSOR POSITION HERE", cursorPositionInSeconds);

    for (const draggable in draggableObjects) {
      if (!draggableObjects[draggable].targetRow) {
        continue;
      }

      // this block is used to play the audio when the cursor is at the start of the draggable
      if (
        cursorPositionInSeconds ===
        draggableObjects[draggable].positionInSeconds
      ) {
        if (
          draggableObjects[draggable].targetRow ===
          draggableObjects[draggable].correctRowPosition
        ) {
          audiosPlaying[draggable] = true;
          audios[draggable].play();
        } else {
          if (!instruments[draggableObjects[draggable].targetRow].silent) {
            failAudios[draggableObjects[draggable].correctRowPosition].play();
          }
        }
      }

      // this block is used to play the audio when the cursor is in the middle of the draggable
      if (
        draggableObjects[draggable].targetRow !== ROWS_ENUM.NONE &&
        cursorPositionInSeconds >
          draggableObjects[draggable].positionInSeconds &&
        cursorPositionInSeconds <
          draggableObjects[draggable].positionInSeconds +
            draggableObjects[draggable].durationInSeconds -
            1 && // subtracting 1 second to avoid replaying of last part of the audio
        !audiosPlaying[draggable]
      ) {
        if (
          draggableObjects[draggable].targetRow ===
          draggableObjects[draggable].correctRowPosition
        ) {
          // play the audio from where the cursor is
          audiosPlaying[draggable] = true;
          audios[draggable].currentTime =
            cursorPositionInSeconds -
            draggableObjects[draggable].positionInSeconds;
          audios[draggable].play();
        } else {
          if (!instruments[draggableObjects[draggable].targetRow].silent) {
            failAudios[draggableObjects[draggable].correctRowPosition].play();
          }
        }
      }
    }
    // removing draggableObjects from the dependencies array to improve performance
    // eslint-disable-next-line
  }, [
    // draggableObjects,
    cursorPositionInSeconds,
    timelinePlaying,
    dispatch,
  ]);

  //  This useEffect handles silent state of the instruments rows
  useEffect(() => {
    if (!timelinePlaying) {
      return;
    }

    // get all draggables in the first row
    const firstRowDraggables = Object.keys(draggableObjects).filter(
      (draggable) => draggableObjects[draggable].targetRow === ROWS_ENUM.ONE,
    );

    // get all draggables in the second row
    const secondRowDraggables = Object.keys(draggableObjects).filter(
      (draggable) => draggableObjects[draggable].targetRow === ROWS_ENUM.TWO,
    );

    // get all draggables in the third row
    const thirdRowDraggables = Object.keys(draggableObjects).filter(
      (draggable) => draggableObjects[draggable].targetRow === ROWS_ENUM.THREE,
    );

    // if the first row is now silent then make volume 0 for all the audios in the first row
    for (const draggableId of firstRowDraggables) {
      if (instruments[ROWS_ENUM.ONE].silent) {
        audios[draggableId].volume = 0;
      } else {
        audios[draggableId].volume = volume;
      }
    }

    // if the second row is now silent then make volume 0 for all the audios in the second row
    for (const draggableId of secondRowDraggables) {
      if (instruments[ROWS_ENUM.TWO].silent) {
        audios[draggableId].volume = 0;
      } else {
        audios[draggableId].volume = volume;
      }
    }

    // if the third row is now silent then make volume 0 for all the audios in the third row
    for (const draggableId of thirdRowDraggables) {
      if (instruments[ROWS_ENUM.THREE].silent) {
        audios[draggableId].volume = 0;
      } else {
        audios[draggableId].volume = volume;
      }
    }
  }, [instruments, draggableObjects, timelinePlaying, volume]);

  // This useEffect is used to set the position of the fixed draggables and initial positions for draggables in the pool
  useEffect(() => {
    // parse connection recovery data from session storage
    const socket_room_data = JSON.parse(
      sessionStorage.getItem("socket_room_data"),
    );

    const roomCode = socket_room_data?.roomCode;

    socket.emit(SOCKET_EVENTS.SYNC_DRAGGABLE_POSITION, { roomCode }, (data) => {
      if (data.error) {
        console.log(
          "ERROR IN SYNCING DRAGGABLE POSITIONS",
          roomCode,
          data.error,
        );
        // TODO change this to a toast message
        alert("ERROR IN CONNECTING TO SERVER, PLEASE RE LOGIN");
        return;
      }
      // console.log("SYNC DRAGGABLE POSITIONS: ", roomCode, data);
      handleDraggablePositionUpdate(data?.data?.draggableObjects);

      // check if hint one has been used
      if (data?.data?.firstHintUsed) {
        dispatch(setHintOneUsed());
      }

      // set piece synced true after 500ms
      setTimeout(() => {
        setpieceSynced(true);
      }, 1000);
    });
    // eslint-disable-next-line
  }, [dispatch]);

  // This useEffect attaches listner to space for play and pause
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === "Space") {
        handleMusicPlayback();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleMusicPlayback]);

  // This useEffect checks if the puzzle is complete
  useEffect(() => {
    // navigate to next stage after 3 seconds
    let navigateTimer;
    const handlePuzzleSolved = () => {
      // loop over all draggables and check if currentPositionInSeconds is the same as correctPositionInSeconds
      // if same then show alert saying puzzle solved
      for (const draggable in draggableObjects) {
        if (
          draggableObjects[draggable].positionInSeconds !==
          draggableObjects[draggable].correctPositionInSeconds
        ) {
          return;
        }
      }

      // trigger the complete animation
      setShowCompleteAnimation(true);

      navigateTimer = setTimeout(() => {
        navigate(VIDEO_ROUTES.TWO);
      }, 6000);
    };

    // after one second check if the puzzle is solved
    const timer = setTimeout(() => {
      handlePuzzleSolved();
    }, 500);

    return () => {
      clearTimeout(timer);
      clearTimeout(navigateTimer);
    };

    // eslint-disable-next-line
  }, [
    draggableObjects.one.positionInSeconds,
    draggableObjects.two.positionInSeconds,
    draggableObjects.three.positionInSeconds,
    draggableObjects.four.positionInSeconds,
    draggableObjects.five.positionInSeconds,
    draggableObjects.six.positionInSeconds,
    draggableObjects.seven.positionInSeconds,
    draggableObjects.eight.positionInSeconds,
    draggableObjects.nine.positionInSeconds,
    draggableObjects.ten.positionInSeconds,
    draggableObjects.eleven.positionInSeconds,
    draggableObjects.twelve.positionInSeconds,
  ]);

  // This useEffect is used to control the volume of the audios
  useEffect(() => {
    for (const audio in audios) {
      // only change the volume if the volume is not 0
      if (audios[audio].volume) audios[audio].volume = volume;
    }

    for (const audio in failAudios) {
      // only change the volume if the volume is not 0
      if (failAudios[audio].volume) failAudios[audio].volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    // const handleAudioLoaded = (audio) => () => {
    //   console.log("AUDIO LOADED", audio);
    // };

    const handleTimeUpdate = (audio) => () => {
      console.log("TIME UPDATE", audio, audios[audio].currentTime);
    };

    // add onTimeUpdate event listener to each audio
    for (const audio in audios) {
      // audios[audio].addEventListener("canplaythrough", handleAudioLoaded(audio));
      audios[audio].addEventListener("timeupdate", handleTimeUpdate(audio));
    }

    return () => {
      // remove onTimeUpdate event listener from each audio
      for (const audio in audios) {
        // audios[audio].removeEventListener("canplaythrough", handleAudioLoaded(audio));
        audios[audio].addEventListener("timeupdate", handleTimeUpdate(audio));
      }
    };
  }, []);

  return (
    <>
      {/* DEV BUTTONS */}
      {isHost && (
        <div
          className="stageOne_skip"
          onClick={() => {
            navigate(VIDEO_ROUTES.TWO);
          }}
        >
          <div
            className="common_btn"
            style={{
              position: "absolute",
              top: "0%",
              right: "0%",
              zIndex: "3",
            }}
          >
            Skip
          </div>
        </div>
      )}

      {/* POPUPS */}
      {showResetPopup && (
        <PopUp
          message={MESSAGES.RESET_POPUP.MESSAGE}
          subMessage={MESSAGES.RESET_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.RESET_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            handleMusicBoardReset();
            setShowResetPopup(false);
          }}
          onCancel={() => {
            setShowResetPopup(false);
          }}
        />
      )}

      {showHintOnePopup && (
        <PopUp
          message={MESSAGES.STAGE_ONE_HINT_ONE_POPUP.MESSAGE}
          subMessage={MESSAGES.STAGE_ONE_HINT_ONE_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.STAGE_ONE_HINT_ONE_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            // emit socket event to change positions
            socket.emit(SOCKET_EVENTS.STAGE_ONE_HINT_ONE, {}, (data) => {
              handleDraggablePositionUpdate(data.data);
              dispatch(setHintOneUsed());
              setShowHintOnePopup(false);
            });
          }}
          onCancel={() => {
            setShowHintOnePopup(false);
          }}
        />
      )}

      {showHintTwoPopup && (
        <PopUp
          message={MESSAGES.STAGE_ONE_HINT_TWO_POPUP.MESSAGE}
          subMessage={MESSAGES.STAGE_ONE_HINT_TWO_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.STAGE_ONE_HINT_TWO_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            // emit socket event to change positions
            socket.emit(SOCKET_EVENTS.STAGE_ONE_HINT_TWO, {}, (data) => {
              handleDraggablePositionUpdate(data.data);
              dispatch(setHintTwoUsed());
              setShowHintTwoPopup(false);
            });
          }}
          onCancel={() => {
            setShowHintTwoPopup(false);
          }}
        />
      )}

      {!instructionsSeen && (
        <div className="instructions_stageone">
          <div
            className="instructionScreen_skip_button common_btn"
            onClick={() => {
              dispatch(setInstrcutionsSeen());
            }}
          >
            Skip Tutorial
            <img src={CommonImages.icons.skip_icon} alt="" />
          </div>
          <InstructionPop
            dataArray={instructionDataArray}
            onComplete={() => {
              dispatch(setInstrcutionsSeen());
            }}
          />
        </div>
      )}

      {showCompleteAnimation && (
        <div className="animationContainer">
          <img src={StageOneImages.shine} alt="" />
        </div>
      )}

      <div className="stageOne">
        <div
          className="stageOne_btns"
          style={{
            width: `${1135 * scale}px`,
            transform: `translate(-50%, -${targetReferences[ROWS_ENUM.ONE]?.current?.getBoundingClientRect() ? targetReferences[ROWS_ENUM.ONE].current.getBoundingClientRect().height * scale * stageOneBtnContainerHeightOffsetMultiplier : 300}px)`,
          }}
        >
          <div className="left_btns">
            <TakeControl
              topBtn={"25%"}
              leftBtn={"5%"}
              topHeading={"-35%"}
              leftHeading={"0%"}
              position=""
            />
          </div>

          <div className="right_btns">
            <VolSlider />

            {isHost && !firstHintUsed && (
              <div
                className="hint_one_music_baord common_btn"
                onClick={() => {
                  setShowHintOnePopup(true);
                }}
              >
                Hint 1
              </div>
            )}

            {isHost && firstHintUsed && !secondHintUsed && (
              <div
                className="hint_two_music_baord common_btn"
                onClick={() => {
                  setShowHintTwoPopup(true);
                }}
              >
                Hint 2
              </div>
            )}

            <div
              className="reset_music_baord common_btn"
              onClick={() => {
                setShowResetPopup(true);
              }}
              style={
                inControl
                  ? {}
                  : {
                      opacity: "0.5",
                      pointerEvents: "none",
                    }
              }
            >
              Reset
            </div>
          </div>
        </div>
        {/* MUSIC BOARD */}
        <div
          className="music_board"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          {Object.keys(draggableObjects).map((draggable) => {
            return (
              <DraggableSound
                key={`${draggable}_draggable_key`}
                audiosPlaying={audiosPlaying}
                draggableId={draggable}
                draggableReferences={draggableReferences}
                handleStart={handleStart}
                handleDrag={handleDrag}
                handleStop={handleStop}
                toggleAudio={toggleAudio}
                pieceSynced={pieceSynced}
                scale={scale}
              />
            );
          })}

          <div className="instruments_container">
            <div className="music_controls" onClick={handleMusicPlayback}>
              {timelinePlaying ? (
                <img src={CommonImages.icons.stop} alt="" />
              ) : (
                <img src={CommonImages.icons.play} alt="" />
              )}
            </div>

            <div
              className={`instrument instrument_one ${instruments[ROWS_ENUM.ONE].silent ? "instrument_silent" : ""}`}
            >
              <div className="instrument_image">
                <img src={StageOneImages.instruments.horn} alt="" />
              </div>
              <div className="instrument_name_and_silent_btn">
                <span>Horn</span>
                <div
                  className="instrument_silent_btn"
                  onClick={() => {
                    handleInstrumentSilent(ROWS_ENUM.ONE);
                  }}
                >
                  {!instruments[ROWS_ENUM.ONE].silent ? (
                    <img src={CommonImages.icons.volume_on} alt="" />
                  ) : (
                    <img src={CommonImages.icons.volume_off} alt="" />
                  )}
                </div>
              </div>
            </div>
            <div
              className={`instrument instrument_two ${instruments[ROWS_ENUM.TWO].silent ? "instrument_silent" : ""}`}
            >
              <div className="instrument_image">
                <img src={StageOneImages.instruments.clarinet} alt="" />
              </div>
              <div className="instrument_name_and_silent_btn">
                <span>Clarinet</span>
                <div
                  className="instrument_silent_btn"
                  onClick={() => {
                    handleInstrumentSilent(ROWS_ENUM.TWO);
                  }}
                >
                  {!instruments[ROWS_ENUM.TWO].silent ? (
                    <img src={CommonImages.icons.volume_on} alt="" />
                  ) : (
                    <img src={CommonImages.icons.volume_off} alt="" />
                  )}
                </div>
              </div>
            </div>
            <div
              className={`instrument instrument_three ${instruments[ROWS_ENUM.THREE].silent ? "instrument_silent" : ""}`}
            >
              <div className="instrument_image">
                <img src={StageOneImages.instruments.drum} alt="" />
              </div>
              <div className="instrument_name_and_silent_btn">
                <span>Drum</span>
                <div
                  className="instrument_silent_btn"
                  onClick={() => {
                    handleInstrumentSilent(ROWS_ENUM.THREE);
                  }}
                >
                  {!instruments[ROWS_ENUM.THREE].silent ? (
                    <img src={CommonImages.icons.volume_on} alt="" />
                  ) : (
                    <img src={CommonImages.icons.volume_off} alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="target_container">
            <Timeline stopAllMusic={stopAllMusic} scale={scale} />
            <div
              className={`target_one ${instruments[ROWS_ENUM.ONE].silent ? " target_silent" : ""}`}
              ref={targetReferences.one}
              style={{
                width: `${MUSIC_BOARD_WIDTH_IN_PIXELS}px`,
              }}
            ></div>
            <div
              className={`target_two ${instruments[ROWS_ENUM.TWO].silent ? " target_silent" : ""}`}
              ref={targetReferences.two}
              style={{
                width: `${MUSIC_BOARD_WIDTH_IN_PIXELS}px`,
              }}
            ></div>
            <div
              className={`target_three ${instruments[ROWS_ENUM.THREE].silent ? " target_silent" : ""}`}
              ref={targetReferences.three}
              style={{
                width: `${MUSIC_BOARD_WIDTH_IN_PIXELS}px`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StageOne;
