export const CommonImages = {
  backgroud:
    "https://storage.googleapis.com/stq_assets/images/common/base_image.jpg",
  modal_backgrounds: {
    bg_one:
      "https://storage.googleapis.com/stq_assets/images/common/bg_one%20(Compressed).png",
    bg_two:
      "https://storage.googleapis.com/stq_assets/images/common/bg_two.png",
  },
  icons: {
    play: "https://storage.googleapis.com/stq_assets/images/common/icons/play.png",
    stop: "https://storage.googleapis.com/stq_assets/images/common/icons/stop.png",
    volume_on:
      "https://storage.googleapis.com/stq_assets/images/common/icons/volume_on.png",
    volume_off:
      "https://storage.googleapis.com/stq_assets/images/common/icons/volume%20off.png",
    skip: "https://storage.googleapis.com/stq_assets/images/common/icons/skip_icon_round.png",
    cross:
      "https://storage.googleapis.com/stq_assets/images/common/icons/cross.png",
    skip_icon:
      "https://storage.googleapis.com/stq_assets/images/common/Tailing%20Icon.png",
    undo_icon:
      "https://storage.googleapis.com/stq_assets/images/common/undo.png",
    redo_icon:
      "https://storage.googleapis.com/stq_assets/images/common/redo.png",
  },
  button_background:
    "https://storage.googleapis.com/stq_assets/images/common/Button.png",
  logo: "https://storage.googleapis.com/stq_assets/images/common/Save%20the%20Queen%20Logo.png",
  common_bg:
    "https://storage.googleapis.com/stq_assets/images/common/common_bg%20(Compressed).png",
  passed:
    "https://storage.googleapis.com/stq_assets/images/common/Mission%20Passed.png",
  failed:
    "https://storage.googleapis.com/stq_assets/images/common/Mission%20Failed.png",
};

export const InstructionScreen = {
  music_puzzle: {
    popup_bg:
      "https://storage.googleapis.com/stq_assets/images/instruction/Background.png",
    gifs: {
      two: "https://storage.googleapis.com/stq_assets/images/instruction/Compressed%20GIFs/Playing%20a%20track%20in%20the%20pool%20(Compressed).gif",
      three:
        "https://storage.googleapis.com/stq_assets/images/instruction/Compressed%20GIFs/Taking%20control%20and%20arranging%20tracks%20(Compressed).gif",
      four: "https://storage.googleapis.com/stq_assets/images/instruction/Compressed%20GIFs/Playing%20the%20tracks%20and%20moving%20the%20needle%20(Compressed).gif",
      five: "https://storage.googleapis.com/stq_assets/images/instruction/Compressed%20GIFs/Muting%20the%20board%20(Compressed).gif",
      six: "https://storage.googleapis.com/stq_assets/images/instruction/Compressed%20GIFs/Not%20touching%20track%20(Compressed).gif",
    },
  },
  crossword: {
    gifs: {
      one: "https://storage.googleapis.com/stq_assets/images/instruction/Compressed%20GIFs/Crossword%20-%20Take%20Control%20and%20Backspace.gif",
      two: "https://storage.googleapis.com/stq_assets/images/instruction/Compressed%20GIFs/Crossword%20-%20Highlight%20and%20Strikethrough.gif",
    },
  },
  chess_puzzle: {
    gifs: {
      one: "https://storage.googleapis.com/stq_assets/images/instruction/Compressed%20GIFs/Chessboard%20-%20Take%20Control%20and%20Piece%20drag.gif",
      two: "https://storage.googleapis.com/stq_assets/images/instruction/Compressed%20GIFs/Chessboard%20-%20Take%20Control%20and%20Highlight.gif",
    },
  },
};

export const Videos = {
  karaoke:
    "https://storage.googleapis.com/stq-videos/vids/Save%20The%20Queen%20Anthem%20Karaoke%20(Compressed).mp4",

  one: "https://storage.googleapis.com/stq-videos/vids/V01%20-%20Stage%201%20Opening%20Video%20(Shorter%20Variant)%20(Compressed).mp4",
  two: "https://storage.googleapis.com/stq-videos/vids/V02.mp4",
  three: "https://storage.googleapis.com/stq-videos/vids/V03.mp4",
  four: "https://storage.googleapis.com/stq-videos/vids/V04.mp4",
  five: "https://storage.googleapis.com/stq-videos/vids/V05.mp4",
  six: "https://storage.googleapis.com/stq-videos/vids/V06.mp4",
  seven:
    "https://storage.googleapis.com/stq-videos/vids/V07%20(Compressed).mp4",
  eight: "https://storage.googleapis.com/stq-videos/vids/V08.mp4",
  nine: "https://storage.googleapis.com/stq-videos/vids/V09.mp4",
  ten: "https://storage.googleapis.com/stq-videos/vids/V10.mp4",
  eleven:
    "https://storage.googleapis.com/stq-videos/vids/V11%20(Compressed).mp4",
  twelve: "https://storage.googleapis.com/stq-videos/vids/V12.mp4",
  thirteen: "https://storage.googleapis.com/stq-videos/vids/V13.mp4",
};

// Separatig videos by stage so that we can load them in the Loader component based on the stage
export const VideosByStage = {
  one: {
    one: Videos.one,
    two: Videos.two,
    three: Videos.three,
  },
  two: {
    four: Videos.four,
  },
  three: {
    five: Videos.five,
    six: Videos.six,
    seven: Videos.seven,
    eight: Videos.eight,
    nine: Videos.nine,
    ten: Videos.ten,
    eleven: Videos.eleven,
    twelve: Videos.twelve,
    thirteen: Videos.thirteen,
  },
};
