import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import "./StageTwo.scss";
import { ObjectsToHighlight, getBaseImage } from "./Highlighter";
import { useDispatch, useSelector } from "react-redux";
import {
  setCombinationLockOneSolved,
  setCombinationLockTwoSolved,
  setShowCombinationLockOne,
  setShowCombinationLockTwo,
  setTableView,
} from "Store/StageTwo";
import CombinationLock from "Components/CombinationLock/CombinationLock";
import {
  COMBINATION_LOCK_ONE_SOLUTION,
  COMBINATION_LOCK_TWO_SOLUTION,
  MESSAGES,
} from "Constants/StageTwo";
import CombinationLockSolvedPopup from "Components/CombinationLockSolvedPopup/CombinationLockSolvedPopup";
import { CrosswordImages } from "Assets/StageTwo";
import { useContext } from "react";
import { SocketContext } from "context/socket";
import { SOCKET_EVENTS, VIDEO_ROUTES } from "Constants/Common";

function StageTwo() {
  const StageTwoState = useSelector((state) => state.stageTwo);

  const { isHost } = useSelector((state) => state.common);

  const [activeImage, setActiveImage] = React.useState(
    getBaseImage(StageTwoState.tableView, StageTwoState.stageTwoSolved),
  );

  const socket = useContext(SocketContext);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    setActiveImage(
      getBaseImage(StageTwoState.tableView, StageTwoState.stageTwoSolved),
    );
  }, [StageTwoState.tableView, StageTwoState.stageTwoSolved]);

  return (
    <>
      {/* DEV BUTTONS */}
      {isHost && (
        <div
          className="stageOne_skip"
          onClick={() => {
            navigate(VIDEO_ROUTES.FIVE);
          }}
        >
          <div
            className="common_btn"
            style={{
              position: "absolute",
              top: "0%",
              right: "0%",
              zIndex: "3",
            }}
          >
            Skip
          </div>
        </div>
      )}
      {/*  DEV BUTTONS END */}
      {StageTwoState.showCombinationLockOne && (
        <CombinationLock
          solution={COMBINATION_LOCK_ONE_SOLUTION}
          combinationLockSolved={StageTwoState.combinationLockOneSolved}
          setShowCombinationLock={setShowCombinationLockOne}
          onSolved={() => {
            socket.emit(SOCKET_EVENTS.COMBINATION_LOCK_ONE_SOLVED, {}, () => {
              dispatch(setCombinationLockOneSolved(true));
            });
          }}
          OnSolvePopup={CombinationLockSolvedPopup}
          solvedPopupMessage={
            MESSAGES.COMBINATION_LOCK_ONE_SOLUTION_POPUP.MESSAGE
          }
          solvedPopupImage={CrosswordImages.key_one}
        />
      )}

      {StageTwoState.showCombinationLockTwo && (
        <CombinationLock
          solution={COMBINATION_LOCK_TWO_SOLUTION}
          combinationLockSolved={StageTwoState.combinationLockTwoSolved}
          setShowCombinationLock={setShowCombinationLockTwo}
          onSolved={() => {
            socket.emit(SOCKET_EVENTS.COMBINATION_LOCK_TWO_SOLVED, {}, () => {
              dispatch(setCombinationLockTwoSolved(true));
            });
          }}
          OnSolvePopup={CombinationLockSolvedPopup}
          solvedPopupMessage={
            MESSAGES.COMBINATION_LOCK_TWO_SOLUTION_POPUP.MESSAGE
          }
          solvedPopupImage={CrosswordImages.key_two}
        />
      )}

      {/* Object highlighter */}
      {Object.values(ObjectsToHighlight).map((object) => {
        if (
          !object.isVisible(
            StageTwoState.tableView,
            StageTwoState.stageTwoSolved,
          )
        ) {
          return <div key={JSON.stringify(object.name + "_key")}></div>;
        }

        return (
          <div
            key={JSON.stringify(object.name + "_key_2")}
            style={{
              position: "absolute",
              top: `${object.position.y}%`,
              left: `${object.position.x}%`,
              transform: "translate(-50%, -50%)",
              width: object.width,
              height: object.height,
              cursor: "pointer",
              // debug
              // border: ".1rem solid red",
            }}
            onMouseEnter={() => {
              setActiveImage(object.getImage());
            }}
            onMouseLeave={() => {
              // disable it if check printer is on
              setActiveImage(
                getBaseImage(
                  StageTwoState.tableView,
                  StageTwoState.stageTwoSolved,
                ),
              );
            }}
            onClick={() => {
              object.onClick(navigate, StageTwoState, dispatch);
            }}
          ></div>
        );
      })}

      {/* Back Btn */}
      {StageTwoState.tableView && (
        <div
          className="common_btn"
          style={{
            position: "absolute",
            top: "5%",
            right: "5%",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(setTableView(false));
          }}
        >
          Back
        </div>
      )}

      {/* BaseImage */}
      <div
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        }}
        className="stage_second"
      >
        <img
          style={{
            height: "100%",
            width: "100%",
          }}
          src={activeImage}
          alt=""
        />
      </div>
    </>
  );
}

export default StageTwo;
