import React, { useCallback, useContext, useEffect, useState } from "react";
import { SocketContext } from "context/socket";
import { SOCKET_EVENTS } from "Constants/Common";
import { useDispatch, useSelector } from "react-redux";
import { fillCell, fillCellArray } from "Store/DrawTheKing";
import { RESOLUTION } from "Constants/DrawTheKing";

import "./DrawArea.scss";

const Cell = React.memo(({ index, color, onMouseDown, onMouseEnter }) => {
  return (
    <div
      onMouseDown={() => onMouseDown(index)}
      onMouseEnter={() => onMouseEnter(index)}
      style={{
        backgroundColor: color,
      }}
      className="draw_cell"
    ></div>
  );
});

function DrawArea({ disabled = false }) {
  const { cells, activeColor } = useSelector((state) => state.drawTheKing);

  const { playerName } = useSelector((state) => state.common);

  const [cellsToFill, setCellsToFill] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  const changeCellColor = useCallback(
    (index, color) => {
      dispatch(fillCell({ cellIndex: index, color }));
    },
    [dispatch],
  );

  const handleMouseDown = useCallback(
    (index) => {
      setIsDragging(true);
      // settings cells to fill as empty array
      setCellsToFill({
        [index]: {
          cellIndex: index,
          color: activeColor,
        },
      });
      changeCellColor(index, activeColor);
    },
    [changeCellColor, activeColor],
  );

  const handleMouseEnter = useCallback(
    (index) => {
      if (isDragging) {
        setCellsToFill((prev) => {
          return {
            ...prev,
            [index]: {
              cellIndex: index,
              color: activeColor,
            },
          };
        });
        changeCellColor(index, activeColor);
      }
    },
    [isDragging, changeCellColor, activeColor, setCellsToFill],
  );

  useEffect(() => {
    const handleMouseUp = () => {
      if (Object.keys(cellsToFill).length === 0) {
        return;
      }

      socket.emit(SOCKET_EVENTS.COLOR_GRID_UPDATE_ARRAY, {
        cellsToFill,
        playerName,
      });
      // clearing cells to fill
      setCellsToFill({});
      setIsDragging(false);
    };

    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [socket, playerName, cellsToFill]);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.COLOR_GRID_UPDATE, ({ cellIndex, color }) => {
      dispatch(fillCell({ cellIndex, color }));
    });

    socket.on(SOCKET_EVENTS.COLOR_GRID_UPDATE_ARRAY, (cellsToFill) => {
      dispatch(fillCellArray(cellsToFill));
    });

    socket.on(SOCKET_EVENTS.UNDO_MOVE, ({ data }) => {
      dispatch(fillCellArray(data));
    });

    socket.on(SOCKET_EVENTS.REDO_MOVE, ({ data }) => {
      dispatch(fillCellArray(data));
    });

    return () => {
      socket.off(SOCKET_EVENTS.COLOR_GRID_UPDATE);
      socket.off(SOCKET_EVENTS.COLOR_GRID_UPDATE_ARRAY);
      socket.off(SOCKET_EVENTS.UNDO_MOVE);
      socket.off(SOCKET_EVENTS.REDO_MOVE);
    };
  }, [socket, dispatch]);

  return (
    <div className="draw_area">
      <div
        className="draw_grid"
        style={{
          gridTemplateColumns: `repeat(${RESOLUTION.COLS}, 1fr)`,
          gridTemplateRows: `repeat(${RESOLUTION.ROWS}, 1fr)`,
          pointerEvents: disabled ? "none" : "auto",
        }}
      >
        {cells.map((color, index) => (
          <Cell
            key={index}
            index={index}
            color={color}
            onMouseDown={handleMouseDown}
            onMouseEnter={handleMouseEnter}
          />
        ))}
      </div>
    </div>
  );
}

export default DrawArea;
