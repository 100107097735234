import React, { useRef, useState } from "react";

import { CommonImages, Videos } from "Assets/Common";
import { videosNavigater } from "./VideoNavigater";
import { useNavigate } from "react-router-dom";
import "./VideoScreen.scss";
import { useSelector } from "react-redux";

function VideoScreen({ videoNumber = "One" }) {
  const { isHost } = useSelector((state) => state.common);

  // video controls
  // const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [showContinue, setShowContinue] = useState(false);
  const videoRef = useRef(null);

  // const togglePlay = () => {
  //   if (videoRef.current) {
  //     if (playing) {
  //       videoRef.current.pause();
  //     } else {
  //       videoRef.current.play();
  //     }
  //     setPlaying(!playing);
  //   }
  // };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setMuted(!muted);
    }
  };

  const handleVideoEnd = () => {
    if (videosNavigater[videoNumber].onEndPath) {
      navigate(videosNavigater[videoNumber].onEndPath);
    }
  };

  const navigate = useNavigate();
  return (
    <>
      {/* Buttons */}
      <div
        style={{
          position: "absolute",
          bottom: "5%",
          left: "5%",
          zIndex: 10,
        }}
        onClick={toggleMute}
        className={`videoScreenButton`}
      >
        {muted ? (
          <img src={CommonImages.icons.volume_off} alt="" />
        ) : (
          <img src={CommonImages.icons.volume_on} alt="" />
        )}
      </div>

      {/* DISABLE PLAY BUTTON */}
      {/* {!playing && (
        <div
          style={{
            position: "absolute",
            bottom: "50%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 10,
          }}
          onClick={togglePlay}
          className="videoScreenButton"
        >
          <img className="player_icon" src={CommonImages.icons.play} alt="" />
        </div>
      )} */}

      {isHost && (
        <div
          style={{
            position: "absolute",
            top: "0%",
            right: "0%",
            zIndex: 10,
          }}
          onClick={handleVideoEnd}
          className="videoScreenButton common_btn"
        >
          Skip
        </div>
      )}

      {showContinue && isHost && (
        <div
          style={{
            position: "absolute",
            bottom: "5%",
            right: "5%",
            zIndex: 10,
          }}
          onClick={handleVideoEnd}
          className="videoScreenButton common_btn"
        >
          Continue
        </div>
      )}

      <div className="video_screen">
        <video
          ref={videoRef}
          muted={muted}
          onEnded={() => {
            if (videosNavigater[videoNumber].hideContinueButton) {
              handleVideoEnd();
            } else {
              setShowContinue(true);
            }
          }}
          autoPlay={true}
        >
          <source src={Videos[videoNumber]} type="video/mp4" />
        </video>
      </div>
    </>
  );
}

export default VideoScreen;
