import React from "react";

import "./CombinationLockInput.scss";

import UpwardTriangle from "MicroComponents/UpwardTriangle/UpwardTriangle";

function CombinationLockInput({ value, nextChar, prevChar, index }) {
  return (
    <div className="combinationLockInput">
      <div
        className="combinationLockInput_upwardTringle combinationLockInput_tringle"
        onClick={() => {
          nextChar(index);
        }}
      >
        <UpwardTriangle height={25} width={25} fill={"rgba(84, 56, 55, 1)"} />
      </div>
      <input type="text" value={value} readOnly />
      <div
        className="combinationLockInput_downwardTringle combinationLockInput_tringle"
        onClick={() => {
          prevChar(index);
        }}
      >
        <UpwardTriangle height={25} width={25} fill={"rgba(84, 56, 55, 1)"} />
      </div>
    </div>
  );
}

export default CombinationLockInput;
