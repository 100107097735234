import {
  DRAGGABLE_ELEMENT_ENUM,
  MUSIC_BOARD_DIVISION,
  ROWS_ENUM,
} from "Constants/StageOne";

export const correctPositionsX = {
  // row 1
  [DRAGGABLE_ELEMENT_ENUM.ONE]: { x: 22 * MUSIC_BOARD_DIVISION },
  [DRAGGABLE_ELEMENT_ENUM.TWO]: { x: 31 * MUSIC_BOARD_DIVISION },
  [DRAGGABLE_ELEMENT_ENUM.THREE]: { x: 38 * MUSIC_BOARD_DIVISION },
  // row 2
  [DRAGGABLE_ELEMENT_ENUM.FOUR]: { x: 3 * MUSIC_BOARD_DIVISION },
  [DRAGGABLE_ELEMENT_ENUM.FIVE]: { x: 10 * MUSIC_BOARD_DIVISION },
  [DRAGGABLE_ELEMENT_ENUM.SIX]: { x: 22 * MUSIC_BOARD_DIVISION },
  [DRAGGABLE_ELEMENT_ENUM.SEVEN]: { x: 31 * MUSIC_BOARD_DIVISION },
  [DRAGGABLE_ELEMENT_ENUM.EIGHT]: { x: 38 * MUSIC_BOARD_DIVISION },
  // row 3
  [DRAGGABLE_ELEMENT_ENUM.NINE]: { x: -8 * MUSIC_BOARD_DIVISION },
  [DRAGGABLE_ELEMENT_ENUM.TEN]: { x: 24 * MUSIC_BOARD_DIVISION },
  [DRAGGABLE_ELEMENT_ENUM.ELEVEN]: { x: 31 * MUSIC_BOARD_DIVISION },
  [DRAGGABLE_ELEMENT_ENUM.TWELVE]: { x: 38 * MUSIC_BOARD_DIVISION },
};

export const initialPositions = {
  // row 1
  [DRAGGABLE_ELEMENT_ENUM.ONE]: {
    x: -13 * MUSIC_BOARD_DIVISION,
    y: 230,
    poolRowTwo: true,
  },
  [DRAGGABLE_ELEMENT_ENUM.TWO]: {
    x: 30 * MUSIC_BOARD_DIVISION,
    y: 170,
    poolRowOne: true,
  },
  [DRAGGABLE_ELEMENT_ENUM.THREE]: {
    x: 38 * MUSIC_BOARD_DIVISION,
    y: 170,
    poolRowOne: true,
  },
  // row 2
  [DRAGGABLE_ELEMENT_ENUM.FOUR]: {
    x: 29 * MUSIC_BOARD_DIVISION,
    y: 170,
    poolRowOne: true,
  },
  [DRAGGABLE_ELEMENT_ENUM.FIVE]: {
    x: 32 * MUSIC_BOARD_DIVISION,
    y: 230,
    poolRowTwo: true,
  },
  [DRAGGABLE_ELEMENT_ENUM.SIX]: {
    x: 17 * MUSIC_BOARD_DIVISION,
    y: 170,
    poolRowOne: true,
  },
  [DRAGGABLE_ELEMENT_ENUM.SEVEN]: {
    x: 32 * MUSIC_BOARD_DIVISION,
    y: 230,
    poolRowTwo: true,
  },
  [DRAGGABLE_ELEMENT_ENUM.EIGHT]: {
    x: -19 * MUSIC_BOARD_DIVISION,
    y: 170,
    poolRowOne: true,
  },
  // row 3
  [DRAGGABLE_ELEMENT_ENUM.NINE]: {
    x: 16 * MUSIC_BOARD_DIVISION,
    y: 230,
    poolRowTwo: true,
  },
  [DRAGGABLE_ELEMENT_ENUM.TEN]: {
    x: 23 * MUSIC_BOARD_DIVISION,
    y: 170,
    poolRowOne: true,
  },
  [DRAGGABLE_ELEMENT_ENUM.ELEVEN]: {
    x: 4 * MUSIC_BOARD_DIVISION,
    y: 230,
    poolRowTwo: true,
  },
  [DRAGGABLE_ELEMENT_ENUM.TWELVE]: {
    x: -2 * MUSIC_BOARD_DIVISION,
    y: 170,
    poolRowOne: true,
  },
};

export const draggableObjects = {
  [DRAGGABLE_ELEMENT_ENUM.ONE]: {
    position: initialPositions.one,
    targetRow: ROWS_ENUM.NONE,
    lastStopPosition: {
      ...initialPositions.one,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: null,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 9,
    correctRowPosition: ROWS_ENUM.ONE,
    correctPositionInSeconds: 30,
  },
  [DRAGGABLE_ELEMENT_ENUM.TWO]: {
    position: initialPositions.two,
    targetRow: ROWS_ENUM.ONE,
    lastStopPosition: {
      ...initialPositions.two,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: 39,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 7,
    isFixed: true,
    correctRowPosition: ROWS_ENUM.ONE,
    correctPositionInSeconds: 39,
  },
  [DRAGGABLE_ELEMENT_ENUM.THREE]: {
    position: initialPositions.three,
    targetRow: ROWS_ENUM.NONE,
    lastStopPosition: {
      ...initialPositions.three,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: null,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 14,
    correctRowPosition: ROWS_ENUM.ONE,
    correctPositionInSeconds: 46,
  },
  [DRAGGABLE_ELEMENT_ENUM.FOUR]: {
    position: initialPositions.four,
    targetRow: ROWS_ENUM.TWO,
    lastStopPosition: {
      ...initialPositions.four,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: 11,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 7,
    isFixed: true,
    correctRowPosition: ROWS_ENUM.TWO,
    correctPositionInSeconds: 11,
  },
  [DRAGGABLE_ELEMENT_ENUM.FIVE]: {
    position: initialPositions.five,
    targetRow: ROWS_ENUM.NONE,
    lastStopPosition: {
      ...initialPositions.five,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: null,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 12,
    correctRowPosition: ROWS_ENUM.TWO,
    correctPositionInSeconds: 18,
  },
  [DRAGGABLE_ELEMENT_ENUM.SIX]: {
    position: initialPositions.six,
    targetRow: ROWS_ENUM.NONE,
    lastStopPosition: {
      ...initialPositions.six,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: null,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 9,
    correctRowPosition: ROWS_ENUM.TWO,
    correctPositionInSeconds: 30,
  },
  [DRAGGABLE_ELEMENT_ENUM.SEVEN]: {
    position: initialPositions.seven,
    targetRow: ROWS_ENUM.TWO,
    lastStopPosition: {
      ...initialPositions.seven,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: 39,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 7,
    isFixed: true,
    correctRowPosition: ROWS_ENUM.TWO,
    correctPositionInSeconds: 39,
  },
  [DRAGGABLE_ELEMENT_ENUM.EIGHT]: {
    position: initialPositions.eight,
    targetRow: ROWS_ENUM.NONE,
    lastStopPosition: {
      ...initialPositions.eight,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: null,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 14,
    correctRowPosition: ROWS_ENUM.TWO,
    correctPositionInSeconds: 46,
  },
  [DRAGGABLE_ELEMENT_ENUM.NINE]: {
    position: initialPositions.nine,
    targetRow: ROWS_ENUM.NONE,
    lastStopPosition: {
      ...initialPositions.nine,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: null,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 12,
    correctRowPosition: ROWS_ENUM.THREE,
    correctPositionInSeconds: 0,
  },
  [DRAGGABLE_ELEMENT_ENUM.TEN]: {
    position: initialPositions.ten,
    targetRow: ROWS_ENUM.THREE,
    lastStopPosition: {
      ...initialPositions.ten,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: 32,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 7,
    isFixed: true,
    correctRowPosition: ROWS_ENUM.THREE,
    correctPositionInSeconds: 32,
  },
  [DRAGGABLE_ELEMENT_ENUM.ELEVEN]: {
    position: initialPositions.eleven,
    targetRow: ROWS_ENUM.NONE,
    lastStopPosition: {
      ...initialPositions.eleven,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: null,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 7,
    correctRowPosition: ROWS_ENUM.THREE,
    correctPositionInSeconds: 39,
  },
  [DRAGGABLE_ELEMENT_ENUM.TWELVE]: {
    position: initialPositions.twelve,
    targetRow: ROWS_ENUM.NONE,
    lastStopPosition: {
      ...initialPositions.twelve,
      targetRow: ROWS_ENUM.NONE,
    },
    positionInSeconds: null,
    lastStopPositionInSeconds: null,
    audioPlaying: false,
    durationInSeconds: 15,
    correctRowPosition: ROWS_ENUM.THREE,
    correctPositionInSeconds: 46,
  },
};
