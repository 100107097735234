import { Navigate } from "react-router-dom";

import { StageOneRoutes } from "Views/StageOne";
import { StageTwoRoutes } from "Views/StageTwo";
import { LoginRoutes } from "Views/Login";
import { WaitingScreenRoutes } from "Views/WaitingScreen";
import { LoaderRoutes } from "Views/Loader";
import { CrosswordRoutes } from "Views/Crossword";
import { ChessRoutes } from "Views/Chess";
import { DrawTheKingRoutes } from "Views/DrawTheKing";
import { VideoScreensRoutes } from "Views/Videos";
import { MazeRoutes } from "Views/Maze";
import { KaraokeRoutes } from "Views/Karaoke";
import { TheEndRoutes } from "Views/End";
const DEFAULT_ROUTE = "/";

export const guestRoutes = [
  {
    path: "*",
    element: <Navigate to={DEFAULT_ROUTE} />,
  },
  ...StageOneRoutes,
  ...StageTwoRoutes,
  ...LoginRoutes,
  ...WaitingScreenRoutes,
  ...LoaderRoutes,
  ...CrosswordRoutes,
  ...ChessRoutes,
  ...DrawTheKingRoutes,
  ...VideoScreensRoutes,
  ...MazeRoutes,
  ...KaraokeRoutes,
  ...TheEndRoutes,
];

export const authenticatedRoutes = [];
