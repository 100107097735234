import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { SocketContext, socket } from "context/socket";

import RootRouter from "./Routes/RootRouter";
import { BrowserRouter } from "react-router-dom";
import { store, persistor } from "./Store";

import Location from "Components/Location/Location";
import Socket from "Components/Socket/Socket";

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter basename={"/"}>
            <RootRouter />
            {/* ALWAYS ATTACHED */}
            <Socket />
            <Location />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </SocketContext.Provider>
  );
}

export default App;
