import {
  DRAGGABLE_POSITION_OFFSET,
  MUSIC_BOARD_DIVISION,
} from "Constants/StageOne";
import React from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";

import "./DraggableSound.scss";
import { CommonImages } from "Assets/Common";
import { updateIsTimelinePlaying } from "Store/StageOne";
import { StageOneImages } from "Assets/StageOne";

function DraggableSound({
  audiosPlaying,
  draggableId,
  draggableReferences,
  handleStart,
  handleDrag,
  handleStop,
  toggleAudio,
  pieceSynced,
  scale = 1,
}) {
  const { draggableObjects } = useSelector((state) => state.stageOne);

  const { inControl } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  return (
    <Draggable
      axis="both"
      handle={`.handle_${draggableId}`}
      position={draggableObjects[draggableId].position}
      positionOffset={{
        x:
          Math.round(
            (draggableObjects[draggableId].durationInSeconds *
              MUSIC_BOARD_DIVISION) /
              2,
          ) -
          MUSIC_BOARD_DIVISION * 20 +
          // adding constant values to offset offset to align peices with the timeline
          DRAGGABLE_POSITION_OFFSET +
          (window.innerHeight > 1000 ? -3 : -2),
        y: 0,
      }}
      scale={scale}
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleStop}
      nodeRef={draggableReferences[[draggableId]]}
      disabled={!inControl || draggableObjects[draggableId].isFixed}
    >
      {draggableObjects[draggableId].targetRow ? (
        <div
          className={`handle_${draggableId} draggable_items ${pieceSynced ? "" : "peieceNotSynced"} ${inControl ? "" : "animateDraggables"}`}
          ref={draggableReferences[draggableId]}
          style={{
            backgroundImage: `url("${StageOneImages.waveforms[draggableId]}")`,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backgroundSize: "100% 100%",
            borderRadius: "4px",
            zIndex: draggableObjects[draggableId].isFixed ? 3 : 2,
            border: draggableObjects[draggableId].isFixed
              ? "1px solid #000"
              : "",
            cursor: draggableObjects[draggableId].isFixed ? "default" : "grab",
            width: `${draggableObjects[draggableId].durationInSeconds * MUSIC_BOARD_DIVISION}px`,
          }}
        ></div>
      ) : (
        <div
          className={`handle_${draggableId} draggable_items ${pieceSynced ? "" : "peieceNotSynced"} ${inControl ? "" : "animateDraggables"}`}
          ref={draggableReferences[draggableId]}
          style={{
            backgroundImage: `url("${StageOneImages.draggables[draggableId]}")`,
            backgroundSize: "100% 100%",
            width: `${draggableObjects[draggableId].durationInSeconds * MUSIC_BOARD_DIVISION}px`,
            zIndex: 5,
          }}
        >
          {
            <div
              className="draggable_item"
              onClick={() => {
                dispatch(updateIsTimelinePlaying(false));
                toggleAudio(draggableId)();
              }}
            >
              {audiosPlaying[draggableId] ? (
                <img src={CommonImages.icons.stop} alt="" />
              ) : (
                <img src={CommonImages.icons.play} alt="" />
              )}{" "}
              {/* DEBUG */}
              {/* {draggableId} */}
              {/* DRAGGABLE SIZE */}
              {/* {draggableObjects[draggableId].durationInSeconds} */}
            </div>
          }
        </div>
      )}
    </Draggable>
  );
}

export default DraggableSound;
