import React from "react";

import { DrawTheKingImages } from "Assets/StageTwo";

import "./KingImagePopup.scss";

function KingImagePopup({ onClose = () => {} }) {
  return (
    <div
      className="kingImagePopup"
      onClick={(e) => {
        if (e.target.classList.contains("kingImagePopup")) {
          onClose();
        }
      }}
    >
      <div className="kingImagePopupContainer">
        <div className="kingImagePopupContainerImageContainer">
          <img src={DrawTheKingImages.king} alt="" />
        </div>
        {/* <div className="common_btn" onClick={onClose}>
          Close
        </div> */}
      </div>
    </div>
  );
}

export default KingImagePopup;
