import React from "react";

function UpwardTriangle({ height, width, fill }) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
    >
      <path
        fill={fill}
        d="M8 1.25a2.1 2.1 0 0 0-1.785.996l.64.392-.642-.388-5.675 9.373-.006.01a2.065 2.065 0 0 0 .751 2.832c.314.183.67.281 1.034.285h11.366a2.1 2.1 0 0 0 1.791-1.045 2.06 2.06 0 0 0-.006-2.072L9.788 2.25l-.003-.004A2.08 2.08 0 0 0 8 1.25"
      />
    </svg>
  );
}

export default UpwardTriangle;
