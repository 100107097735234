import React, { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { SocketContext } from "context/socket";

import { ROUTES, SOCKET_EVENTS } from "Constants/Common";
import {
  updateInControl,
  updateIsHost,
  updatePlayerNameAndLeader,
  updateRoomCode,
  updateParticipants,
} from "Store/Common";

import "./Login.scss";
import { CommonImages } from "Assets/Common";

function Login() {
  const socket = useContext(SocketContext);

  const dispatch = useDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  const [username, setUsername] = React.useState("");
  const [roomCode, setRoomCode] = React.useState(
    location.state?.roomCode || "",
  );
  const [isHost, setIsHost] = React.useState(false);
  const [isLeader, setIsLeader] = React.useState(false);
  const [loggingIn, setLoggingIn] = React.useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleRoomCodeChange = (e) => {
    setRoomCode(e.target.value);
  };

  const handleJoinRoom = () => {
    if (loggingIn) {
      return;
    }

    const { eventId, roomCode, eventCode } = location.state || {};

    if (!username || !roomCode) {
      alert("Please enter username and room code");
      return;
    }

    // check if name contains only alphabets, spaces and numbers
    if (!/^[a-zA-Z0-9 ]+$/.test(username)) {
      alert(
        "Please enter a valid name. Only alphabets, spaces and numbers are allowed.",
      );
      return;
    }

    setLoggingIn(true);

    // emit join event
    socket.emit(
      SOCKET_EVENTS.JOIN_ROOM,
      {
        username,
        roomCode,
        eventCode,
        eventId,
        isLeader,
        isHost,
      },
      (data) => {
        setLoggingIn(false);

        if (data.error) {
          console.error(data.error);
          alert(data.error);
          return;
        }

        // save player name and leader in redux
        dispatch(
          updatePlayerNameAndLeader({
            playerName: username,
            isLeader: isLeader,
          }),
        );

        // save isHost in redux
        dispatch(updateIsHost({ isHost: isHost }));

        // save room data in redux
        dispatch(
          updateParticipants({ participants: data.data.room.participants }),
        );

        // update roomCode in redux
        dispatch(updateRoomCode({ roomCode: roomCode }));

        // check if user is first participant, if yes, take control
        if (data.data.isFirstParticipant) {
          dispatch(
            updateInControl({
              inControl: true,
              inControlPlayerName: username,
            }),
          );
        } else {
          dispatch(
            updateInControl({
              inControl: false,
              inControlPlayerName: data.data.room.inControl,
            }),
          );
        }

        saveRoomData();

        const activeStage = data.data?.room?.activeStage;

        if (
          activeStage &&
          activeStage !== ROUTES.WAITING_SCREEN &&
          activeStage !== ROUTES.LOGIN
        ) {
          navigate(activeStage);
        } else {
          navigate(ROUTES.WAITING_SCREEN);
        }
      },
    );
  };

  const saveRoomData = useCallback(() => {
    const { eventId, roomCode, eventCode } = location.state || {};

    if (eventId && roomCode && eventCode) {
      // set socket_room_data in session storage
      sessionStorage.setItem(
        "socket_room_data",
        JSON.stringify({
          eventId: eventId,
          roomCode: roomCode,
          eventCode: eventCode,
          // set three hour expiry
          expiresAt: Date.now() + 1000 * 60 * 60 * 3,
        }),
      );
    } else {
      console.error("COULD NOT SAVE ROOM DATA");
    }
  }, [location.state]);

  return (
    <div className="login_page">
      <div className="game_logo">
        <img src={CommonImages.logo} alt="Save the Queen Logo" />
      </div>
      <div className="login_form">
        <h1>Login</h1>
        <div className="login_form_input">
          <label htmlFor="room code">Game Code</label>
          <input
            autoComplete="off"
            type="text"
            id="room code"
            onChange={handleRoomCodeChange}
            value={roomCode}
            readOnly
          />
        </div>

        <div className="login_form_input">
          <label htmlFor="username">Your Name</label>
          <input
            autoComplete="off"
            type="text"
            id="username"
            onChange={handleUsernameChange}
            value={username}
          />
        </div>

        <div className="login_form_checkbox">
          <label htmlFor="isHostCheckbox">Are You The Game Host?</label>
          <input
            type="checkbox"
            id="isHostCheckbox"
            className={`${isHost ? "is_host_checked" : ""}`}
            onChange={(e) => {
              setIsHost(e.target.checked);
            }}
            value={isHost}
          />
        </div>
        <div
          className="login_form_checkbox"
          style={{
            // DISABLING LEADER CHECKBOX FOR NOW
            display: "none",
          }}
        >
          <label htmlFor="isLeader">Are you the leader?</label>
          <input
            type="checkbox"
            id="isLeader"
            onChange={(e) => {
              setIsLeader(e.target.checked);
            }}
            value={isLeader}
          />
        </div>

        <div className="login_form_btn common_btn" onClick={handleJoinRoom}>
          {loggingIn ? "Joining..." : "Join"}
        </div>
      </div>
    </div>
  );
}

export default Login;
