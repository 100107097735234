export const COMBINATION_LOCK_ONE_SOLUTION = ["F", "4"];

export const COMBINATION_LOCK_TWO_SOLUTION = ["T", "U", "D", "O", "R"];

export const CROSSWORD_HINT_PANELS = {
  ACROSS: "Across",
  DOWN: "Down",
};

export const HINT_ITEM_STATES = {
  NONE: "NONE",
  STRIKED: "STRIKED",
  HIGHLIGHTED: "HIGHLIGHTED",
};

export const HINT_ITEM_STATES_ORDER = [
  HINT_ITEM_STATES.NONE,
  HINT_ITEM_STATES.STRIKED,
  HINT_ITEM_STATES.HIGHLIGHTED,
];

export const CHESS_HINT_PANELS = {
  BLACK: "black",
  WHITE: "white",
};

export const CHESS_PIECE_TYPES = {
  PAWN: "pawn",
  ROOK: "rook",
  KNIGHT: "knight",
  BISHOP: "bishop",
  QUEEN: "queen",
  KING: "king",
};

export const CHESS_BOARD_DIMENSIONS = {
  ROWS: 8,
  COLUMNS: 8,
};

export const CHESS_PIECE_COLORS = {
  WHITE: "white",
  BLACK: "black",
};

export const MESSAGES = {
  RESET_POPUP: {
    MESSAGE: "Be Warned",
    SUBMESSAGE:
      "You are about to reset the entire board back to its original condition. Are you sure you want to do this?",
    OK_BUTTON_TEXT: "Reset",
  },
  STAGE_ONE_HINT_ONE_POPUP: {
    MESSAGE: "Hint #1?",
    SUBMESSAGE:
      "Please give the team the answer to any THREE questions of their choosing. Once done, click Confirm.",
    OK_BUTTON_TEXT: "Confirm",
  },
  STAGE_ONE_HINT_TWO_POPUP: {
    MESSAGE: "Hint #2?",
    SUBMESSAGE:
      "Do you want to show the second hint? This action can not be undone.",
    OK_BUTTON_TEXT: "Show Hint",
  },
  COMBINATION_LOCK_ONE_SOLUTION_POPUP: {
    MESSAGE: "You found a KEY to the vault that contains the Crown Jewels",
  },
  COMBINATION_LOCK_TWO_SOLUTION_POPUP: {
    MESSAGE: "You found a KEY to the Queen's Cell",
  },
};
