import { createSlice } from "@reduxjs/toolkit";

const common = createSlice({
  name: "common",
  initialState: {
    // user data
    isLeader: false,
    isHost: false,
    inControl: false,
    inControlTwo: false,
    playerName: "",
    inControlPlayerName: "",
    inControlPlayerNameTwo: "",
    connected: false,
    roomCode: "",
    participants: [],
  },
  reducers: {
    updatePlayerNameAndLeader: (state, action) => {
      state.playerName = action.payload.playerName;
      state.isLeader = action.payload.isLeader;
    },
    updateIsHost: (state, action) => {
      state.isHost = action.payload.isHost;
    },
    updateInControl: (state, action) => {
      state.inControl = action.payload.inControl;
      state.inControlPlayerName = action.payload.inControlPlayerName;
    },
    updateInControlTwo: (state, action) => {
      state.inControlTwo = action.payload.inControlTwo;
      state.inControlPlayerNameTwo = action.payload.inControlPlayerNameTwo;
    },
    updateParticipants: (state, action) => {
      state.participants = action.payload.participants;
    },
    updateRoomCode: (state, action) => {
      state.roomCode = action.payload.roomCode;
    },
    updateConnected: (state, action) => {
      state.connected = action.payload.connected;
    },
  },
});

export const {
  updatePlayerNameAndLeader,
  updateIsHost,
  updateInControl,
  updateInControlTwo,
  updateRoomCode,
  updateConnected,
  updateParticipants,
} = common.actions;

export default common.reducer;
