export const MUSIC_BOARD_DIVISION = 15;

export const MUSIC_BOARD_WIDTH_IN_PIXELS = 61 * MUSIC_BOARD_DIVISION;

export const MUSIC_BOARD_DURATION_IN_SECONDS = 61;

export const PIXEL_TO_SECOND_OFFSET = 120;

export const DRAGGABLE_POSITION_OFFSET = 65;

export const ROWS_ENUM = {
  ONE: "one",
  TWO: "two",
  THREE: "three",
  NONE: "",
};

export const DRAGGABLE_ELEMENT_ENUM = {
  ONE: "one",
  TWO: "two",
  THREE: "three",
  FOUR: "four",
  FIVE: "five",
  SIX: "six",
  SEVEN: "seven",
  EIGHT: "eight",
  NINE: "nine",
  TEN: "ten",
  ELEVEN: "eleven",
  TWELVE: "twelve",
};

export const INSTRUMENTS = {
  DRUM: "drum",
  CLAIRENET: "clairenet",
  HORN: "horn",
};

export const MESSAGES = {
  RESET_POPUP: {
    MESSAGE: "Be Warned",
    SUBMESSAGE:
      "You are about to reset the entire board back to its original condition. Are you sure you want to do this?",
    OK_BUTTON_TEXT: "Reset",
  },
  STAGE_ONE_HINT_ONE_POPUP: {
    MESSAGE: "Warning Message",
    SUBMESSAGE:
      "Do you want to show the first hint? This action can not be undone.",
    OK_BUTTON_TEXT: "Show Hint",
  },
  STAGE_ONE_HINT_TWO_POPUP: {
    MESSAGE: "Warning Message",
    SUBMESSAGE:
      "Do you want to show the second hint? This action can not be undone.",
    OK_BUTTON_TEXT: "Show Hint",
  },
};
