import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBoard,
  fillCellArray,
  setActiveColor,
  setSavedColors,
  setShowAllowStageFourPopup,
} from "Store/DrawTheKing";

import "./ColorControl.scss";
import { DrawTheKingImages } from "Assets/StageTwo";
import { SocketContext } from "context/socket";
import { SOCKET_EVENTS } from "Constants/Common";
import CustomColorPicker from "Components/ColorPicker/ColorPicker";
import { CommonImages } from "Assets/Common";

function ColorControl({
  drawingSubmitted,
  showKingImage = () => {},
  showClearPopup = () => {},
  showConfirmSubmitPopup = () => {},
}) {
  const { baseColors, savedColors, activeColor, showContinueButton } =
    useSelector((state) => state.drawTheKing);

  const { isHost } = useSelector((state) => state.common);

  const { playerName } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  const [erasing, setErasing] = React.useState(false);
  const [colorBeforeErasing, setColorBeforeErasing] = React.useState(
    activeColor || "#000000",
  );

  const socket = useContext(SocketContext);

  const handleColorChange = (value) => {
    dispatch(setActiveColor(value));
  };

  const handleSaveColor = () => {
    // ignore if active color is transparent
    if (activeColor === "transparent") {
      return;
    }

    socket.emit(
      SOCKET_EVENTS.SAVE_COLOR_DATA,
      {
        color: activeColor,
      },
      (data) => {
        if (data.error) {
          alert(data.error);
        }

        if (data.data) {
          dispatch(setSavedColors(data.data));
        }
      },
    );
  };

  const handleUndoMove = () => {
    socket.emit(SOCKET_EVENTS.UNDO_MOVE, { playerName }, (data) => {
      if (data.error) {
        console.error(data.error);
        return;
      }

      dispatch(fillCellArray(data.data));
    });
  };

  const handleRedoMove = () => {
    socket.emit(SOCKET_EVENTS.REDO_MOVE, { playerName }, (data) => {
      if (data.error) {
        console.error(data.error);
        return;
      }

      dispatch(fillCellArray(data.data));
    });
  };

  useEffect(() => {
    socket.on(SOCKET_EVENTS.SAVE_COLOR_DATA, (data) => {
      dispatch(setSavedColors(data));
    });

    socket.on(SOCKET_EVENTS.CLEAR_BOARD, () => {
      dispatch(clearBoard());
    });

    return () => {
      socket.off(SOCKET_EVENTS.SAVE_COLOR_DATA);
      socket.off(SOCKET_EVENTS.CLEAR_BOARD);
    };
  }, [dispatch, socket]);

  return (
    <div className="color_control">
      <div className="color_control_content">
        <div className="reference_image" onClick={showKingImage}>
          <img src={DrawTheKingImages.king} alt="" />
        </div>

        <div className="color_selector">
          <div className="color_picker_and_earser">
            {erasing ? (
              <div
                className="erase_color"
                onClick={() => {
                  // ENABLE THIS TO MAKE BOTH ERASER BEHAVIOR TO BE SAME
                  // if (erasing) {
                  //   setErasing(false);
                  //   handleColorChange("#000000");
                  // } else {
                  //   setErasing(true);
                  //   handleColorChange("transparent");
                  // }
                }}
              >
                <img src={DrawTheKingImages.eraser} alt="" />
              </div>
            ) : (
              <CustomColorPicker />
            )}
            <div
              className="save_color common_btn"
              onClick={handleSaveColor}
              style={{}}
            >
              Save Color
            </div>
          </div>
          <div className="color_control_undo_redo">
            <div className="undo" onClick={handleUndoMove}>
              <img src={CommonImages.icons.undo_icon} alt="" />
              <span>Undo</span>
            </div>
            <div className="redo" onClick={handleRedoMove}>
              <img src={CommonImages.icons.redo_icon} alt="" />
              <span>Redo</span>
            </div>
          </div>
        </div>

        <div className="base_color_container">
          <h3>Base Colors</h3>
          <div className="colors_container">
            <div
              className="erase_color"
              onClick={() => {
                if (erasing) {
                  setErasing(false);
                  handleColorChange(colorBeforeErasing);
                } else {
                  setColorBeforeErasing(activeColor);
                  setErasing(true);
                  handleColorChange("transparent");
                }
              }}
            >
              <img src={DrawTheKingImages.eraser} alt="" />
            </div>
            {baseColors.map((baseColor, index) => (
              <div
                key={index}
                className="color_cell"
                onClick={() => {
                  setErasing(false);
                  handleColorChange(baseColor);
                }}
                style={{ backgroundColor: baseColor }}
              ></div>
            ))}
          </div>
        </div>

        <div className="saved_color_container">
          <h3>Saved Colors</h3>
          <div className="colors_container">
            {savedColors.map((savedColor, index) => (
              <div
                key={index}
                className="color_cell"
                onClick={() => handleColorChange(savedColor)}
                style={{ backgroundColor: savedColor }}
              ></div>
            ))}
          </div>
        </div>
        <div className="color_control_buttons">
          {isHost && showContinueButton ? (
            <div
              className="common_btn"
              onClick={() => {
                dispatch(setShowAllowStageFourPopup());
              }}
              style={{}}
            >
              Continue
            </div>
          ) : (
            <>
              {!drawingSubmitted && !isHost && (
                <>
                  <div
                    className="common_btn"
                    onClick={showClearPopup}
                    style={{}}
                  >
                    Clear
                  </div>
                  <div
                    className="submit_draw common_btn"
                    onClick={showConfirmSubmitPopup}
                  >
                    Submit
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ColorControl;
