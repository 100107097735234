import React from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";

import "./ChessPiece.scss";
import { ChessPuzzleImages } from "Assets/StageTwo";

function ChessPiece({
  pieceId,
  draggableReferences,
  handleStart,
  handleDrag,
  handleStop,
  syncComplete,
  color,
}) {
  const { pieceData, puzzleSolved, solvedPuzzleAnimationDisabled } =
    useSelector((state) => state.chess);

  const { isHost, inControl } = useSelector((state) => state.common);

  return (
    <Draggable
      axis="both"
      handle={`.handle_${pieceId}`}
      position={pieceData[pieceId].position}
      scale={1}
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleStop}
      nodeRef={draggableReferences[pieceId]}
      disabled={pieceData[pieceId].isFixed || isHost || !inControl}
    >
      <div
        className={`handle_${pieceId} draggable_items chess_piece ${puzzleSolved && !solvedPuzzleAnimationDisabled ? "animate_chess_piece" : ""} ${!inControl || !syncComplete ? "chess_piece_transition" : ""}`}
        ref={draggableReferences[pieceId]}
      >
        {
          <div className="draggable_item">
            <img
              src={
                ChessPuzzleImages.chess_pieces[color][pieceData[pieceId].type]
              }
              alt=""
            />
            {/* DEBUG */}
            {/* {pieceId} */}
            {/* DRAGGABLE SIZE */}
            {/* {pieceData[pieceId].durationInSeconds} */}
          </div>
        }
      </div>
    </Draggable>
  );
}

export default ChessPiece;
