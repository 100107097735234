import React from "react";
import { Slider } from "@mui/material";

import "./VolSlider.scss";
import { useDispatch, useSelector } from "react-redux";
import { setVolume } from "Store/StageOne";
// import { CommonImages } from "Assets/Common";

function VolSlider() {
  const { volume } = useSelector((state) => state.stageOne);

  const dispatch = useDispatch();

  return (
    <div className="volSlider">
      {/* <img src={CommonImages.icons.volume_on} alt="" /> */}
      <Slider
        style={{
          width: "20rem",
          height: ".5rem",
          color: "#f6e5b4",
        }}
        valueLabelDisplay="off"
        shiftStep={5}
        step={1}
        marks={false}
        min={1}
        max={100}
        value={Math.round(volume * 100)}
        onChange={(e, value) => {
          dispatch(setVolume(value / 100));
        }}
      />
    </div>
  );
}

export default VolSlider;
