import VideoScreen from "../Views/VideoScreen";

const routes = [
  {
    path: "/v/one",
    element: <VideoScreen videoNumber={"one"} />,
    title: "video screen one",
  },
  {
    path: "/v/two",
    element: <VideoScreen videoNumber={"two"} />,
    title: "video screen two",
  },
  {
    path: "/v/three",
    element: <VideoScreen videoNumber={"three"} />,
    title: "video screen three",
  },
  {
    path: "/v/four",
    element: <VideoScreen videoNumber={"four"} />,
    title: "video screen four",
  },
  {
    path: "/v/five",
    element: <VideoScreen videoNumber={"five"} />,
    title: "video screen five",
  },
  {
    path: "/v/six",
    element: <VideoScreen videoNumber={"six"} />,
    title: "video screen six",
  },
  {
    path: "/v/seven",
    element: <VideoScreen videoNumber={"seven"} />,
    title: "video screen seven",
  },
  {
    path: "/v/eight",
    element: <VideoScreen videoNumber={"eight"} />,
    title: "video screen eight",
  },
  {
    path: "/v/nine",
    element: <VideoScreen videoNumber={"nine"} />,
    title: "video screen nine",
  },
  {
    path: "/v/ten",
    element: <VideoScreen videoNumber={"ten"} />,
    title: "video screen ten",
  },
  {
    path: "/v/eleven",
    element: <VideoScreen videoNumber={"eleven"} />,
    title: "video screen eleven",
  },
  {
    path: "/v/twelve",
    element: <VideoScreen videoNumber={"twelve"} />,
    title: "video screen twelve",
  },
  {
    path: "/v/thirteen",
    element: <VideoScreen videoNumber={"thirteen"} />,
    title: "video screen thirteen",
  },
];

export default routes;
