import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { SocketContext } from "context/socket";
import { SOCKET_EVENTS, VIDEO_ROUTES } from "Constants/Common";

import "./WaitingScreen.scss";

function WaitingScreen() {
  const { roomCode, participants, isHost } = useSelector(
    (state) => state.common,
  );

  const socket = useContext(SocketContext);

  // const dispatch = useDispatch();

  const navigate = useNavigate();

  const startGame = () => {
    socket.emit(SOCKET_EVENTS.START_GAME, {
      roomCode: roomCode,
    });

    navigate(VIDEO_ROUTES.ONE);
  };

  return (
    <div className="waiting_screen">
      <div className="waitin_screen_board">
        <h1>Waiting For All Players</h1>
        <h2>Room Code: {roomCode}</h2>
        {participants && participants.length > 0 ? (
          <div className="player_list">
            {participants.map((participant, index) => (
              <li key={JSON.stringify({ participant, index })}>
                {participant}
              </li>
            ))}
          </div>
        ) : (
          <div className="player_list">
            <li>No Participants</li>
          </div>
        )}

        {isHost && (
          <div className="common_btn" onClick={startGame}>
            START
          </div>
        )}
      </div>
    </div>
  );
}

export default WaitingScreen;
