import { ROUTES, VIDEO_ROUTES } from "Constants/Common";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./Maze.scss";

import { MazeImages } from "Assets/StageFour";
import { useDispatch, useSelector } from "react-redux";
import PopUp from "Components/PopUp/PopUp";
import { MESSAGES } from "Constants/StageFour";

const Mazes = [
  {
    id: "one",
    title: "Crown & Mace",
    unSecImage: MazeImages.unsec_jewel_one,
    secImage: MazeImages.jewel_one,
    path: ROUTES.MAZE_ONE,
  },
  {
    id: "two",
    title: "Ampulla & Sword",
    unSecImage: MazeImages.unsec_jewel_two,
    secImage: MazeImages.jewel_two,
    path: ROUTES.MAZE_TWO,
  },
  {
    id: "three",
    title: "Scepter & Orb",
    unSecImage: MazeImages.unsec_jewel_three,
    secImage: MazeImages.jewel_three,
    path: ROUTES.MAZE_THREE,
  },
];

function Maze() {
  const { mazeComplete } = useSelector((state) => state.mazePuzzle);

  const { isHost } = useSelector((state) => state.common);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showEndGamePopup, setShowEndGamePopup] = React.useState(false);

  const handleEndGame = () => {
    // if all mazes are secured
    if (Object.values(mazeComplete).every((val) => val === true)) {
      navigate(VIDEO_ROUTES.THIRTEEN);
    }

    // is some mazes are secured but not all
    else if (Object.values(mazeComplete).some((val) => val === true)) {
      navigate(VIDEO_ROUTES.TWELVE);
    }

    // if no mazes are secured
    else {
      navigate(VIDEO_ROUTES.NINE);
    }
  };

  // this use effect will keep checking if all mazes are secured or not
  useEffect(() => {
    if (Object.values(mazeComplete).every((val) => val === true)) {
      navigate(VIDEO_ROUTES.THIRTEEN);
    }
  }, [mazeComplete, navigate, dispatch]);

  return (
    <>
      {/* DEV BUTTONS */}
      {isHost && (
        <div
          className="stageOne_skip"
          onClick={() => {
            navigate(VIDEO_ROUTES.THIRTEEN);
          }}
        >
          <div
            className="common_btn"
            style={{
              position: "absolute",
              top: "0%",
              right: "0%",
              zIndex: "3",
            }}
          >
            Skip
          </div>
        </div>
      )}
      {/*  DEV BUTTONS END */}
      {/* POPUPS */}
      {showEndGamePopup && (
        <PopUp
          message={MESSAGES.END_GAME_POPUP.MESSAGE}
          subMessage={MESSAGES.END_GAME_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.END_GAME_POPUP.OK_BUTTON_TEXT}
          cancelBtnText={MESSAGES.END_GAME_POPUP.CANCEL_BUTTON_TEXT}
          onOk={() => {
            handleEndGame();
            setShowEndGamePopup(false);
          }}
          onCancel={() => {
            setShowEndGamePopup(false);
          }}
        />
      )}
      <div className="mazePuzzle">
        <div className="mazePuzzle_overlay"></div>
        <h1>
          The Guards Have Split Up The Crown Jewels! Can You Secure Them All
          Before The Time Runs Out?
        </h1>
        <div className="mazePuzzle_container">
          {Mazes.map((maze, index) => (
            <div className="maze_portal" key={"maze_idx_" + index}>
              <h1>{maze.title}</h1>
              <img
                src={mazeComplete[maze.id] ? maze.secImage : maze.unSecImage}
                alt=""
              />
              {mazeComplete[maze.id] ? (
                <h2>SECURED</h2>
              ) : (
                <div
                  className="common_btn"
                  onClick={() => {
                    navigate(maze.path);
                  }}
                >
                  Start
                </div>
              )}
            </div>
          ))}
        </div>
        {isHost && (
          <div
            className="common_btn end_maze_btn"
            onClick={() => {
              setShowEndGamePopup(true);
            }}
          >
            End Game
          </div>
        )}
      </div>
    </>
  );
}

export default Maze;
