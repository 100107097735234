export const SOCKET_EVENTS = {
  TEST: "test",
  // common
  DISCONNECT: "disconnect",
  CONNECT: "connect",
  JOIN_ROOM: "joinRoom",
  REJOIN_ROOM: "rejoinRoom",
  PARTICIPANT_JOINED: "participantJoined",
  TAKE_CONTROL: "takeControl",
  TAKE_CONTROL_TWO: "takeControlTwo",
  SYNC_TAKE_CONTROL: "syncTakeControl",
  PATH_UPDATE: "pathUpdate",
  SYNC_GAME_STATE: "syncGameState",
  START_KARAOKE: "startKaraoke",
  RESET_KARAOKE: "resetKaraoke",
  // Stage one
  START_GAME: "startGame",
  DRAGABBLE_POSITION_UPDATE: "draggablePositionUpdate",
  RESET_MUSIC_BOARD: "resetMusicBoard",
  STAGE_ONE_HINT_ONE: "stageOneHintOne",
  STAGE_ONE_HINT_TWO: "stageOneHintTwo",
  SYNC_DRAGGABLE_POSITION: "syncDraggablePosition",
  // Stage two
  CROSSWORD_GRID_UPDATE: "crosswordGridUpdate",
  RESET_CROSSWORD_GRID: "resetCrosswordGrid",
  CROSSWORD_HINT_ITEM_STATE_UPDATE: "crosswordHintItemStateUpdate",
  STAGE_TWO_HINT_TWO: "stageTwoHintTwo",
  COMBINATION_LOCK_ONE_SOLVED: "combinationLockOneSolved",
  COMBINATION_LOCK_TWO_SOLVED: "combinationLockTwoSolved",
  SYNC_CROSSWORD_GRID: "syncCrosswordGrid",
  STAGE_TWO_DOOR_UNLOCKED: "stageTwoDoorUnlocked",
  // Chess
  CHESS_PIECE_MOVED: "chessPieceMoved",
  CHESS_PIECE_REMOVED: "chessPieceRemoved",
  CHESS_HINT_ONE: "chessHintOne",
  CHESS_HINT_TWO: "chessHintTwo",
  CHESS_PUZZLE_SOLVED: "chessPuzzleSolved",
  CHESS_HINT_ITEM_STATE_UPDATE: "chessHintItemStateUpdate",
  RESET_CHESS_BOARD: "resetChessBoard",
  SYNC_CHESS_PIECE_POSITION: "syncChessPiecePosition",
  // Draw the king
  COLOR_GRID_UPDATE: "colorGridUpdate",
  COLOR_GRID_UPDATE_ARRAY: "colorGridUpdateArray",
  SYNC_DRAW_BOARD: "syncDrawBoard",
  SAVE_COLOR_DATA: "saveColorData",
  CLEAR_BOARD: "clearBoard",
  SUBMIT_DRAWING: "submitDrawing",
  UNDO_MOVE: "undoMove",
  REDO_MOVE: "redoMove",
  PASS_DRAWING: "passDrawing",
  FAIL_DRAWING: "failDrawing",
  // Maze
  STAGE_FOUR_MAZE_SOLVED: "stageFourMazeSolved",
};

export const ROUTES = {
  LOGIN: "/login",
  WAITING_SCREEN: "/waiting-room",
  STAGE_ONE: "/stage-one",
  STAGE_ONE_KARAOKE: "/karaoke",
  STAGE_TWO: "/stage-two",
  CHESS: "/chess-puzzle",
  CROSSWORD: "/crossword",
  DRAW_THE_KING: "/draw-the-king",
  MAZE: "/maze",
  MAZE_ONE: "/maze/one",
  MAZE_TWO: "/maze/two",
  MAZE_THREE: "/maze/three",
  THE_END: "/the-end",
};

export const VIDEO_ROUTES = {
  ONE: "/v/one",
  TWO: "/v/two",
  THREE: "/v/three",
  FOUR: "/v/four",
  FIVE: "/v/five",
  SIX: "/v/six",
  SEVEN: "/v/seven",
  EIGHT: "/v/eight",
  NINE: "/v/nine",
  TEN: "/v/ten",
  ELEVEN: "/v/eleven",
  TWELVE: "/v/twelve",
  THIRTEEN: "/v/thirteen",
};
