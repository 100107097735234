import React, { useContext, useEffect, useRef, useState } from "react";

import "./Karaoke.scss";
import { CommonImages, Videos } from "Assets/Common";
import { useNavigate } from "react-router-dom";
import { SOCKET_EVENTS, VIDEO_ROUTES } from "Constants/Common";
import { useSelector } from "react-redux";
import { SocketContext } from "context/socket";

function Karaoke() {
  const { isHost } = useSelector((state) => state.common);

  const navigate = useNavigate();

  const socket = useContext(SocketContext);

  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleReset = () => {
    socket.emit(SOCKET_EVENTS.RESET_KARAOKE, {}, () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        setPlaying(false);
      }
    });
  };

  const handleFail = () => {
    navigate(VIDEO_ROUTES.THREE);
  };

  const handlePass = () => {
    navigate(VIDEO_ROUTES.FOUR);
  };

  useEffect(() => {
    socket.on(SOCKET_EVENTS.START_KARAOKE, () => {
      if (videoRef.current) {
        videoRef.current.play();
        setPlaying(true);
      }
    });

    socket.on(SOCKET_EVENTS.RESET_KARAOKE, () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        setPlaying(false);
      }
    });
    return () => {
      socket.off(SOCKET_EVENTS.START_KARAOKE);
      socket.off(SOCKET_EVENTS.RESET_KARAOKE);
    };
  }, [socket]);

  return (
    <>
      {isHost && (
        <div
          className="stageOne_skip"
          onClick={() => {
            navigate(VIDEO_ROUTES.FOUR);
          }}
        >
          <div
            className="common_btn"
            style={{
              position: "absolute",
              top: "0%",
              right: "0%",
              zIndex: "3",
            }}
          >
            Skip
          </div>
        </div>
      )}
      <div className="karaokeScreen">
        <img src={CommonImages.logo} alt="" className="karaokeScreen_logo" />
        <div className="karaoke__video">
          {!playing && isHost && (
            <img
              src={CommonImages.icons.play}
              alt=""
              className="karaokeScreenPlay_btn"
              onClick={() => {
                socket.emit(SOCKET_EVENTS.START_KARAOKE, {}, () => {
                  if (videoRef.current) {
                    videoRef.current.play();
                    setPlaying(true);
                  }
                });
              }}
            />
          )}
          <video
            ref={videoRef}
            src={Videos.karaoke}
            controls={false}
            autoPlay={false}
          ></video>
        </div>
        {isHost && (
          <div className="karaoke_btns">
            <div className="common_btn" onClick={handleReset}>
              RESET
            </div>
            <div className="common_btn" onClick={handleFail}>
              FAIL
            </div>
            <div className="common_btn" onClick={handlePass}>
              PASS
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Karaoke;
