import MazePuzzle from "Components/MazeGenerator/MazeGen";
import Maze from "../Views/Maze";

const routes = [
  { path: "/maze", element: <Maze /> },
  { path: "/maze/one", element: <MazePuzzle id={"one"} /> },
  { path: "/maze/two", element: <MazePuzzle id={"two"} /> },
  { path: "/maze/three", element: <MazePuzzle id={"three"} /> },
];

export default routes;
