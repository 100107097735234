import React, { useEffect } from "react";

import "./CombinationLock.scss";
import CombinationLockInput from "Components/CombinationLockInput/CombinationLockInput";
import { getNextChar, getPrevChar } from "Shared/Utilities";
import { useDispatch } from "react-redux";

function CombinationLock({
  solution = ["A", "B", "C"],
  combinationLockSolved,
  onSolved,
  setShowCombinationLock,
  OnSolvePopup,
  solvedPopupMessage,
  solvedPopupImage,
}) {
  const dispatch = useDispatch();

  const [inputValues, setInputValues] = React.useState(solution.map(() => "A"));

  useEffect(() => {
    const checkSolution = () => {
      if (inputValues.join("") === solution.join("")) {
        onSolved();
      }
    };

    checkSolution();
  }, [dispatch, inputValues, onSolved, setShowCombinationLock, solution]);

  const nextChar = (index) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = getNextChar(newInputValues[index]);
    inputValues[index] = newInputValues[index];
    setInputValues(newInputValues);
  };

  const prevChar = (index) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = getPrevChar(newInputValues[index]);
    inputValues[index] = newInputValues[index];
    setInputValues(newInputValues);
  };

  return (
    <div
      className="combinationLock_container"
      onClick={(e) => {
        // close the lock if clicked outside
        if (e.target.className === "combinationLock_container")
          dispatch(setShowCombinationLock(false));
      }}
    >
      {combinationLockSolved ? (
        <OnSolvePopup
          message={solvedPopupMessage}
          image={solvedPopupImage}
          onClose={() => {
            dispatch(setShowCombinationLock(false));
          }}
        />
      ) : (
        <div className="combinationLock">
          <div className="combinationLock_inputs">
            {inputValues.map((input, index) => (
              <CombinationLockInput
                key={index}
                value={inputValues[index]}
                nextChar={nextChar}
                prevChar={prevChar}
                index={index}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CombinationLock;
