import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { api } from "Services/Api/api";
import common from "./Common";
import { setupListeners } from "@reduxjs/toolkit/query";

import stageOne from "./StageOne";
import stageTwo from "./StageTwo";
import crossword from "./Crossword";
import chess from "./Chess";
import drawTheKing from "./DrawTheKing";
import mazePuzzle from "./StageFour";

const rootPersistConfig = {
  key: "root",
  storage: sessionStorage,
};

if (process.env.REACT_APP_SESSION_STORAGE_DISABLED) {
  rootPersistConfig.whitelist = ["common"];
} else {
  rootPersistConfig.whitelist = [
    "common",
    "stageOne",
    "stageTwo",
    "crossword",
    "chess",
    "drawTheKing",
    "mazePuzzle",
  ];
}

const reducers = combineReducers({
  common,
  stageOne,
  stageTwo,
  crossword,
  chess,
  drawTheKing,
  mazePuzzle,
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware);
    return middlewares;
  },
});

const persistor = persistStore(store);
setupListeners(store.dispatch);
export { store, persistor };
