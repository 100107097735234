import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./Crossword.scss";
import {
  setFirstHintUsed,
  setInstrcutionsSeen,
  setPuzzleSolved,
  solvePuzzle,
  toggleHintPanel,
  updateCrosswordGrid,
  updateCrosswordGridComplete,
  updateHintStateToNext,
  updateHintStateToNextComplete,
} from "Store/Crossword";
import HintBoard from "Components/HintBoard/HintBoard";
import { CROSSWORD_HINT_PANELS, MESSAGES } from "Constants/StageTwo";
import { ROUTES, SOCKET_EVENTS } from "Constants/Common";
import { SocketContext } from "context/socket";
import PopUp from "Components/PopUp/PopUp";
import { CommonImages, InstructionScreen } from "Assets/Common";
import InstructionPop from "Components/InstructionPop/InstructionPop";

const instructionDataArray = [
  {
    img: "",
    title: "Instructions",
    description: "A word cross riddle??? What sorcery is this!",
  },
  {
    img: InstructionScreen.crossword.gifs.one,
    title: "",
    description:
      "“Take control” of the board and type letters into the puzzle. You may use the backspace key if you make a mistake.",
  },
  {
    img: InstructionScreen.crossword.gifs.two,
    title: "",
    description:
      "When in control of the board, you may click on the statements in the left panel in order to highlight or strikethrough the text.",
  },
];

function Crossword() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const socket = useContext(SocketContext);

  const { inControlTwo, isHost } = useSelector((state) => state.common);

  const {
    rows,
    columns,
    gridData,
    hints,
    activeHintPanel,
    firstHintUsed,
    secondHintUsed,
    puzzleSolved,
    instructionsSeen,
  } = useSelector((state) => state.crossword);

  const [showHintOnePopup, setShowHintOnePopup] = React.useState(false);
  const [showHintTwoPopup, setShowHintTwoPopup] = React.useState(false);
  const [showResetPopup, setShowResetPopup] = React.useState(false);

  // DISABLED ARROW KEY NAVIGATION
  // const [inFocusCell, setInFocusCell] = React.useState("");

  const [highlightOnSolve, setHighlightOnSolve] = React.useState(false);

  const refInput0X9 = useRef(null);
  const refInput1X8 = useRef(null);
  const refInput1X9 = useRef(null);
  const refInput1X10 = useRef(null);
  const refInput1X14 = useRef(null);
  const refInput2X4 = useRef(null);
  const refInput2X9 = useRef(null);
  const refInput2X14 = useRef(null);
  const refInput3X4 = useRef(null);
  const refInput3X9 = useRef(null);
  const refInput3X14 = useRef(null);
  const refInput4X4 = useRef(null);
  const refInput4X5 = useRef(null);
  const refInput4X6 = useRef(null);
  const refInput4X7 = useRef(null);
  const refInput4X8 = useRef(null);
  const refInput4X9 = useRef(null);
  const refInput4X10 = useRef(null);
  const refInput4X14 = useRef(null);
  const refInput5X0 = useRef(null);
  const refInput5X1 = useRef(null);
  const refInput5X2 = useRef(null);
  const refInput5X3 = useRef(null);
  const refInput5X4 = useRef(null);
  const refInput5X9 = useRef(null);
  const refInput5X12 = useRef(null);
  const refInput5X14 = useRef(null);
  const refInput6X4 = useRef(null);
  const refInput6X6 = useRef(null);
  const refInput6X7 = useRef(null);
  const refInput6X8 = useRef(null);
  const refInput6X9 = useRef(null);
  const refInput6X10 = useRef(null);
  const refInput6X11 = useRef(null);
  const refInput6X12 = useRef(null);
  const refInput6X13 = useRef(null);
  const refInput6X14 = useRef(null);
  const refInput7X4 = useRef(null);
  const refInput7X10 = useRef(null);
  const refInput7X12 = useRef(null);
  const refInput7X14 = useRef(null);
  const refInput7X18 = useRef(null);
  const refInput8X5 = useRef(null);
  const refInput8X10 = useRef(null);
  const refInput8X12 = useRef(null);
  const refInput8X14 = useRef(null);
  const refInput8X16 = useRef(null);
  const refInput8X17 = useRef(null);
  const refInput8X18 = useRef(null);
  const refInput8X19 = useRef(null);
  const refInput8X20 = useRef(null);
  const refInput8X21 = useRef(null);
  const refInput9X5 = useRef(null);
  const refInput9X10 = useRef(null);
  const refInput9X12 = useRef(null);
  const refInput9X14 = useRef(null);
  const refInput9X18 = useRef(null);
  const refInput10X1 = useRef(null);
  const refInput10X5 = useRef(null);
  const refInput10X7 = useRef(null);
  const refInput10X10 = useRef(null);
  const refInput10X12 = useRef(null);
  const refInput10X18 = useRef(null);
  const refInput11X0 = useRef(null);
  const refInput11X1 = useRef(null);
  const refInput11X2 = useRef(null);
  const refInput11X3 = useRef(null);
  const refInput11X4 = useRef(null);
  const refInput11X5 = useRef(null);
  const refInput11X7 = useRef(null);
  const refInput11X9 = useRef(null);
  const refInput11X12 = useRef(null);
  const refInput11X18 = useRef(null);
  const refInput12X1 = useRef(null);
  const refInput12X5 = useRef(null);
  const refInput12X7 = useRef(null);
  const refInput12X9 = useRef(null);
  const refInput12X11 = useRef(null);
  const refInput12X12 = useRef(null);
  const refInput12X13 = useRef(null);
  const refInput12X14 = useRef(null);
  const refInput12X15 = useRef(null);
  const refInput12X16 = useRef(null);
  const refInput12X17 = useRef(null);
  const refInput12X18 = useRef(null);
  const refInput13X1 = useRef(null);
  const refInput13X5 = useRef(null);
  const refInput13X7 = useRef(null);
  const refInput13X9 = useRef(null);
  const refInput13X12 = useRef(null);
  const refInput14X1 = useRef(null);
  const refInput14X5 = useRef(null);
  const refInput14X6 = useRef(null);
  const refInput14X7 = useRef(null);
  const refInput14X8 = useRef(null);
  const refInput14X9 = useRef(null);
  const refInput14X10 = useRef(null);
  const refInput14X11 = useRef(null);
  const refInput14X12 = useRef(null);
  const refInput14X13 = useRef(null);
  const refInput14X14 = useRef(null);
  const refInput15X5 = useRef(null);
  const refInput15X7 = useRef(null);
  const refInput15X9 = useRef(null);
  const refInput15X13 = useRef(null);
  const refInput16X5 = useRef(null);
  const refInput16X7 = useRef(null);
  const refInput16X9 = useRef(null);
  const refInput16X11 = useRef(null);
  const refInput16X12 = useRef(null);
  const refInput16X13 = useRef(null);
  const refInput16X14 = useRef(null);
  const refInput17X7 = useRef(null);
  const refInput17X9 = useRef(null);
  const refInput17X13 = useRef(null);
  const refInput18X7 = useRef(null);
  const refInput18X9 = useRef(null);
  const refInput18X13 = useRef(null);
  const refInput19X9 = useRef(null);
  const refInput19X13 = useRef(null);

  const refInputMap = useMemo(() => {
    return {
      "0X9": refInput0X9,
      "1X8": refInput1X8,
      "1X9": refInput1X9,
      "1X10": refInput1X10,
      "1X14": refInput1X14,
      "2X4": refInput2X4,
      "2X9": refInput2X9,
      "2X14": refInput2X14,
      "3X4": refInput3X4,
      "3X9": refInput3X9,
      "3X14": refInput3X14,
      "4X4": refInput4X4,
      "4X5": refInput4X5,
      "4X6": refInput4X6,
      "4X7": refInput4X7,
      "4X8": refInput4X8,
      "4X9": refInput4X9,
      "4X10": refInput4X10,
      "4X14": refInput4X14,
      "5X0": refInput5X0,
      "5X1": refInput5X1,
      "5X2": refInput5X2,
      "5X3": refInput5X3,
      "5X4": refInput5X4,
      "5X9": refInput5X9,
      "5X12": refInput5X12,
      "5X14": refInput5X14,
      "6X4": refInput6X4,
      "6X6": refInput6X6,
      "6X7": refInput6X7,
      "6X8": refInput6X8,
      "6X9": refInput6X9,
      "6X10": refInput6X10,
      "6X11": refInput6X11,
      "6X12": refInput6X12,
      "6X13": refInput6X13,
      "6X14": refInput6X14,
      "7X4": refInput7X4,
      "7X10": refInput7X10,
      "7X12": refInput7X12,
      "7X14": refInput7X14,
      "7X18": refInput7X18,
      "8X5": refInput8X5,
      "8X10": refInput8X10,
      "8X12": refInput8X12,
      "8X14": refInput8X14,
      "8X16": refInput8X16,
      "8X17": refInput8X17,
      "8X18": refInput8X18,
      "8X19": refInput8X19,
      "8X20": refInput8X20,
      "8X21": refInput8X21,
      "9X5": refInput9X5,
      "9X10": refInput9X10,
      "9X12": refInput9X12,
      "9X14": refInput9X14,
      "9X18": refInput9X18,
      "10X1": refInput10X1,
      "10X5": refInput10X5,
      "10X7": refInput10X7,
      "10X10": refInput10X10,
      "10X12": refInput10X12,
      "10X18": refInput10X18,
      "11X0": refInput11X0,
      "11X1": refInput11X1,
      "11X2": refInput11X2,
      "11X3": refInput11X3,
      "11X4": refInput11X4,
      "11X5": refInput11X5,
      "11X7": refInput11X7,
      "11X9": refInput11X9,
      "11X12": refInput11X12,
      "11X18": refInput11X18,
      "12X1": refInput12X1,
      "12X5": refInput12X5,
      "12X7": refInput12X7,
      "12X9": refInput12X9,
      "12X11": refInput12X11,
      "12X12": refInput12X12,
      "12X13": refInput12X13,
      "12X14": refInput12X14,
      "12X15": refInput12X15,
      "12X16": refInput12X16,
      "12X17": refInput12X17,
      "12X18": refInput12X18,
      "13X1": refInput13X1,
      "13X5": refInput13X5,
      "13X7": refInput13X7,
      "13X9": refInput13X9,
      "13X12": refInput13X12,
      "14X1": refInput14X1,
      "14X5": refInput14X5,
      "14X6": refInput14X6,
      "14X7": refInput14X7,
      "14X8": refInput14X8,
      "14X9": refInput14X9,
      "14X10": refInput14X10,
      "14X11": refInput14X11,
      "14X12": refInput14X12,
      "14X13": refInput14X13,
      "14X14": refInput14X14,
      "15X5": refInput15X5,
      "15X7": refInput15X7,
      "15X9": refInput15X9,
      "15X13": refInput15X13,
      "16X5": refInput16X5,
      "16X7": refInput16X7,
      "16X9": refInput16X9,
      "16X11": refInput16X11,
      "16X12": refInput16X12,
      "16X13": refInput16X13,
      "16X14": refInput16X14,
      "17X7": refInput17X7,
      "17X9": refInput17X9,
      "17X13": refInput17X13,
      "18X7": refInput18X7,
      "18X9": refInput18X9,
      "18X13": refInput18X13,
      "19X9": refInput19X9,
      "19X13": refInput19X13,
    };
  }, []);

  // DISABLED KEY NAVIGATION
  // use arrow keys to navigate through the crossword
  // useEffect(() => {
  //   if (!inFocusCell) {
  //     return;
  //   }

  //   const handleKeyDown = (e) => {
  //     if (e.key === "ArrowRight") {
  //       // get the row and column index of the inFocusCell
  //       const [rowIndex, columnIndex] = inFocusCell.split("X");
  //       // check if the columnIndex is less than the number of columns
  //       if (parseInt(columnIndex) + 1 < columns) {
  //         // move to the next cell
  //         if (
  //           refInputMap[`${rowIndex}X${parseInt(columnIndex) + 1}`] &&
  //           refInputMap[`${rowIndex}X${parseInt(columnIndex) + 1}`].current
  //         ) {
  //           refInputMap[
  //             `${rowIndex}X${parseInt(columnIndex) + 1}`
  //           ].current.focus();
  //         }
  //       }
  //     } else if (e.key === "ArrowDown") {
  //       // check if there is an element below
  //       const [rowIndex, columnIndex] = inFocusCell.split("X");
  //       // check if the rowIndex is less than the number of rows
  //       if (parseInt(rowIndex) + 1 < rows) {
  //         // move to the next cell
  //         if (
  //           refInputMap[`${parseInt(rowIndex) + 1}X${columnIndex}`] &&
  //           refInputMap[`${parseInt(rowIndex) + 1}X${columnIndex}`].current
  //         ) {
  //           refInputMap[
  //             `${parseInt(rowIndex) + 1}X${columnIndex}`
  //           ].current.focus();
  //         }
  //       }
  //     } else if (e.key === "ArrowLeft") {
  //       // check if there is an element to the left
  //       const [rowIndex, columnIndex] = inFocusCell.split("X");
  //       // check if the columnIndex is greater than 0
  //       if (parseInt(columnIndex) - 1 >= 0) {
  //         // move to the next cell
  //         if (
  //           refInputMap[`${rowIndex}X${parseInt(columnIndex) - 1}`] &&
  //           refInputMap[`${rowIndex}X${parseInt(columnIndex) - 1}`].current
  //         ) {
  //           refInputMap[
  //             `${rowIndex}X${parseInt(columnIndex) - 1}`
  //           ].current.focus();
  //         }
  //       }
  //     } else if (e.key === "ArrowUp") {
  //       // check if there is an element above
  //       const [rowIndex, columnIndex] = inFocusCell.split("X");
  //       // check if the rowIndex is greater than 0
  //       if (parseInt(rowIndex) - 1 >= 0) {
  //         // move to the next cell
  //         if (
  //           refInputMap[`${parseInt(rowIndex) - 1}X${columnIndex}`] &&
  //           refInputMap[`${parseInt(rowIndex) - 1}X${columnIndex}`].current
  //         ) {
  //           refInputMap[
  //             `${parseInt(rowIndex) - 1}X${columnIndex}`
  //           ].current.focus();
  //         }
  //       }
  //     }

  //     // if key is backspace clear the cell
  //     if (e.key === "Backspace") {
  //       const [rowIndex, columnIndex] = inFocusCell.split("X");

  //       dispatch(
  //         updateCrosswordGrid({
  //           rowIndex,
  //           columnIndex,
  //           value: "",
  //         }),
  //       );

  //       socket.emit(SOCKET_EVENTS.CROSSWORD_GRID_UPDATE, {
  //         rowIndex,
  //         columnIndex,
  //         value: "",
  //       });

  //       return;
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [dispatch, rows, columns, inFocusCell, refInputMap, socket]);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.CROSSWORD_GRID_UPDATE, (data) => {
      dispatch(
        updateCrosswordGrid({
          rowIndex: data.rowIndex,
          columnIndex: data.columnIndex,
          value: data.value,
        }),
      );
    });

    socket.on(SOCKET_EVENTS.CROSSWORD_HINT_ITEM_STATE_UPDATE, (data) => {
      dispatch(updateHintStateToNext(data));
    });

    socket.on(SOCKET_EVENTS.STAGE_TWO_HINT_TWO, (data) => {
      dispatch(solvePuzzle());
    });

    socket.on(SOCKET_EVENTS.RESET_CROSSWORD_GRID, (data) => {
      dispatch(updateCrosswordGridComplete(data.gridData));
      dispatch(updateHintStateToNextComplete(data.hints));
    });

    return () => {
      socket.off(SOCKET_EVENTS.CROSSWORD_GRID_UPDATE);
      socket.off(SOCKET_EVENTS.CROSSWORD_HINT_ITEM_STATE_UPDATE);
      socket.off(SOCKET_EVENTS.STAGE_TWO_HINT_TWO);
      socket.off(SOCKET_EVENTS.RESET_CROSSWORD_GRID);
    };
  }, [dispatch, socket]);

  // check if solved every time the gridData changes
  useEffect(() => {
    let solved = true;
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        if (
          gridData[`${i}X${j}`] &&
          gridData[`${i}X${j}`].value !== gridData[`${i}X${j}`].correctValue
          // || gridData[`${i}X${j}`].value === gridData[`${i}X${j}`].secondCorrectValue
        ) {
          solved = false;
          break;
        }
        if (!solved) {
          break;
        }
      }
    }

    if (solved) {
      dispatch(setPuzzleSolved());
      setHighlightOnSolve(true);
    }
  }, [dispatch, gridData, rows, columns]);

  // this useEffect syncs the game with server on first load
  useEffect(() => {
    // parse connection recovery data from session storage
    const socket_room_data = JSON.parse(
      sessionStorage.getItem("socket_room_data"),
    );

    const roomCode = socket_room_data?.roomCode;

    socket.emit(SOCKET_EVENTS.SYNC_CROSSWORD_GRID, { roomCode }, (data) => {
      if (data.error) {
        console.error(data.error);
        // TODO change this to a toast message
        alert("ERROR IN CONNECTING TO SERVER, PLEASE RE LOGIN");
        return;
      }

      dispatch(updateCrosswordGridComplete(data.data.gridData));

      dispatch(updateHintStateToNextComplete(data.data.hints));
    });
  }, [dispatch, socket]);

  const handleCrosswordGridUpdate = (e, rowIndex, columnIndex) => {
    const key = e.key;

    // Clear the cell if the input key is a space or backspace, or if the input is not a valid letter
    if (key === " " || key === null || !key.match(/^[a-zA-Z]$/)) {
      dispatch(
        updateCrosswordGrid({
          rowIndex,
          columnIndex,
          value: "",
        }),
      );

      socket.emit(SOCKET_EVENTS.CROSSWORD_GRID_UPDATE, {
        rowIndex,
        columnIndex,
        value: "",
      });

      // Stop further processing
      return;
    }

    // If the input is a valid letter, convert to uppercase and update the grid
    if (key.match(/[a-z]/i)) {
      const upperCaseLetter = key.toUpperCase();

      dispatch(
        updateCrosswordGrid({
          rowIndex,
          columnIndex,
          value: upperCaseLetter,
        }),
      );

      socket.emit(SOCKET_EVENTS.CROSSWORD_GRID_UPDATE, {
        rowIndex,
        columnIndex,
        value: upperCaseLetter,
      });
    }
  };
  // DISABLED DUE TO INCONSISTENCY OF BORDERS
  // check if there is an element to the right
  const checkRight = useCallback(
    (rowIndex, columnIndex) => {
      if (parseInt(columnIndex) + 1 < columns) {
        return gridData[`${rowIndex}X${parseInt(columnIndex) + 1}`];
      }
      return null;
    },
    [columns, gridData],
  );

  // check if there is an element below
  const checkDown = useCallback(
    (rowIndex, columnIndex) => {
      if (parseInt(rowIndex) + 1 < rows) {
        return gridData[`${parseInt(rowIndex) + 1}X${columnIndex}`];
      }
      return null;
    },
    [gridData, rows],
  );

  // check if there is an element to the left
  const checkLeft = useCallback(
    (rowIndex, columnIndex) => {
      if (parseInt(columnIndex) - 1 >= 0) {
        return gridData[`${rowIndex}X${parseInt(columnIndex) - 1}`];
      }
      return null;
    },
    [gridData],
  );

  // check if there is an element above
  const checkUp = useCallback(
    (rowIndex, columnIndex) => {
      if (parseInt(rowIndex) - 1 >= 0) {
        return gridData[`${parseInt(rowIndex) - 1}X${columnIndex}`];
      }
      return null;
    },
    [gridData],
  );

  const getGridBorderStyles = useCallback(
    (rowIndex, columnIndex) => {
      const right = checkRight(rowIndex, columnIndex);
      const down = checkDown(rowIndex, columnIndex);
      const left = checkLeft(rowIndex, columnIndex);
      const up = checkUp(rowIndex, columnIndex);

      const borderStyles = {};

      // check if there is an element to the right
      // if yes, make the right border 1px solid black
      if (right) {
        borderStyles.borderRight = "0.5px solid #e6c99e";
      }

      // check if there is an element below
      // if yes, make the bottom border 1px solid black
      if (down) {
        borderStyles.borderBottom = "0.5px solid #e6c99e";
      }

      // check if there is an element to the left
      // if yes, make the left border 1px solid black
      if (left) {
        borderStyles.borderLeft = "0.5px solid #e6c99e";
      }

      // check if there is an element above
      // if yes, make the top border 1px solid black
      if (up) {
        borderStyles.borderTop = "0.5px solid #e6c99e";
      }

      return borderStyles;
    },
    [checkDown, checkLeft, checkRight, checkUp],
  );

  return (
    <div className="crossword">
      {/* POPUPS */}
      {showResetPopup && (
        <PopUp
          message={MESSAGES.RESET_POPUP.MESSAGE}
          subMessage={MESSAGES.RESET_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.RESET_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            socket.emit(SOCKET_EVENTS.RESET_CROSSWORD_GRID, {}, (data) => {
              if (data.error) {
                console.error(data.error);
                // TODO change this to a toast message
                alert("ERROR IN CONNECTING TO SERVER, PLEASE RE LOGIN");
                return;
              }

              dispatch(updateCrosswordGridComplete(data.data.gridData));
              dispatch(updateHintStateToNextComplete(data.data.hints));
            });
            setShowResetPopup(false);
          }}
          onCancel={() => {
            setShowResetPopup(false);
          }}
        />
      )}

      {showHintOnePopup && (
        <PopUp
          message={MESSAGES.STAGE_ONE_HINT_ONE_POPUP.MESSAGE}
          subMessage={MESSAGES.STAGE_ONE_HINT_ONE_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.STAGE_ONE_HINT_ONE_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            dispatch(setFirstHintUsed());
            setShowHintOnePopup(false);
          }}
          onCancel={() => {
            setShowHintOnePopup(false);
          }}
        />
      )}

      {showHintTwoPopup && (
        <PopUp
          message={MESSAGES.STAGE_ONE_HINT_TWO_POPUP.MESSAGE}
          subMessage={MESSAGES.STAGE_ONE_HINT_TWO_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.STAGE_ONE_HINT_TWO_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            // emit socket event to change positions
            socket.emit(SOCKET_EVENTS.STAGE_TWO_HINT_TWO, {}, (data) => {});

            dispatch(solvePuzzle());
            setShowHintTwoPopup(false);
          }}
          onCancel={() => {
            setShowHintTwoPopup(false);
          }}
        />
      )}

      {/* FULL SCREEN POPUP */}
      {!instructionsSeen && (
        <div className="instructions_crossword">
          <div
            className="instructionScreen_skip_button common_btn"
            onClick={() => {
              dispatch(setInstrcutionsSeen());
            }}
          >
            Skip Tutorial
            <img src={CommonImages.icons.skip_icon} alt="" />
          </div>
          <InstructionPop
            dataArray={instructionDataArray}
            onComplete={() => {
              dispatch(setInstrcutionsSeen());
            }}
          />
        </div>
      )}

      <div className="crossword_hintbtns">
        {isHost && !firstHintUsed && (
          <div
            className="hint_one_crossword common_btn"
            onClick={() => {
              setShowHintOnePopup(true);
            }}
          >
            Hint 1
          </div>
        )}

        {isHost && firstHintUsed && !secondHintUsed && (
          <div
            className="hint_two_crossword common_btn"
            onClick={() => {
              setShowHintTwoPopup(true);
            }}
          >
            Hint 2
          </div>
        )}

        <div
          className="reset_crossword common_btn"
          onClick={() => {
            setShowResetPopup(true);
          }}
          style={
            puzzleSolved
              ? { pointerEvents: "none", display: "none" }
              : inControlTwo
                ? {}
                : {
                    pointerEvents: "none",
                    opacity: 0.65,
                  }
          }
        >
          Reset
        </div>
        <div
          className="crossword_back common_btn"
          onClick={() => {
            navigate(ROUTES.STAGE_TWO);
          }}
        >
          Exit
        </div>
      </div>
      <div className="crossword_container">
        <div className="crossword_left">
          <HintBoard
            data={hints}
            hintBoardTwo={true}
            activeHintPanel={activeHintPanel}
            hintPanels={[
              CROSSWORD_HINT_PANELS.ACROSS,
              CROSSWORD_HINT_PANELS.DOWN,
            ]}
            updateHintState={(index) => {
              dispatch(
                updateHintStateToNext({
                  index,
                  activeHintPanel,
                }),
              );

              socket.emit(SOCKET_EVENTS.CROSSWORD_HINT_ITEM_STATE_UPDATE, {
                index,
                activeHintPanel,
              });
            }}
            toggleHintPanels={[
              () => {
                dispatch(toggleHintPanel(CROSSWORD_HINT_PANELS.ACROSS));
              },
              () => {
                dispatch(toggleHintPanel(CROSSWORD_HINT_PANELS.DOWN));
              },
            ]}
          />
        </div>
        <div className="crossword_right">
          <div
            className="crossword_grid_container"
            style={
              !inControlTwo || isHost || puzzleSolved
                ? {
                    pointerEvents: "none",
                  }
                : {}
            }
          >
            {Array(rows)
              .fill(0)
              .map((row, rowIndex) =>
                Array(columns)
                  .fill(0)
                  .map((column, columnIndex) => (
                    <div
                      className="input_container"
                      style={
                        gridData[`${rowIndex}X${columnIndex}`]
                          ? getGridBorderStyles(rowIndex, columnIndex)
                          : {
                              pointerEvents: "none",
                              opacity: 0.1,
                            }
                      }
                      key={`crossword_grid_${rowIndex}X${columnIndex}`}
                    >
                      <span>
                        {gridData[`${rowIndex}X${columnIndex}`].number
                          ? gridData[`${rowIndex}X${columnIndex}`].number
                          : ""}
                      </span>
                      <input
                        type="text"
                        className={`crossword_input_${rowIndex}X${columnIndex}`}
                        value={
                          gridData[`${rowIndex}X${columnIndex}`] &&
                          gridData[`${rowIndex}X${columnIndex}`].value
                            ? gridData[`${rowIndex}X${columnIndex}`].value
                            : ""
                        }
                        onKeyDown={(e) => {
                          handleCrosswordGridUpdate(e, rowIndex, columnIndex);
                        }}
                        // onchange give unreliability in the crossword grid update so we are using keydown
                        // onChange={(e) => {
                        //   handleCrosswordGridUpdate(e, rowIndex, columnIndex);
                        // }}
                        // DISABLED ARROW KEY NAVIGATION
                        // onFocus={() => {
                        //   setInFocusCell(`${rowIndex}X${columnIndex}`);
                        // }}
                        ref={refInputMap[`${rowIndex}X${columnIndex}`]}
                        style={
                          highlightOnSolve &&
                          gridData[`${rowIndex}X${columnIndex}`]
                            .highlightOnSolve
                            ? {
                                backgroundColor: "#db10107d",
                              }
                            : {}
                        }
                      />
                    </div>
                  )),
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Crossword;
