import { createSlice } from "@reduxjs/toolkit";

const stageTwo = createSlice({
  name: "stageTwo",
  initialState: {
    tableView: false,
    combinationLockOneSolved: false,
    combinationLockTwoSolved: false,

    showCombinationLockOne: false,
    showCombinationLockTwo: false,

    stageTwoSolved: false,
  },
  reducers: {
    setTableView: (state, action) => {
      state.tableView = action.payload;
    },
    setCombinationLockOneSolved: (state, action) => {
      const bothLocksSolved = action.payload && state.combinationLockTwoSolved;
      if (bothLocksSolved) {
        state.stageTwoSolved = bothLocksSolved;
        state.tableView = false;
      }
      state.combinationLockOneSolved = action.payload;
    },
    setCombinationLockTwoSolved: (state, action) => {
      const bothLocksSolved = action.payload && state.combinationLockOneSolved;
      if (bothLocksSolved) {
        state.stageTwoSolved = bothLocksSolved;
        state.tableView = false;
      }
      state.combinationLockTwoSolved = action.payload;
    },

    setShowCombinationLockOne: (state, action) => {
      state.showCombinationLockOne = action.payload;
    },
    setShowCombinationLockTwo: (state, action) => {
      state.showCombinationLockTwo = action.payload;
    },
  },
});

export const {
  setTableView,
  setCombinationLockOneSolved,
  setCombinationLockTwoSolved,

  setShowCombinationLockOne,
  setShowCombinationLockTwo,
} = stageTwo.actions;

export default stageTwo.reducer;
