import React from "react";

import "./PopUp.scss";

function PopUp({
  message = "Are you sure?",
  subMessage = "",
  img = "",
  okBtnText = "OK",
  cancelBtnText = "Cancel",
  noCancel = false,
  onOk = () => {},
  onCancel = () => {},
}) {
  return (
    <div
      className="popup_container"
      onClick={(e) => {
        if (e.target.classList.contains("popup_container")) {
          onCancel();
        }
      }}
    >
      <div className="popup_content">
        <div className="popup_message">{message}</div>
        {img && <img className="popup_img" src={img} alt="popup" />}
        {subMessage && <div className="popup_sub_message">{subMessage}</div>}
        <div
          className="popup_btns"
          style={{
            justifyContent: noCancel ? "center" : "space-between",
          }}
        >
          {!noCancel && (
            <div className="common_btn" onClick={onCancel}>
              {cancelBtnText}
            </div>
          )}
          <div className="common_btn" onClick={onOk}>
            {okBtnText}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopUp;
