import { createSlice } from "@reduxjs/toolkit";

const mazePuzzle = createSlice({
  name: "mazePuzzle",
  initialState: {
    showInstructions: true,
    mazeComplete: {
      one: false,
      two: false,
      three: false,
    },
    userPosition: {
      one: [0, 0],
      two: [0, 0],
      three: [0, 0],
    },
    positionsVisited: {
      one: {},
      two: {},
      three: {},
    },
    status: {
      one: "playing",
      two: "playing",
      three: "playing",
    },
  },
  reducers: {
    hideInstructions: (state) => {
      state.showInstructions = false;
    },
    setMazeCompelete: (state, action) => {
      const { mazeId } = action.payload;
      state.mazeComplete[mazeId] = true;
    },
    setUserPosition: (state, action) => {
      const { mazeId, position } = action.payload;
      state.userPosition[mazeId] = position;
    },
    setPositionsVisited: (state, action) => {
      const { mazeId, positions } = action.payload;
      state.positionsVisited[mazeId] = positions;
    },
    setStatus: (state, action) => {
      const { mazeId, status } = action.payload;
      state.status[mazeId] = status;
    },
  },
});

export const {
  hideInstructions,
  setMazeCompelete,
  setUserPosition,
  setStatus,
  setPositionsVisited,
} = mazePuzzle.actions;

export default mazePuzzle.reducer;
