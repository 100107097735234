export const MazeImages = {
  jewel_one:
    "https://storage.googleapis.com/stq_assets/images/stage_four/jewel_one.png",
  jewel_two:
    "https://storage.googleapis.com/stq_assets/images/stage_four/jewel_two.png",
  jewel_three:
    "https://storage.googleapis.com/stq_assets/images/stage_four/jewel_three.png",
  solved_jewel_one:
    "https://storage.googleapis.com/stq_assets/images/stage_four/solved_jewel_one.png",
  solved_jewel_two:
    "https://storage.googleapis.com/stq_assets/images/stage_four/solved_jewel_two.png",
  solved_jewel_three:
    "https://storage.googleapis.com/stq_assets/images/stage_four/solved_jewel_three.png",
  unsec_jewel_one:
    "https://storage.googleapis.com/stq_assets/images/stage_four/unsec_jewel_one.png",
  unsec_jewel_two:
    "https://storage.googleapis.com/stq_assets/images/stage_four/unsec_jewel_two.png",
  unsec_jewel_three:
    "https://storage.googleapis.com/stq_assets/images/stage_four/unsec_jewel_three.png",
  mission_pass_one:
    "https://storage.googleapis.com/stq_assets/images/stage_four/Mission%20Passed_Crown%20%26%20Mace%201.png",
  mission_pass_two:
    "https://storage.googleapis.com/stq_assets/images/stage_four/Mission%20Passed_%20Ampulla%20%26%20Sword%201.png",
  mission_pass_three:
    "https://storage.googleapis.com/stq_assets/images/stage_four/Mission%20Passed_Scepter%20%26%20Orb%201.png",

  bg: {
    one: "https://storage.googleapis.com/stq_assets/images/stage_four/maze_one_bg.png",
    // one: "https://storage.googleapis.com/stq_assets/images/stage_four/maze_one_bg%20(Compressed).png",
    two: "https://storage.googleapis.com/stq_assets/images/stage_four/maze_two_bg.png",
    three:
      "https://storage.googleapis.com/stq_assets/images/stage_four/maze_three_bg.png",
    jewel_bg:
      "https://storage.googleapis.com/stq_assets/images/stage_four/jewel_bg%20(Compressed).png",
    key_ins_bg:
      "https://storage.googleapis.com/stq_assets/images/stage_four/key_ins_bg.png",
  },
  instruction: {
    keys: "https://storage.googleapis.com/stq_assets/images/stage_four/keys.png",
  },
  start:
    "https://storage.googleapis.com/stq_assets/images/stage_four/start.png",
};
