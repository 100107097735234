import { SOCKET_EVENTS } from "Constants/Common";
import { SocketContext } from "context/socket";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function Location() {
  const { isHost } = useSelector((state) => state.common);

  const socket = useContext(SocketContext);

  const location = useLocation();

  useEffect(() => {
    if (!isHost) return;
    // if new path is same as current path, do not emit
    socket.emit(SOCKET_EVENTS.PATH_UPDATE, {
      path: location.pathname,
    });
  }, [isHost, socket, location]);

  return null;
}

export default Location;
