import { createSlice } from "@reduxjs/toolkit";
import { CHESS_HINT_PANELS, HINT_ITEM_STATES_ORDER } from "Constants/StageTwo";
import { chess_data, hints, pieceData } from "StaticData/ChessPuzzle";

const chess = createSlice({
  name: "chess",
  initialState: {
    rows: 8,
    columns: 8,
    gridData: chess_data,
    pieceData,
    hints,
    instructionsSeen: false,
    activeHintPanel: CHESS_HINT_PANELS.WHITE,
    firstHintUsed: false,
    secondHintUsed: false,
    puzzleSolved: false,
    solvedPuzzleAnimationDisabled: false,
  },
  reducers: {
    toggleHintPanel: (state, action) => {
      state.activeHintPanel = action.payload;
    },
    setFirstHintUsed: (state, action) => {
      const gridData = action.payload;
      state.gridData = gridData;
      state.firstHintUsed = true;
    },
    setPuzzleSolved: (state) => {
      state.secondHintUsed = true;
      state.puzzleSolved = true;
    },
    updateHintStateToNext: (state, action) => {
      const { index, activeHintPanel } = action.payload;
      const hintData = state.hints[activeHintPanel];
      const hint = hintData[index];
      hint.state =
        HINT_ITEM_STATES_ORDER[
          (HINT_ITEM_STATES_ORDER.indexOf(hint.state) + 1) %
            HINT_ITEM_STATES_ORDER.length
        ];

      // update state
      state.hints[activeHintPanel][index] = hint;
    },
    updateHintStateComplete: (state, action) => {
      state.hints = action.payload;
    },
    updatePiecePosition: (state, action) => {
      const { id, position, lastPosition, initialPosition } = action.payload;
      state.pieceData[id].position = position;

      if (
        position.row !== null &&
        position.row !== undefined &&
        position.column !== null &&
        position.column !== undefined
      ) {
        state.gridData[`${position.row}X${position.column}`].piece = id;
      }

      if (
        state.pieceData[id].lastPosition.row !== null &&
        state.pieceData[id].lastPosition.column !== null &&
        state.pieceData[id].lastPosition.row !== undefined &&
        state.pieceData[id].lastPosition.column !== undefined &&
        (state.pieceData[id].lastPosition.row !== position.row ||
          state.pieceData[id].lastPosition.column !== position.column)
      ) {
        state.gridData[
          `${state.pieceData[id].lastPosition.row}X${state.pieceData[id].lastPosition.column}`
        ].piece = null;
        state.gridData[
          `${state.pieceData[id].lastPosition.row}X${state.pieceData[id].lastPosition.column}`
        ].highlightRed = false;
      }

      if (lastPosition) state.pieceData[id].lastPosition = lastPosition;
      if (initialPosition)
        state.pieceData[id].initialPosition = initialPosition;
    },
    removePieceFromGrid: (state, action) => {
      const { id, lastRow, lastColumn } = action.payload;

      state.gridData[`${lastRow}X${lastColumn}`].piece = null;
      state.gridData[`${lastRow}X${lastColumn}`].highlightRed = false;

      // update pieceData
      state.pieceData[id].position = {
        ...state.pieceData[id].position,
        row: null,
        column: null,
      };

      // update pieceData
      state.pieceData[id].lastPosition = {
        ...state.pieceData[id].lastPosition,
        row: null,
        column: null,
      };
    },
    resetChessBoard: (state) => {
      state.gridData = chess_data;
      state.pieceData = pieceData;
      state.hints = hints;
    },
    disableSolvedPuzzleAnimation: (state) => {
      state.solvedPuzzleAnimationDisabled = true;
    },
    setGridData: (state, action) => {
      state.gridData = action.payload;
    },
    setInstrcutionsSeen: (state) => {
      state.instructionsSeen = true;
    },
  },
});

export const {
  toggleHintPanel,
  setFirstHintUsed,
  setPuzzleSolved,
  updateHintStateToNext,
  updateHintStateComplete,
  updatePiecePosition,
  removePieceFromGrid,
  disableSolvedPuzzleAnimation,
  setGridData,
  setInstrcutionsSeen,
} = chess.actions;

export default chess.reducer;
