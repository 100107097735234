import { CHESS_PIECE_COLORS, CHESS_PIECE_TYPES } from "Constants/StageTwo";

export const chess_data = {
  "0X0": {
    piece: null,
    position: "8a",
    cellNum: 8,
  },
  "0X1": {
    piece: null,
    position: "8b",
  },
  "0X2": {
    piece: null,
    position: "8c",
    correctPieceType: CHESS_PIECE_TYPES.ROOK,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "0X3": {
    piece: null,
    position: "8d",
    correctPieceType: CHESS_PIECE_TYPES.QUEEN,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "0X4": {
    piece: null,
    position: "8e",
  },
  "0X5": {
    piece: null,
    position: "8f",
  },
  "0X6": {
    piece: null,
    position: "8g",
    correctPieceType: CHESS_PIECE_TYPES.KING,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "0X7": {
    piece: null,
    position: "8h",
    correctPieceType: CHESS_PIECE_TYPES.BISHOP,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "1X0": {
    piece: "seventeen",
    position: "7a",
    cellNum: 7,
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "1X1": {
    piece: null,
    position: "7b",
    correctPieceType: CHESS_PIECE_TYPES.BISHOP,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "1X2": {
    piece: null,
    position: "7c",
  },
  "1X3": {
    piece: null,
    position: "7d",
    correctPieceType: CHESS_PIECE_TYPES.ROOK,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "1X4": {
    piece: null,
    position: "7e",
  },
  "1X5": {
    piece: null,
    position: "7f",
  },
  "1X6": {
    piece: null,
    position: "7g",
  },
  "1X7": {
    piece: null,
    position: "7h",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "2X0": {
    piece: null,
    position: "6a",
    cellNum: 6,
  },
  "2X1": {
    piece: null,
    position: "6b",
  },
  "2X2": {
    piece: null,
    position: "6c",
    correctPieceType: CHESS_PIECE_TYPES.KNIGHT,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "2X3": {
    piece: null,
    position: "6d",
  },
  "2X4": {
    piece: null,
    position: "6e",
    correctPieceType: CHESS_PIECE_TYPES.ROOK,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "2X5": {
    piece: null,
    position: "6f",
  },
  "2X6": {
    piece: null,
    position: "6g",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "2X7": {
    piece: null,
    position: "6h",
    correctPieceType: CHESS_PIECE_TYPES.BISHOP,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "3X0": {
    piece: null,
    position: "5a",
    cellNum: 5,
  },
  "3X1": {
    piece: null,
    position: "5b",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "3X2": {
    piece: null,
    position: "5c",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "3X3": {
    piece: null,
    position: "5d",
  },
  "3X4": {
    piece: null,
    position: "5e",
  },
  "3X5": {
    piece: null,
    position: "5f",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "3X6": {
    piece: null,
    position: "5g",
    correctPieceType: CHESS_PIECE_TYPES.KNIGHT,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "3X7": {
    piece: null,
    position: "5h",
  },
  "4X0": {
    piece: null,
    position: "4a",
    cellNum: 4,
  },
  "4X1": {
    piece: null,
    position: "4b",
  },
  "4X2": {
    piece: null,
    position: "4c",
  },
  "4X3": {
    piece: null,
    position: "4d",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.BLACK,
  },
  "4X4": {
    piece: null,
    position: "4e",
  },
  "4X5": {
    piece: null,
    position: "4f",
    correctPieceType: CHESS_PIECE_TYPES.QUEEN,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "4X6": {
    piece: null,
    position: "4g",
  },
  "4X7": {
    piece: null,
    position: "4h",
  },
  "5X0": {
    piece: null,
    position: "3a",
    cellNum: 3,
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "5X1": {
    piece: null,
    position: "3b",
  },
  "5X2": {
    piece: null,
    position: "3c",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "5X3": {
    piece: null,
    position: "3d",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "5X4": {
    piece: null,
    position: "3e",
  },
  "5X5": {
    piece: null,
    position: "3f",
  },
  "5X6": {
    piece: null,
    position: "3g",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "5X7": {
    piece: null,
    position: "3h",
  },
  "6X0": {
    piece: null,
    position: "2a",
    cellNum: 2,
  },
  "6X1": {
    piece: "two",
    position: "2b",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "6X2": {
    piece: null,
    position: "2c",
  },
  "6X3": {
    piece: null,
    position: "2d",
  },
  "6X4": {
    piece: null,
    position: "2e",
  },
  "6X5": {
    piece: null,
    position: "2f",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "6X6": {
    piece: null,
    position: "2g",
    correctPieceType: CHESS_PIECE_TYPES.BISHOP,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "6X7": {
    piece: null,
    position: "2h",
    correctPieceType: CHESS_PIECE_TYPES.PAWN,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "7X0": {
    piece: null,
    position: "1a",
    cellNum: 1,
    cellId: "a",
  },
  "7X1": {
    piece: null,
    position: "1b",
    cellId: "b",
  },
  "7X2": {
    piece: null,
    position: "1c",
    cellId: "c",
  },
  "7X3": {
    piece: null,
    position: "1d",
    cellId: "d",
  },
  "7X4": {
    piece: null,
    position: "1e",
    cellId: "e",
    correctPieceType: CHESS_PIECE_TYPES.ROOK,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "7X5": {
    piece: null,
    position: "1f",
    cellId: "f",
  },
  "7X6": {
    piece: null,
    position: "1g",
    cellId: "g",
    correctPieceType: CHESS_PIECE_TYPES.KING,
    correctPieceColor: CHESS_PIECE_COLORS.WHITE,
  },
  "7X7": {
    piece: null,
    position: "1h",
    cellId: "h",
  },
};

// 8 X 8 grid
export const pieceData = {
  one: {
    id: "one",
    index: "Pawn 1",
    boardPosition: "7a",
    initialPosition: {
      x: -150,
      y: 700,
    },
    position: {
      x: -150,
      y: 700,
    },
    lastPosition: {
      x: -150,
      y: 700,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 5,
    correctColumn: 0,
  },
  two: {
    id: "two",
    index: "Pawn 2",
    boardPosition: "7b",
    initialPosition: {
      x: -150,
      y: 600,
      // fixed position
      row: 6,
      column: 1,
    },
    position: {
      x: -150,
      y: 600,
    },
    lastPosition: {
      x: -150,
      y: 600,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 6,
    correctColumn: 1,
    isFixed: true,
  },
  three: {
    id: "three",
    index: "Pawn 3",
    boardPosition: "7c",
    initialPosition: {
      x: -150,
      y: 500,
    },
    position: {
      x: -150,
      y: 500,
    },
    lastPosition: {
      x: -150,
      y: 500,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 5,
    correctColumn: 2,
  },
  four: {
    id: "four",
    index: "Pawn 4",
    boardPosition: "7d",
    initialPosition: {
      x: -150,
      y: 400,
    },
    position: {
      x: -150,
      y: 400,
    },
    lastPosition: {
      x: -150,
      y: 400,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 5,
    correctColumn: 3,
  },
  five: {
    id: "five",
    index: "Pawn 5",
    boardPosition: "7e",
    initialPosition: {
      x: -150,
      y: 300,
    },
    position: {
      x: -150,
      y: 300,
    },
    lastPosition: {
      x: -150,
      y: 300,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 6,
    correctColumn: 5,
  },
  six: {
    id: "six",
    index: "Pawn 6",
    boardPosition: "7f",
    initialPosition: {
      x: -150,
      y: 200,
    },
    position: {
      x: -150,
      y: 200,
    },
    lastPosition: {
      x: -150,
      y: 200,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 5,
    correctColumn: 6,
  },
  seven: {
    id: "seven",
    index: "Pawn 7",
    boardPosition: "7g",
    initialPosition: {
      x: -150,
      y: 100,
    },
    position: {
      x: -150,
      y: 100,
    },
    lastPosition: {
      x: -150,
      y: 100,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 6,
    correctColumn: 7,
  },
  eight: {
    id: "eight",
    index: "Pawn 8",
    boardPosition: "7h",
    initialPosition: {
      x: -150,
      y: 0,
    },
    position: {
      x: -150,
      y: 0,
    },
    lastPosition: {
      x: -150,
      y: 0,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.WHITE,
  },
  nine: {
    id: "nine",
    index: "Castle 1",
    boardPosition: "8a",
    initialPosition: {
      x: -80,
      y: 0,
    },
    position: {
      x: -80,
      y: 0,
    },
    lastPosition: {
      x: -80,
      y: 0,
    },
    type: CHESS_PIECE_TYPES.ROOK,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 7,
    correctColumn: 4,
  },
  ten: {
    id: "ten",
    index: "Castle 2",
    boardPosition: "8h",
    initialPosition: {
      x: -80,
      y: 100,
    },
    position: {
      x: -80,
      y: 100,
    },
    lastPosition: {
      x: -80,
      y: 100,
    },
    type: CHESS_PIECE_TYPES.ROOK,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 2,
    correctColumn: 4,
  },
  eleven: {
    id: "eleven",
    index: "Knight 1",
    boardPosition: "8b",
    initialPosition: {
      x: -80,
      y: 200,
    },
    position: {
      x: -80,
      y: 200,
    },
    lastPosition: {
      x: -80,
      y: 200,
    },
    type: CHESS_PIECE_TYPES.KNIGHT,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 3,
    correctColumn: 6,
  },
  twelve: {
    id: "twelve",
    index: "Knight 2",
    boardPosition: "8g",
    initialPosition: {
      x: -80,
      y: 300,
    },
    position: {
      x: -80,
      y: 300,
    },
    lastPosition: {
      x: -80,
      y: 300,
    },
    type: CHESS_PIECE_TYPES.KNIGHT,
    color: CHESS_PIECE_COLORS.WHITE,
  },
  thirteen: {
    id: "thirteen",
    index: "Bishop 1",
    boardPosition: "8c",
    initialPosition: {
      x: -80,
      y: 400,
    },
    position: {
      x: -80,
      y: 400,
    },
    lastPosition: {
      x: -80,
      y: 400,
    },
    type: CHESS_PIECE_TYPES.BISHOP,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 6,
    correctColumn: 6,
  },
  fourteen: {
    id: "fourteen",
    index: "Bishop 2",
    boardPosition: "8f",
    initialPosition: {
      x: -80,
      y: 500,
    },
    position: {
      x: -80,
      y: 500,
    },
    lastPosition: {
      x: -80,
      y: 500,
    },
    type: CHESS_PIECE_TYPES.BISHOP,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 2,
    correctColumn: 7,
  },
  fifteen: {
    id: "fifteen",
    index: "Queen",
    boardPosition: "8d",
    initialPosition: {
      x: -80,
      y: 600,
    },
    position: {
      x: -80,
      y: 600,
    },
    lastPosition: {
      x: -80,
      y: 600,
    },
    type: CHESS_PIECE_TYPES.QUEEN,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 4,
    correctColumn: 5,
  },
  sixteen: {
    id: "sixteen",
    index: "King",
    boardPosition: "8e",
    initialPosition: {
      x: -80,
      y: 700,
    },
    position: {
      x: -80,
      y: 700,
    },
    lastPosition: {
      x: -80,
      y: 700,
    },
    type: CHESS_PIECE_TYPES.KING,
    color: CHESS_PIECE_COLORS.WHITE,
    correctRow: 7,
    correctColumn: 6,
  },
  seventeen: {
    id: "seventeen",
    index: "Pawn 1",
    boardPosition: "2a",
    initialPosition: {
      x: 700,
      y: 700,
      // fixed position
      row: 1,
      column: 0,
    },
    position: {
      x: 700,
      y: 700,
    },
    lastPosition: {
      x: 700,
      y: 700,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 1,
    correctColumn: 0,
    isFixed: true,
  },
  eighteen: {
    id: "eighteen",
    index: "Pawn 2",
    boardPosition: "2b",
    initialPosition: {
      x: 700,
      y: 600,
    },
    position: {
      x: 700,
      y: 600,
    },
    lastPosition: {
      x: 700,
      y: 600,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 3,
    correctColumn: 1,
  },
  nineteen: {
    id: "nineteen",
    index: "Pawn 3",
    boardPosition: "2c",
    initialPosition: {
      x: 700,
      y: 500,
    },
    position: {
      x: 700,
      y: 500,
    },
    lastPosition: {
      x: 700,
      y: 500,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 3,
    correctColumn: 2,
  },
  twenty: {
    id: "twenty",
    index: "Pawn 4",
    boardPosition: "2d",
    initialPosition: {
      x: 700,
      y: 400,
    },
    position: {
      x: 700,
      y: 400,
    },
    lastPosition: {
      x: 700,
      y: 400,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 4,
    correctColumn: 3,
  },
  twentyOne: {
    id: "twentyOne",
    index: "Pawn 5",
    boardPosition: "2e",
    initialPosition: {
      x: 700,
      y: 300,
    },
    position: {
      x: 700,
      y: 300,
    },
    lastPosition: {
      x: 700,
      y: 300,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 3,
    correctColumn: 5,
  },
  twentyTwo: {
    id: "twentyTwo",
    index: "Pawn 6",
    boardPosition: "2f",
    initialPosition: {
      x: 700,
      y: 200,
    },
    position: {
      x: 700,
      y: 200,
    },
    lastPosition: {
      x: 700,
      y: 200,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 2,
    correctColumn: 6,
  },
  twentyThree: {
    id: "twentyThree",
    index: "Pawn 7",
    boardPosition: "2g",
    initialPosition: {
      x: 700,
      y: 100,
    },
    position: {
      x: 700,
      y: 100,
    },
    lastPosition: {
      x: 700,
      y: 100,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 1,
    correctColumn: 7,
  },
  twentyFour: {
    id: "twentyFour",
    index: "Pawn 8",
    boardPosition: "2h",
    initialPosition: {
      x: 700,
      y: 0,
    },
    position: {
      x: 700,
      y: 0,
    },
    lastPosition: {
      x: 700,
      y: 0,
    },
    type: CHESS_PIECE_TYPES.PAWN,
    color: CHESS_PIECE_COLORS.BLACK,
  },
  twentyFive: {
    id: "twentyFive",
    index: "Castle 1",
    boardPosition: "1a",
    initialPosition: {
      x: 780,
      y: 0,
    },
    position: {
      x: 780,
      y: 0,
    },
    lastPosition: {
      x: 780,
      y: 0,
    },
    type: CHESS_PIECE_TYPES.ROOK,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 0,
    correctColumn: 2,
  },
  twentySix: {
    id: "twentySix",
    index: "Castle 2",
    boardPosition: "1h",
    initialPosition: {
      x: 780,
      y: 100,
    },
    position: {
      x: 780,
      y: 100,
    },
    lastPosition: {
      x: 780,
      y: 100,
    },
    type: CHESS_PIECE_TYPES.ROOK,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 1,
    correctColumn: 3,
  },
  twentySeven: {
    id: "twentySeven",
    index: "Knight 1",
    boardPosition: "1b",
    initialPosition: {
      x: 780,
      y: 200,
    },
    position: {
      x: 780,
      y: 200,
    },
    lastPosition: {
      x: 780,
      y: 200,
    },
    type: CHESS_PIECE_TYPES.KNIGHT,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 2,
    correctColumn: 2,
  },
  twentyEight: {
    id: "twentyEight",
    index: "Knight 2",
    boardPosition: "1g",
    initialPosition: {
      x: 780,
      y: 300,
    },
    position: {
      x: 780,
      y: 300,
    },
    lastPosition: {
      x: 780,
      y: 300,
    },
    type: CHESS_PIECE_TYPES.KNIGHT,
    color: CHESS_PIECE_COLORS.BLACK,
  },
  twentyNine: {
    id: "twentyNine",
    index: "Bishop 1",
    boardPosition: "1c",
    initialPosition: {
      x: 780,
      y: 400,
    },
    position: {
      x: 780,
      y: 400,
    },
    lastPosition: {
      x: 780,
      y: 400,
    },
    type: CHESS_PIECE_TYPES.BISHOP,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 0,
    correctColumn: 7,
  },
  thirty: {
    id: "thirty",
    index: "Bishop 2",
    boardPosition: "1f",
    initialPosition: {
      x: 780,
      y: 500,
    },
    position: {
      x: 780,
      y: 500,
    },
    lastPosition: {
      x: 780,
      y: 500,
    },
    type: CHESS_PIECE_TYPES.BISHOP,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 1,
    correctColumn: 1,
  },
  thirtyOne: {
    id: "thirtyOne",
    index: "Queen",
    boardPosition: "1d",
    initialPosition: {
      x: 780,
      y: 600,
    },
    position: {
      x: 780,
      y: 600,
    },
    lastPosition: {
      x: 780,
      y: 600,
    },
    type: CHESS_PIECE_TYPES.QUEEN,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 0,
    correctColumn: 3,
  },
  thirtyTwo: {
    id: "thirtyTwo",
    index: "King",
    boardPosition: "1e",
    initialPosition: {
      x: 780,
      y: 700,
    },
    position: {
      x: 780,
      y: 700,
    },
    lastPosition: {
      x: 780,
      y: 700,
    },
    type: CHESS_PIECE_TYPES.KING,
    color: CHESS_PIECE_COLORS.BLACK,
    correctRow: 0,
    correctColumn: 6,
  },
};

export const hints = {
  black: [],
  white: [],
};
