import { createSlice } from "@reduxjs/toolkit";
import {
  HINT_ITEM_STATES_ORDER,
  CROSSWORD_HINT_PANELS,
} from "Constants/StageTwo";
import { crossword_data, hints } from "StaticData/CrosswordPuzzle";

const crossword = createSlice({
  name: "crossword",
  initialState: {
    rows: 20,
    columns: 22,
    gridData: crossword_data,
    hints,
    instructionsSeen: false,
    activeHintPanel: CROSSWORD_HINT_PANELS.ACROSS,
    firstHintUsed: false,
    secondHintUsed: false,
    puzzleSolved: false,
  },
  reducers: {
    updateCrosswordGrid: (state, action) => {
      const { rowIndex, columnIndex, value } = action.payload;
      state.gridData[`${rowIndex}X${columnIndex}`].value = value;
    },
    updateCrosswordGridComplete: (state, action) => {
      state.gridData = action.payload;
    },
    toggleHintPanel: (state, action) => {
      state.activeHintPanel = action.payload;
    },
    updateHintStateToNext: (state, action) => {
      const { index, activeHintPanel } = action.payload;
      const hintData = state.hints[activeHintPanel];
      const hint = hintData[index];
      hint.state =
        HINT_ITEM_STATES_ORDER[
          (HINT_ITEM_STATES_ORDER.indexOf(hint.state) + 1) %
            HINT_ITEM_STATES_ORDER.length
        ];

      // update state
      state.hints[activeHintPanel][index] = hint;
    },
    updateHintStateToNextComplete: (state, action) => {
      state.hints = action.payload;
    },
    solvePuzzle: (state) => {
      const solvedGridData = {};
      // loop over all the crossword data and set the correctValue as value
      for (const key in crossword_data) {
        if (crossword_data[key] && crossword_data[key].correctValue) {
          solvedGridData[key] = {
            ...crossword_data[key],
            value: crossword_data[key].correctValue,
          };
        } else {
          solvedGridData[key] = crossword_data[key];
        }
      }

      state.gridData = solvedGridData;
      state.secondHintUsed = true;
      state.puzzleSolved = true;
    },
    setFirstHintUsed: (state) => {
      state.firstHintUsed = true;
    },
    setPuzzleSolved: (state) => {
      state.puzzleSolved = true;
    },
    setInstrcutionsSeen: (state) => {
      state.instructionsSeen = true;
    },
  },
});

export const {
  updateCrosswordGrid,
  toggleHintPanel,
  updateHintStateToNext,
  solvePuzzle,
  setFirstHintUsed,
  setPuzzleSolved,
  setInstrcutionsSeen,
  // used for syncing the complete grid data
  updateCrosswordGridComplete,
  updateHintStateToNextComplete,
} = crossword.actions;

export default crossword.reducer;
