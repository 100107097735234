import React, { useRef, useState } from "react";
import throttle from "lodash.throttle";
import { CustomPicker } from "react-color";
import { Hue, Saturation } from "react-color/lib/components/common";
import { useDispatch, useSelector } from "react-redux";
import reactCSS from "reactcss";

import { CommonImages } from "Assets/Common";
import { setActiveColor } from "Store/DrawTheKing";

import "./ColorPicker.scss";

const CustomColorPicker = () => {
  const { activeColor } = useSelector((state) => state.drawTheKing);

  const dispatch = useDispatch();

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const [color, setColor] = React.useState(activeColor || "#000000");

  const handleClick = () => {
    setColor(activeColor);
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (value) => {
    dispatch(setActiveColor(value));
  };

  const makeDispatchThrottled = useRef(throttle(handleColorChange, 0));

  const handleColorChangeThrottled = (value) => {
    makeDispatchThrottled.current(value);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        className="select_color_box"
        style={{
          backgroundColor: activeColor,
          cursor: "pointer",
        }}
        onClick={handleClick}
      ></div>
      {displayColorPicker && (
        <div
          style={{
            position: "absolute",
            zIndex: "2",
            top: "-300%",
            right: "-350%",
          }}
        >
          <div className="close_picker_button" onClick={handleClose}>
            <img src={CommonImages.icons.cross} alt={""} />
          </div>

          <STQColorPicker
            color={color}
            onChangeComplete={(e) => {
              handleColorChangeThrottled(e.hex);
            }}
            onChange={(e) => {
              setColor(e.hex);
            }}
          />
        </div>
      )}
    </div>
  );
};

export const MyPicker = ({ hex, hsl, hsv, onChange, color }) => {
  const styles = {
    picker: {
      background: "#fff",
      borderRadius: "2px",
      boxShadow: "0 0 2px rgba(0,0,0,.3), 0 4px 8px rgba(0,0,0,.3)",
      boxSizing: "initial",
      fontFamily: "Menlo",
    },
    saturation: {
      width: "30rem",
      height: "20rem",
      paddingBottom: "55%",
      position: "relative",
      borderRadius: "2px 2px 0 0",
      overflow: "hidden",
    },
    Saturation: {
      radius: "2px 2px 0 0",
    },
    hue: {
      height: 10,
      position: "relative",
      marginBottom: 5,
      marginTop: 10,
    },
    swatch: {
      width: 54,
      height: 38,
      background: hex,
    },
  };
  return (
    <div className="myPicker">
      <div style={styles.saturation}>
        <Saturation
          style={styles.Saturation}
          hsl={hsl}
          hsv={hsv}
          color={color}
          pointer={ChromePointerCircle}
          onChange={onChange}
        />
      </div>

      <div style={styles.hue}>
        <Hue hsl={hsl} pointer={ChromePointer} onChange={onChange} />
      </div>
    </div>
  );
};

const STQColorPicker = CustomPicker(MyPicker);

export const ChromePointer = () => {
  const styles = reactCSS({
    default: {
      picker: {
        width: "12px",
        height: "12px",
        borderRadius: "6px",
        transform: "translate(-6px, -1px)",
        backgroundColor: "rgb(248, 248, 248)",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.37)",
      },
    },
  });

  return <div style={styles.picker} />;
};

export const ChromePointerCircle = () => {
  const styles = reactCSS({
    default: {
      picker: {
        width: "12px",
        height: "12px",
        borderRadius: "6px",
        boxShadow: "inset 0 0 0 1px #fff",
        transform: "translate(-6px, -6px)",
      },
    },
  });

  return <div style={styles.picker} />;
};

export default CustomColorPicker;
