import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Timeline.scss";

import Draggable from "react-draggable";
import {
  MUSIC_BOARD_DIVISION,
  MUSIC_BOARD_DURATION_IN_SECONDS,
  MUSIC_BOARD_WIDTH_IN_PIXELS,
} from "Constants/StageOne";
import {
  updateCursorPositionInSeconds,
  updateIsTimelinePlaying,
} from "Store/StageOne";

function Timeline({ stopAllMusic, scale }) {
  const {
    timeline: { timelinePlaying },
  } = useSelector((state) => state.stageOne);

  const dispatch = useDispatch();

  const cursourRef = useRef(null);

  const [cursorPositionX, setCursorPositionX] = useState(0);

  const handleStart = (_, data) => {
    // stop the timeline if it's playing
    dispatch(updateIsTimelinePlaying(false));

    // stop all audio
    stopAllMusic();
  };

  const handleDrag = (_, data) => {};

  const handleStop = (_, data) => {
    // prevent timeline from going out of bounds
    if (data.x < 0) {
      setCursorPositionX(0);
      return;
    }

    // prevent timeline from going out of bounds
    if (data.x > MUSIC_BOARD_DURATION_IN_SECONDS * MUSIC_BOARD_DIVISION) {
      setCursorPositionX(
        MUSIC_BOARD_DURATION_IN_SECONDS * MUSIC_BOARD_DIVISION,
      );
      return;
    }

    setCursorPositionX(
      Math.round(data.x / MUSIC_BOARD_DIVISION) * MUSIC_BOARD_DIVISION,
    );

    dispatch(
      updateCursorPositionInSeconds(Math.round(data.x / MUSIC_BOARD_DIVISION)),
    );
  };

  const handleTimelineMoveOnClick = (e) => {
    // ignore the event is its not timeline_cursor_handle to prevent cursor going out of the timeline
    if (e.target.className === "timeline_cursor_handle") {
      return;
    }

    const rect = e.target.getBoundingClientRect();

    let x = Math.round(e.clientX) - Math.round(rect.left);

    if (x < 0 || rect.width < 10) return; // handle incorrect click;

    x = x / scale;

    setCursorPositionX(
      Math.round(x / MUSIC_BOARD_DIVISION) * MUSIC_BOARD_DIVISION,
    );

    dispatch(
      updateCursorPositionInSeconds(Math.round(x / MUSIC_BOARD_DIVISION)),
    );

    // stop all audio
    stopAllMusic();

    // stop the timeline if it's playing
    dispatch(updateIsTimelinePlaying(false));
  };

  // if timeline is playing move the cursor to the right every second
  useEffect(() => {
    let interval;

    const handleTimeliveMovement = () => {
      setCursorPositionX((currentPositionX) => {
        if (
          currentPositionX >=
          MUSIC_BOARD_DURATION_IN_SECONDS * MUSIC_BOARD_DIVISION
        ) {
          dispatch(updateIsTimelinePlaying(false));
          clearInterval(interval);
          return currentPositionX;
        }

        const newX = currentPositionX + MUSIC_BOARD_DIVISION;

        // Update the cursor position in state and dispatch an action
        dispatch(
          updateCursorPositionInSeconds(
            Math.round(newX / MUSIC_BOARD_DIVISION),
          ),
        );

        return Math.round(newX / MUSIC_BOARD_DIVISION) * MUSIC_BOARD_DIVISION;
      });
    };

    if (timelinePlaying) {
      interval = setInterval(handleTimeliveMovement, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [timelinePlaying, dispatch]);

  return (
    <div
      className="timeline"
      onClick={handleTimelineMoveOnClick}
      style={{
        width: `${MUSIC_BOARD_WIDTH_IN_PIXELS}px`,
      }}
    >
      <Draggable
        axis="x"
        handle=".timeline_cursor"
        position={{
          x: cursorPositionX,
          y: 0,
        }}
        scale={scale}
        onStart={handleStart}
        onDrag={handleDrag}
        onStop={handleStop}
        nodeRef={cursourRef}
      >
        <div
          className={`timeline_cursor ${timelinePlaying ? "timeline_cursor_animate" : ""}`}
          ref={cursourRef}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="timeline_cursor_handle"></div>
          <div className="timeline_cursor_line"></div>
        </div>
      </Draggable>
      <div
        className="timeline_lines"
        style={{
          width: `${MUSIC_BOARD_WIDTH_IN_PIXELS}px`,
        }}
      >
        {Array.from({ length: MUSIC_BOARD_DURATION_IN_SECONDS + 1 }).map(
          (_, index) => (
            <div
              key={index}
              className={`timeline_line ${index % 5 === 0 ? "timeline_line_big" : "timeline_line_small"}`}
            >
              {index % 5 === 0 && <span>{index}</span>}
            </div>
          ),
        )}
      </div>
    </div>
  );
}

export default Timeline;
