import { createSlice } from "@reduxjs/toolkit";
import { draggableObjects, correctPositionsX } from "StaticData/MusicPuzzle";

const stageOne = createSlice({
  name: "stageOne",
  initialState: {
    draggableObjects: draggableObjects,
    correctPositionsX,
    timeline: {
      cursorPositionInSeconds: 0,
      timelinePlaying: false,
    },
    instruments: {
      one: {
        silent: false,
      },
      two: {
        silent: false,
      },
      three: {
        silent: false,
      },
    },
    firstHintUsed: false,
    secondHintUsed: false,
    instructionsSeen: false,
    volume: 0.5,
  },
  reducers: {
    updateIsTimelinePlaying(state, action) {
      state.timeline.timelinePlaying = action.payload;
    },
    setVolume(state, action) {
      state.volume = action.payload;
    },
    setAudioPlaying(state, action) {
      state.draggableObjects[action.payload.id].audioPlaying =
        action.payload.playing;
    },
    stopAllAudioPlaying(state) {
      for (const position in state.draggableObjects) {
        state.draggableObjects[position].audioPlaying = false;
      }
    },
    updateCursorPositionInSeconds(state, action) {
      state.timeline.cursorPositionInSeconds = action.payload;
    },
    updateDraggableObjectPosition(state, action) {
      const { id, position, isFixed } = action.payload;
      state.draggableObjects[id].position = position;
      if (isFixed) {
        state.draggableObjects[id].isFixed = true;
      }
    },
    updateDraggableObjectLastStopPositionAndLastTargetRow(state, action) {
      const { id, position } = action.payload;
      state.draggableObjects[id].lastStopPosition = position;
    },
    updateDraggableObjectPositionInSeconds(state, action) {
      const { id, positionInSeconds, isFixed } = action.payload;
      state.draggableObjects[id].positionInSeconds = positionInSeconds;
      if (isFixed) {
        state.draggableObjects[id].isFixed = true;
      }
    },
    updateDraggableObjectTargetRow(state, action) {
      const { id, targetRow } = action.payload;
      state.draggableObjects[id].targetRow = targetRow;
    },
    updateSilentStateInstrument(state, action) {
      const { instrument, silent } = action.payload;
      state.instruments[instrument].silent = silent;
    },
    setHintOneUsed(state) {
      state.firstHintUsed = true;
    },
    setHintTwoUsed(state) {
      state.secondHintUsed = true;
    },
    setInstrcutionsSeen: (state) => {
      state.instructionsSeen = true;
    },
  },
});

export const {
  updateIsTimelinePlaying,
  setAudioPlaying,
  stopAllAudioPlaying,
  updateCursorPositionInSeconds,
  updateDraggableObjectPosition,
  updateDraggableObjectLastStopPositionAndLastTargetRow,
  updateDraggableObjectPositionInSeconds,
  updateDraggableObjectTargetRow,
  updateDraggableObjectLastTargetRow,
  updateSilentStateInstrument,
  setHintOneUsed,
  setHintTwoUsed,
  setVolume,
  setInstrcutionsSeen,
} = stageOne.actions;

export default stageOne.reducer;
