export const StageOneImages = {
  waveforms: {
    one: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Horn%20One.png",
    two: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Horn%20Two.png",
    three:
      "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Horn%20Three.png",
    four: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Clarinet%20One.png",
    five: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Clarinet%20Two.png",
    six: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Clarinet%20Three.png",
    seven:
      "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Clarinet%20Four.png",
    eight:
      "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Clarinet%20Five.png",
    nine: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Drum%20One.png",
    ten: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Drum%20Two.png",
    eleven:
      "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Drum%20Three.png",
    twelve:
      "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks%20V2/Drum%20Four.png",
  },
  draggables: {
    one: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Horn%20One.png",
    two: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Horn%20Two.png",
    three:
      "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Horn%20Three.png",
    four: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Clarinet%20One.png",
    five: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Clarinet%20Two.png",
    six: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Clarinet%20Three.png",
    seven:
      "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Clarinet%20Four.png",
    eight:
      "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Clarinet%20Five.png",
    nine: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Drum%20One.png",
    ten: "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Drum%20Two.png",
    eleven:
      "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Drum%20Three.png",
    twelve:
      "https://storage.googleapis.com/stq_assets/images/stage_one/Snippets%20Tracks/S_Drum%20Four.png",
  },
  instruments: {
    drum: "https://storage.googleapis.com/stq_assets/images/stage_one/drum.png",
    horn: "https://storage.googleapis.com/stq_assets/images/stage_one/horn.png",
    clarinet:
      "https://storage.googleapis.com/stq_assets/images/stage_one/cliarenet.png",
  },
  music_board_bg:
    "https://storage.googleapis.com/stq_assets/images/stage_one/music_board_bg%20(Compressed).png",
  music_board_ins_bg:
    "https://storage.googleapis.com/stq_assets/images/stage_one/music_board_ins_bg.png",
  shine:
    "https://storage.googleapis.com/stq_assets/images/stage_one/Shine%20(Compressed).gif",
};
