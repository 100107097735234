import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ROUTES, SOCKET_EVENTS } from "Constants/Common";
import HintBoard from "Components/HintBoard/HintBoard";
import { CHESS_BOARD_DIMENSIONS, CHESS_HINT_PANELS } from "Constants/StageTwo";
import {
  removePieceFromGrid,
  setFirstHintUsed,
  setPuzzleSolved,
  toggleHintPanel,
  updatePiecePosition,
  updateHintStateToNext,
  disableSolvedPuzzleAnimation,
  updateHintStateComplete,
  setGridData,
  setInstrcutionsSeen,
} from "Store/Chess";
import ChessPiece from "Components/ChessPiece/ChessPiece";
import { SocketContext } from "context/socket";

import "./Chess.scss";

import { MESSAGES } from "Constants/StageOne";
import PopUp from "Components/PopUp/PopUp";
import { CommonImages, InstructionScreen } from "Assets/Common";
import InstructionPop from "Components/InstructionPop/InstructionPop";

const instructionDataArray = [
  {
    img: "",
    title: "Instructions",
    description:
      "This chess board hath been left in shambles!  Perhaps the key to the mystery lies in the guard’s calligraphy...",
  },
  {
    img: InstructionScreen.chess_puzzle.gifs.one,
    title: "",
    description:
      "“Take control” of the board and drag the chess pieces into place. The pieces are numbered in order from left to right.",
  },
  {
    img: InstructionScreen.chess_puzzle.gifs.two,
    title: "",
    description:
      "When you are in control of the board, you may click on the statements in the left panel in order to highlight or strikethrough the text.",
  },
];

function ChessPuzzle() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const socket = useContext(SocketContext);

  const {
    rows,
    columns,
    pieceData,
    hints,
    activeHintPanel,
    gridData,
    firstHintUsed,
    secondHintUsed,
    puzzleSolved,
    solvedPuzzleAnimationDisabled,
    instructionsSeen,
  } = useSelector((state) => state.chess);

  const { inControl, isHost } = useSelector((state) => state.common);

  const [showResetPopup, setShowResetPopup] = React.useState(false);
  const [showHintOnePopup, setShowHintOnePopup] = React.useState(false);
  const [showHintTwoPopup, setShowHintTwoPopup] = React.useState(false);

  const [syncComplete, setSyncComplete] = React.useState(false);

  const draggableRefOne = useRef(null);
  const draggableRefTwo = useRef(null);
  const draggableRefThree = useRef(null);
  const draggableRefFour = useRef(null);
  const draggableRefFive = useRef(null);
  const draggableRefSix = useRef(null);
  const draggableRefSeven = useRef(null);
  const draggableRefEight = useRef(null);
  const draggableRefNine = useRef(null);
  const draggableRefTen = useRef(null);
  const draggableRefEleven = useRef(null);
  const draggableRefTwelve = useRef(null);
  const draggableRefThirteen = useRef(null);
  const draggableRefFourteen = useRef(null);
  const draggableRefFifteen = useRef(null);
  const draggableRefSixteen = useRef(null);
  const draggableRefSeventeen = useRef(null);
  const draggableRefEighteen = useRef(null);
  const draggableRefNineteen = useRef(null);
  const draggableRefTwenty = useRef(null);
  const draggableRefTwentyOne = useRef(null);
  const draggableRefTwentyTwo = useRef(null);
  const draggableRefTwentyThree = useRef(null);
  const draggableRefTwentyFour = useRef(null);
  const draggableRefTwentyFive = useRef(null);
  const draggableRefTwentySix = useRef(null);
  const draggableRefTwentySeven = useRef(null);
  const draggableRefTwentyEight = useRef(null);
  const draggableRefTwentyNine = useRef(null);
  const draggableRefThirty = useRef(null);
  const draggableRefThirtyOne = useRef(null);
  const draggableRefThirtyTwo = useRef(null);

  const draggableReferences = useMemo(() => {
    return {
      one: draggableRefOne,
      two: draggableRefTwo,
      three: draggableRefThree,
      four: draggableRefFour,
      five: draggableRefFive,
      six: draggableRefSix,
      seven: draggableRefSeven,
      eight: draggableRefEight,
      nine: draggableRefNine,
      ten: draggableRefTen,
      eleven: draggableRefEleven,
      twelve: draggableRefTwelve,
      thirteen: draggableRefThirteen,
      fourteen: draggableRefFourteen,
      fifteen: draggableRefFifteen,
      sixteen: draggableRefSixteen,
      seventeen: draggableRefSeventeen,
      eighteen: draggableRefEighteen,
      nineteen: draggableRefNineteen,
      twenty: draggableRefTwenty,
      twentyOne: draggableRefTwentyOne,
      twentyTwo: draggableRefTwentyTwo,
      twentyThree: draggableRefTwentyThree,
      twentyFour: draggableRefTwentyFour,
      twentyFive: draggableRefTwentyFive,
      twentySix: draggableRefTwentySix,
      twentySeven: draggableRefTwentySeven,
      twentyEight: draggableRefTwentyEight,
      twentyNine: draggableRefTwentyNine,
      thirty: draggableRefThirty,
      thirtyOne: draggableRefThirtyOne,
      thirtyTwo: draggableRefThirtyTwo,
    };
  }, []);

  const chessBoardRef = useRef(null);

  const handleStart = (_, data) => {};
  const handleDrag = (_, data) => {
    const activeElement = data.node.className.split(" ")[0].split("_")[1];
    dispatch(
      updatePiecePosition({
        id: activeElement,
        position: {
          x: data.x,
          y: data.y,
        },
      }),
    );
  };
  const handleStop = (_, data) => {
    const activeElement = data.node.className.split(" ")[0].split("_")[1];

    const chessboard = chessBoardRef.current.getBoundingClientRect();
    const piece =
      draggableReferences[activeElement].current.getBoundingClientRect();

    // console.log("chessboard", chessboard.top, chessboard.left);
    // console.log("piece", piece.top, piece.left);

    // piece postion relative to the chessboard
    const pieceCorrdinates = {
      x: piece.top - chessboard.top,
      y: piece.left - chessboard.left,
    };

    // if inside the bounds of the chessboard
    if (
      pieceCorrdinates.x + piece.width / 2 > 0 &&
      pieceCorrdinates.x < chessboard.height &&
      pieceCorrdinates.y + piece.height / 2 > 0 &&
      pieceCorrdinates.y < chessboard.width
    ) {
      const blockHeight = chessboard.height / CHESS_BOARD_DIMENSIONS.ROWS;
      const blockWidth = chessboard.width / CHESS_BOARD_DIMENSIONS.COLUMNS;

      let row = Math.floor(
        (pieceCorrdinates.x + piece.width / 2) / blockHeight,
      );
      let column = Math.floor(
        (pieceCorrdinates.y + piece.height / 2) / blockWidth,
      );

      row =
        row < 0
          ? 0
          : row > CHESS_BOARD_DIMENSIONS.ROWS - 1
            ? CHESS_BOARD_DIMENSIONS.ROWS - 1
            : row;

      column =
        column < 0
          ? 0
          : column > CHESS_BOARD_DIMENSIONS.COLUMNS - 1
            ? CHESS_BOARD_DIMENSIONS.COLUMNS - 1
            : column;

      // DEBUG AUTO SNAP
      // console.log("");

      // console.log("piece", piece.x);
      // // console.log("chessboard", chessboard.x);
      // console.log("data.x", data.x);
      // console.log("X: ", chessboard.x + column * blockWidth - 150);

      // console.log("=====");

      // console.log("piece", piece.y);
      // // console.log("chessboard", chessboard.y);
      // console.log("data.y", data.y);
      // console.log("Y: ", chessboard.y + row * blockHeight - 150);

      // console.log("");

      // check if the block is empty
      if (
        gridData[`${row}X${column}`].piece &&
        gridData[`${row}X${column}`].piece !== activeElement
      ) {
        // if not empty, then set the position to the last position
        // if last position was on the board then set the piece to the last position
        if (
          pieceData[activeElement].lastPosition.row !== null &&
          pieceData[activeElement].lastPosition.column !== null &&
          pieceData[activeElement].lastPosition.row !== undefined &&
          pieceData[activeElement].lastPosition.column !== undefined
        ) {
          dispatch(
            updatePiecePosition({
              id: activeElement,
              position: {
                ...pieceData[activeElement].lastPosition,
              },
            }),
          );
        } else {
          // if last position was out of the board then set the piece to the initial position
          dispatch(
            updatePiecePosition({
              id: activeElement,
              position: {
                ...pieceData[activeElement].initialPosition,
                row: null,
                column: null,
              },
            }),
          );
        }
        return;
      }

      socket.emit(
        SOCKET_EVENTS.CHESS_PIECE_MOVED,
        {
          pieceId: activeElement,
          row,
          column,
        },
        (data) => {
          if (data.error) {
            console.log("ERROR IN MOVING CHESS PIECE", data.error);
            // TODO change this to a toast message
            alert("ERROR IN MOVING CHESS PIECE");

            // sync pieces with the server
            handleChessPieceMovement(data.data);
            return;
          }

          // update the grid with peice Id
          // update the piece with grid position
          // auto snap to the block
          dispatch(
            updatePiecePosition({
              id: activeElement,
              position: {
                x: column * blockWidth + blockWidth / 2 - piece.width / 2,
                y: row * blockHeight + blockHeight / 2 - piece.height / 2,
                row,
                column,
              },
              lastPosition: {
                x: column * blockWidth + blockWidth / 2 - piece.width / 2,
                y: row * blockHeight + blockHeight / 2 - piece.height / 2,
                row,
                column,
              },
            }),
          );
        },
      );
    } else {
      // if peice was on the board and now out of the board then remove the piece from the grid
      if (
        pieceData[activeElement].lastPosition.row !== null &&
        pieceData[activeElement].lastPosition.column !== null &&
        pieceData[activeElement].lastPosition.row !== undefined &&
        pieceData[activeElement].lastPosition.column !== undefined
      ) {
        socket.emit(
          SOCKET_EVENTS.CHESS_PIECE_REMOVED,
          {
            pieceId: activeElement,
            row: null,
            column: null,
          },
          (data) => {
            dispatch(
              removePieceFromGrid({
                id: activeElement,
                lastRow: pieceData[activeElement].lastPosition.row,
                lastColumn: pieceData[activeElement].lastPosition.column,
              }),
            );
          },
        );
      }

      dispatch(
        updatePiecePosition({
          id: activeElement,
          position: {
            ...pieceData[activeElement].initialPosition,
            row: null,
            column: null,
          },
        }),
      );
    }
  };

  const handleChessPieceMovement = useCallback(
    (chessPuzzleState) => {
      const { pieceData, gridData } = chessPuzzleState;

      if (gridData) {
        dispatch(setGridData(gridData));
      } else {
        console.log("NO GRID DATA");
      }
      Object.keys(pieceData).forEach((pieceId, index) => {
        const piece =
          draggableReferences[pieceId].current.getBoundingClientRect();
        const chessboard = chessBoardRef.current.getBoundingClientRect();

        // if piece is already fixed on the board
        if (
          pieceData[pieceId].isFixed &&
          pieceData[pieceId].initialPosition.row !== null &&
          pieceData[pieceId].initialPosition.column !== null
        ) {
          // place the piece on the board
          dispatch(
            updatePiecePosition({
              id: pieceId,
              position: {
                x:
                  pieceData[pieceId].initialPosition.column *
                    (chessboard.width / CHESS_BOARD_DIMENSIONS.COLUMNS) +
                  chessboard.width / CHESS_BOARD_DIMENSIONS.COLUMNS / 2 -
                  piece.width / 2,
                y:
                  pieceData[pieceId].initialPosition.row *
                    (chessboard.height / CHESS_BOARD_DIMENSIONS.ROWS) +
                  chessboard.height / CHESS_BOARD_DIMENSIONS.ROWS / 2 -
                  piece.height / 2,
              },
              lastPosition: {
                x:
                  pieceData[pieceId].initialPosition.column *
                    (chessboard.width / CHESS_BOARD_DIMENSIONS.COLUMNS) +
                  chessboard.width / CHESS_BOARD_DIMENSIONS.COLUMNS / 2 -
                  piece.width / 2,
                y:
                  pieceData[pieceId].initialPosition.row *
                    (chessboard.height / CHESS_BOARD_DIMENSIONS.ROWS) +
                  chessboard.height / CHESS_BOARD_DIMENSIONS.ROWS / 2 -
                  piece.height / 2,
              },
            }),
          );

          return;
        }

        // sync other peices that are not fixed
        const row = pieceData[pieceId].position.row;
        const column = pieceData[pieceId].position.column;
        if (row != null && column != null) {
          const blockHeight = chessboard.height / CHESS_BOARD_DIMENSIONS.ROWS;
          const blockWidth = chessboard.width / CHESS_BOARD_DIMENSIONS.COLUMNS;

          // update the grid with peice Id
          dispatch(
            updatePiecePosition({
              id: pieceId,
              position: {
                x: column * blockWidth + blockWidth / 2 - piece.width / 2,
                y: row * blockHeight + blockHeight / 2 - piece.height / 2,
                row,
                column,
              },
              lastPosition: {
                x: column * blockWidth + blockWidth / 2 - piece.width / 2,
                y: row * blockHeight + blockHeight / 2 - piece.height / 2,
                row,
                column,
              },
            }),
          );

          return;
        }

        // set peices to their initial positions
        if (
          row === null ||
          column === null ||
          row === undefined ||
          column === undefined
        ) {
          let x = 0;
          const windowWidth = window.innerWidth;
          // 0 - 7
          if (index < 8) {
            x = -piece.width - Math.floor(windowWidth / 100);
          }
          // 8 - 15
          else if (index < 16) {
            // get the width of the window
            x =
              -piece.width -
              Math.floor(windowWidth / 100) -
              piece.width -
              Math.floor(windowWidth / 100);
          }
          // 16 - 23
          else if (index < 24) {
            // get the width of the window
            x = chessboard.width + Math.floor(windowWidth / 100);
          }
          // 24 - 31
          else {
            x =
              chessboard.width +
              Math.floor(windowWidth / 100) +
              piece.width +
              Math.floor(windowWidth / 100);
          }

          // set half the peice on the left side of the board
          dispatch(
            updatePiecePosition({
              id: pieceId,
              position: {
                x: x,
                y:
                  (piece.height + Math.floor(chessboard.width / 22)) *
                  (index % 8),
              },
              initialPosition: {
                x: x,
                y:
                  (piece.height + Math.floor(chessboard.width / 22)) *
                  (index % 8),
              },
              lastPosition: {
                x: x,
                y:
                  (piece.height + Math.floor(chessboard.width / 22)) *
                  (index % 8),
              },
            }),
          );
        }
      });
    },
    [dispatch, draggableReferences],
  );

  const handleFirstHintUsed = useCallback(
    (gridData) => {
      dispatch(setFirstHintUsed(gridData));
      setShowHintOnePopup(false);
    },
    [dispatch],
  );

  // debug use effect
  // useEffect(
  //   () => {
  //     // console.log("pieceData", pieceData);
  //     // console.log("gridData", gridData);
  //   },
  //   [
  //     // pieceData,
  //     // gridData
  //   ]
  // );

  // this useEffect checks if the puzzle is solved
  useEffect(() => {
    const checkAndHandlePuzzleSolved = () => {
      let correctPieceCount = 0;
      let wrongPieceCount = 0;
      for (const gridCell in gridData) {
        if (
          gridData[gridCell].piece &&
          gridData[gridCell].correctPieceColor &&
          gridData[gridCell].correctPieceType
        ) {
          if (
            pieceData[gridData[gridCell].piece]?.color ===
              gridData[gridCell].correctPieceColor &&
            pieceData[gridData[gridCell].piece]?.type ===
              gridData[gridCell].correctPieceType
          ) {
            correctPieceCount++;
          } else {
            wrongPieceCount++;
          }
        }

        if (
          gridData[gridCell].piece &&
          (!gridData[gridCell].correctPieceColor ||
            !gridData[gridCell].correctPieceType)
        ) {
          wrongPieceCount++;
        }
      }

      // 28 is number of peices that will be on the board
      if (correctPieceCount === 28 && wrongPieceCount === 0) {
        socket.emit(SOCKET_EVENTS.CHESS_PUZZLE_SOLVED, {}, (data) => {
          dispatch(setPuzzleSolved());
        });
      }
    };

    checkAndHandlePuzzleSolved();

    // eslint-disable-next-line
  }, [gridData, dispatch, socket]);

  // this useEffect create socket connection
  useEffect(() => {
    socket.on(SOCKET_EVENTS.CHESS_PIECE_MOVED, handleChessPieceMovement);

    socket.on(SOCKET_EVENTS.CHESS_PIECE_REMOVED, handleChessPieceMovement);

    socket.on(SOCKET_EVENTS.CHESS_HINT_ONE, handleFirstHintUsed);

    socket.on(SOCKET_EVENTS.CHESS_HINT_TWO, (data) => {
      dispatch(setPuzzleSolved());
      setShowHintTwoPopup(false);
    });

    socket.on(SOCKET_EVENTS.CHESS_PUZZLE_SOLVED, (data) => {
      dispatch(setPuzzleSolved());
    });

    socket.on(SOCKET_EVENTS.RESET_CHESS_BOARD, (data) => {
      handleChessPieceMovement(data);
      dispatch(updateHintStateComplete(data.hints));
    });

    socket.on(SOCKET_EVENTS.CHESS_HINT_ITEM_STATE_UPDATE, (data) => {
      dispatch(updateHintStateToNext(data));
    });

    return () => {
      socket.off(SOCKET_EVENTS.CHESS_PIECE_MOVED);
      socket.off(SOCKET_EVENTS.CHESS_PIECE_REMOVED);
      socket.off(SOCKET_EVENTS.CHESS_HINT_ONE);
      socket.off(SOCKET_EVENTS.CHESS_HINT_TWO);
      socket.off(SOCKET_EVENTS.CHESS_PUZZLE_SOLVED);
      socket.off(SOCKET_EVENTS.RESET_CHESS_BOARD);
      socket.off(SOCKET_EVENTS.CHESS_HINT_ITEM_STATE_UPDATE);
    };
  }, [
    socket,
    handleFirstHintUsed,
    draggableReferences,
    dispatch,
    pieceData,
    handleChessPieceMovement,
  ]);

  // this useEffect calculate initial peices positions
  useEffect(() => {
    // parse connection recovery data from session storage
    const socket_room_data = JSON.parse(
      sessionStorage.getItem("socket_room_data"),
    );

    const roomCode = socket_room_data?.roomCode;

    socket.emit(
      SOCKET_EVENTS.SYNC_CHESS_PIECE_POSITION,
      { roomCode },
      (data) => {
        if (data.error) {
          console.log("ERROR IN SYNCING CHESS POSITIONS", roomCode, data.error);
          // TODO change this to a toast message
          alert("ERROR IN CONNECTING TO SERVER, PLEASE RE LOGIN");
          return;
        }

        dispatch(updateHintStateComplete(data.data.hints));

        if (data?.data?.secondHintUsed) {
          dispatch(setPuzzleSolved());
        } else {
          if (data?.data?.firstHintUsed) {
            dispatch(setFirstHintUsed(data?.data?.gridData));
          }

          handleChessPieceMovement(data?.data);
        }

        setTimeout(() => {
          setSyncComplete(true);
        }, 1000);
      },
    );
    // eslint-disable-next-line
  }, []);

  // this useEffect auto solves the puzzle by ** taking the peices to the correct position **
  // useEffect(() => {
  //   if (secondHintUsed) {
  //     const chessboard = chessBoardRef.current.getBoundingClientRect();
  //     const blockHeight = chessboard.height / CHESS_BOARD_DIMENSIONS.ROWS;
  //     const blockWidth = chessboard.width / CHESS_BOARD_DIMENSIONS.COLUMNS;

  //     Object.keys(pieceData).forEach((pieceId) => {
  //       const piece =
  //         draggableReferences[pieceId].current.getBoundingClientRect();
  //       const row = pieceData[pieceId].correctRow;
  //       const column = pieceData[pieceId].correctColumn;

  //       if (
  //         row === null ||
  //         column === null ||
  //         row === undefined ||
  //         column === undefined
  //       ) {
  //         return;
  //       }
  //       console.log("row", row, "column", column);

  //       // update the grid with peice Id
  //       dispatch(
  //         updatePiecePosition({
  //           id: pieceId,
  //           position: {
  //             x: column * blockWidth + blockWidth / 2 - piece.width / 2,
  //             y: row * blockHeight + blockHeight / 2 - piece.height / 2,
  //             row,
  //             column,
  //           },
  //           lastPosition: {
  //             x: column * blockWidth + blockWidth / 2 - piece.width / 2,
  //             y: row * blockHeight + blockHeight / 2 - piece.height / 2,
  //             row,
  //             column,
  //           },
  //         }),
  //       );
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [secondHintUsed]);

  // this useEffect auto solves the puzzle and animate the peices
  useEffect(() => {
    if (puzzleSolved) {
      // disable animation after 3s if not disabled
      if (!solvedPuzzleAnimationDisabled) {
        setTimeout(() => {
          dispatch(disableSolvedPuzzleAnimation());
        }, 3000);
      }

      const chessboard = chessBoardRef.current.getBoundingClientRect();
      const blockHeight = chessboard.height / CHESS_BOARD_DIMENSIONS.ROWS;
      const blockWidth = chessboard.width / CHESS_BOARD_DIMENSIONS.COLUMNS;

      Object.keys(pieceData).forEach((pieceId) => {
        const piece =
          draggableReferences[pieceId].current.getBoundingClientRect();

        // central row and column
        const row = 4;
        const column = 5;

        // update the grid with peice Id
        dispatch(
          updatePiecePosition({
            id: pieceId,
            position: {
              x: column * blockWidth + blockWidth / 2 - piece.width / 2,
              y: row * blockHeight + blockHeight / 2 - piece.height / 2,
              row,
              column,
            },
            lastPosition: {
              x: column * blockWidth + blockWidth / 2 - piece.width / 2,
              y: row * blockHeight + blockHeight / 2 - piece.height / 2,
              row,
              column,
            },
          }),
        );

        if (solvedPuzzleAnimationDisabled) {
          if (
            pieceData[pieceId].type !== "queen" ||
            pieceData[pieceId].color !== "white"
          ) {
            if (draggableReferences[pieceId].current) {
              draggableReferences[pieceId].current.style.display = "none";
            }
          }
          return;
        }

        // hide all other peices except the white queen after 3s of the animation
        setTimeout(() => {
          if (
            pieceData[pieceId].type !== "queen" ||
            pieceData[pieceId].color !== "white"
          ) {
            if (draggableReferences[pieceId].current) {
              draggableReferences[pieceId].current.style.display = "none";
            }
          }
        }, 2900);
      });
    }
    // eslint-disable-next-line
  }, [puzzleSolved]);

  // this useEffect listens to resize events and recalculates the peices positions
  useEffect(() => {
    const handleResize = () => {
      handleChessPieceMovement({ pieceData });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleChessPieceMovement, pieceData]);

  return (
    <div className="chess">
      {/* POPUPS */}
      {showResetPopup && (
        <PopUp
          message={MESSAGES.RESET_POPUP.MESSAGE}
          subMessage={MESSAGES.RESET_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.RESET_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            socket.emit(SOCKET_EVENTS.RESET_CHESS_BOARD, {}, (data) => {
              handleChessPieceMovement(data.data);
              dispatch(updateHintStateComplete(data.data.hints));
            });
            setShowResetPopup(false);
          }}
          onCancel={() => {
            setShowResetPopup(false);
          }}
        />
      )}

      {showHintOnePopup && (
        <PopUp
          message={MESSAGES.STAGE_ONE_HINT_ONE_POPUP.MESSAGE}
          subMessage={MESSAGES.STAGE_ONE_HINT_ONE_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.STAGE_ONE_HINT_ONE_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            socket.emit(SOCKET_EVENTS.CHESS_HINT_ONE, {}, (data) => {
              handleFirstHintUsed(data.data);
            });
          }}
          onCancel={() => {
            setShowHintOnePopup(false);
          }}
        />
      )}

      {showHintTwoPopup && (
        <PopUp
          message={MESSAGES.STAGE_ONE_HINT_TWO_POPUP.MESSAGE}
          subMessage={MESSAGES.STAGE_ONE_HINT_TWO_POPUP.SUBMESSAGE}
          okBtnText={MESSAGES.STAGE_ONE_HINT_TWO_POPUP.OK_BUTTON_TEXT}
          onOk={() => {
            // emit socket event to change positions
            socket.emit(SOCKET_EVENTS.CHESS_HINT_TWO, {}, (data) => {
              dispatch(setPuzzleSolved());
              setShowHintTwoPopup(false);
            });
          }}
          onCancel={() => {
            setShowHintTwoPopup(false);
          }}
        />
      )}

      {/* FULL SCREEN POPUP */}
      {!instructionsSeen && (
        <div className="instructions_chess">
          <div
            className="instructionScreen_skip_button common_btn"
            onClick={() => {
              dispatch(setInstrcutionsSeen());
            }}
          >
            Skip Tutorial
            <img src={CommonImages.icons.skip_icon} alt="" />
          </div>
          <InstructionPop
            dataArray={instructionDataArray}
            onComplete={() => {
              dispatch(setInstrcutionsSeen());
            }}
          />
        </div>
      )}

      <div className="chess_hintbtns">
        {isHost && !firstHintUsed && (
          <div
            className="hint_one_chess common_btn"
            onClick={() => {
              setShowHintOnePopup(true);
            }}
          >
            Hint 1
          </div>
        )}

        {isHost && firstHintUsed && !secondHintUsed && (
          <div
            className="hint_two_chess common_btn"
            onClick={() => {
              setShowHintTwoPopup(true);
            }}
          >
            Hint 2
          </div>
        )}

        <div
          className="reset_chess common_btn"
          onClick={() => {
            setShowResetPopup(true);
          }}
          style={
            puzzleSolved
              ? { pointerEvents: "none", display: "none" }
              : inControl
                ? {}
                : {
                    pointerEvents: "none",
                    opacity: 0.65,
                  }
          }
        >
          Reset
        </div>
        <div
          className="chess_back"
          onClick={() => {
            navigate(ROUTES.STAGE_TWO);
          }}
        >
          <div className="common_btn">Exit</div>
        </div>
      </div>

      <div className="chess_container">
        <div className="chess_left">
          <HintBoard
            data={hints}
            activeHintPanel={activeHintPanel}
            hintPanels={[CHESS_HINT_PANELS.WHITE, CHESS_HINT_PANELS.BLACK]}
            indexGapLarge={true}
            updateHintState={(index) => {
              dispatch(
                updateHintStateToNext({
                  index,
                  activeHintPanel,
                }),
              );

              socket.emit(SOCKET_EVENTS.CHESS_HINT_ITEM_STATE_UPDATE, {
                index,
                activeHintPanel,
              });
            }}
            toggleHintPanels={[
              () => {
                dispatch(toggleHintPanel(CHESS_HINT_PANELS.WHITE));
              },
              () => {
                dispatch(toggleHintPanel(CHESS_HINT_PANELS.BLACK));
              },
            ]}
          />
        </div>
        <div
          className="chess_right"
          style={{
            pointerEvents: !inControl ? "none" : "auto",
          }}
        >
          {puzzleSolved && (
            <div
              className={`chess_solved_popup ${solvedPuzzleAnimationDisabled ? "" : "chess_solved_popup_animation"}`}
            >
              <h1>The revolution begins with the Queen</h1>
            </div>
          )}
          <div className="chess_white_pieces chess_pieces"></div>
          <div className="chess_grid_container" ref={chessBoardRef}>
            {Object.keys(pieceData)
              .slice(0, 16)
              .map((pieceId) => (
                <ChessPiece
                  key={`whiteChessPiece_${pieceId}`}
                  pieceId={pieceId}
                  draggableReferences={draggableReferences}
                  handleDrag={handleDrag}
                  handleStart={handleStart}
                  handleStop={handleStop}
                  syncComplete={syncComplete}
                  color="white"
                />
              ))}
            {Object.keys(pieceData)
              .slice(16)
              .map((pieceId) => (
                <ChessPiece
                  key={`blackChessPiece_${pieceId}`}
                  pieceId={pieceId}
                  draggableReferences={draggableReferences}
                  handleDrag={handleDrag}
                  handleStart={handleStart}
                  handleStop={handleStop}
                  syncComplete={syncComplete}
                  color="black"
                />
              ))}
            {Array(rows)
              .fill(0)
              .map((row, rowIndex) =>
                Array(columns)
                  .fill(0)
                  .map((column, columnIndex) => (
                    <div
                      className={`chess_grid_cell ${gridData[`${rowIndex}X${columnIndex}`].highlightRed ? "chess_grid_cell_piece_wrong" : ""} ${gridData[`${rowIndex}X${columnIndex}`].piece && pieceData[gridData[`${rowIndex}X${columnIndex}`].piece].isFixed ? "chess_grid_cell_piece_fixed" : ""}`}
                      style={
                        rowIndex % 2 === 0
                          ? columnIndex % 2 === 0
                            ? { backgroundColor: "#e2d28ec9" }
                            : { backgroundColor: "#7f8944cc" }
                          : columnIndex % 2 === 0
                            ? { backgroundColor: "#7f8944cc" }
                            : { backgroundColor: "#e2d28ec9" }
                      }
                      key={`chess_grid_${rowIndex}X${columnIndex}`}
                    >
                      {/* DEBUG */}
                      {/* <h2>
                        {rowIndex}X{columnIndex}
                      </h2> */}
                      {gridData[`${rowIndex}X${columnIndex}`].cellNum && (
                        <span
                          className="chess_cellNum"
                          style={{
                            color:
                              rowIndex % 2 === 0
                                ? columnIndex % 2 === 0
                                  ? "#7f8944cc"
                                  : "#e2d28ec9"
                                : columnIndex % 2 === 0
                                  ? "#e2d28ec9"
                                  : "#7f8944cc",
                          }}
                        >
                          {gridData[`${rowIndex}X${columnIndex}`].cellNum}
                        </span>
                      )}

                      {gridData[`${rowIndex}X${columnIndex}`].cellId && (
                        <span
                          className="chess_cellId"
                          style={{
                            color:
                              rowIndex % 2 === 0
                                ? columnIndex % 2 === 0
                                  ? "#7f8944cc"
                                  : "#e2d28ec9"
                                : columnIndex % 2 === 0
                                  ? "#e2d28ec9"
                                  : "#7f8944cc",
                          }}
                        >
                          {gridData[`${rowIndex}X${columnIndex}`].cellId}
                        </span>
                      )}
                    </div>
                  )),
              )}
          </div>
          <div className="chess_black_pieces chess_pieces"></div>
        </div>
      </div>
    </div>
  );
}

export default ChessPuzzle;
