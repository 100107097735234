import React from "react";
import { useNavigate } from "react-router-dom";

import "./InstructionPop.scss";

function InstructionPop({
  redirectTo = "",
  dataArray = [],
  onComplete = () => {},
}) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const navigate = useNavigate();

  const handleNext = () => {
    if (currentIndex === dataArray.length - 1) {
      if (redirectTo) {
        navigate(redirectTo);
      } else {
        onComplete();
      }
    }
    setCurrentIndex(currentIndex + 1);
  };

  const handlePrev = () => {
    setCurrentIndex(currentIndex - 1);
  };

  return (
    <div
      className={`instructionPop ${dataArray[currentIndex].img ? "instructionPop_wide" : "instructionPop_small"}`}
    >
      <div
        className="instructionPop_content"
        style={{
          justifyContent: dataArray[currentIndex].img
            ? "space-between"
            : "center",
        }}
      >
        {dataArray[currentIndex].img && (
          <div className="instructionPop_content_img">
            <img src={dataArray[currentIndex].img} alt="instruction" />
          </div>
        )}
        {dataArray[currentIndex].title && (
          <div className="instructionPop_content_title">
            {dataArray[currentIndex].title}
          </div>
        )}
        <div className="instructionPop_content_description">
          {dataArray[currentIndex].description}
        </div>
      </div>
      <div className="instructionPop_progress">
        <div className="instructionPop_progress_bar">
          <div
            className="instructionPop_progress_bar_fill"
            style={{
              width: `${(currentIndex + 1) * (100 / dataArray.length)}%`,
            }}
          ></div>
        </div>
      </div>
      <div className="instructionPop_next">
        {currentIndex === 0 ? null : (
          <div
            className="instructionPop_next_button common_btn"
            onClick={handlePrev}
          >
            Back
          </div>
        )}
        <div
          className="instructionPop_next_button common_btn"
          onClick={handleNext}
        >
          {currentIndex === dataArray.length - 1 ? "Start" : "Next"}
        </div>
      </div>
    </div>
  );
}

export default InstructionPop;
