import { ROUTES, SOCKET_EVENTS, VIDEO_ROUTES } from "Constants/Common";
import { SocketContext } from "context/socket";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  setCombinationLockOneSolved,
  setCombinationLockTwoSolved,
} from "Store/StageTwo";
import {
  updateInControl,
  updateInControlTwo,
  updateParticipants,
} from "Store/Common";
import { useNavigate } from "react-router-dom";
import { setMazeCompelete, setStatus } from "Store/StageFour";

function Socket() {
  const socket = useContext(SocketContext);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    // COMMON SOCKET EVENTS
    socket.on(SOCKET_EVENTS.CONNECT, () => {
      // parse connection recovery data from session storage
      const socket_room_data = JSON.parse(
        sessionStorage.getItem("socket_room_data"),
      );

      // if recovery data exists
      if (socket_room_data) {
        console.debug("REJOINING ROOM");
        if (socket_room_data.expiresAt > Date.now()) {
          // emit rejoin room
          socket.emit(
            SOCKET_EVENTS.REJOIN_ROOM,
            {
              eventId: socket_room_data.eventId,
              roomCode: socket_room_data.roomCode,
              eventCode: socket_room_data.eventCode,
            },
            (data) => {
              if (data.error) {
                console.log(
                  "ERROR IN REJOINING ROOM",
                  socket_room_data.roomCode,
                  data.error,
                );
                // TODO change this to a toast message
                alert("ERROR IN CONNECTING TO SERVER, PLEASE RE LOGIN");
                sessionStorage.removeItem("socket_room_data");
                navigate(ROUTES.LOGIN);
                return;
              }

              console.debug("REJOINGED ROOM: ", socket_room_data.roomCode);
            },
          );
        } else {
          // clear session storage
          sessionStorage.removeItem("socket_room_data");
          alert(
            "Room has expired as you have passed the 3 hour limit. Please login again.",
          );
          console.debug("ROOM EXPIERED:", socket_room_data.roomCode);
          navigate(ROUTES.LOGIN);
        }
      }
    });

    socket.on(SOCKET_EVENTS.TAKE_CONTROL, (data) => {
      dispatch(
        updateInControl({
          inControl: false,
          inControlPlayerName: data.playerName,
          participants: data.participants,
        }),
      );
    });

    socket.on(SOCKET_EVENTS.TAKE_CONTROL_TWO, (data) => {
      dispatch(
        updateInControlTwo({
          inControlTwo: false,
          inControlPlayerNameTwo: data.playerName,
          participants: data.participants,
        }),
      );
    });

    // STAGE TWO SOCKET EVENTS
    socket.on(SOCKET_EVENTS.COMBINATION_LOCK_ONE_SOLVED, () => {
      dispatch(setCombinationLockOneSolved(true));
    });

    socket.on(SOCKET_EVENTS.COMBINATION_LOCK_TWO_SOLVED, () => {
      dispatch(setCombinationLockTwoSolved(true));
    });

    socket.on(SOCKET_EVENTS.PARTICIPANT_JOINED, (data) => {
      // update participants
      dispatch(updateParticipants({ participants: data }));
    });

    socket.on(SOCKET_EVENTS.PATH_UPDATE, (path) => {
      // ignore previous paths and is current path is same as new path

      if (path === window.location.pathname) return;
      if (path === "/") return;
      if (path === ROUTES.LOGIN) return;
      if (path === ROUTES.WAITING_SCREEN) return;
      if (path === ROUTES.CROSSWORD) return;
      if (path === ROUTES.CHESS) return;
      if (
        path === ROUTES.STAGE_TWO ||
        path === ROUTES.CHESS ||
        path === ROUTES.CROSSWORD
      ) {
        if (
          window.location.pathname === ROUTES.STAGE_TWO ||
          window.location.pathname === ROUTES.CHESS ||
          window.location.pathname === ROUTES.CROSSWORD
        ) {
          // if current path is stage two and new path is also stage one or sub stage of stage two, return
          return;
        }
      }

      if (
        path === ROUTES.MAZE_ONE ||
        path === ROUTES.MAZE_TWO ||
        path === ROUTES.MAZE_THREE ||
        path === ROUTES.MAZE
      ) {
        if (
          window.location.pathname === ROUTES.MAZE_ONE ||
          window.location.pathname === ROUTES.MAZE_TWO ||
          window.location.pathname === ROUTES.MAZE_THREE ||
          window.location.pathname === ROUTES.MAZE
        ) {
          // if current path is maze and new path is also maze or sub stage of maze, return
          return;
        }
      }

      navigate(path);
    });

    socket.on(SOCKET_EVENTS.STAGE_FOUR_MAZE_SOLVED, (data) => {
      dispatch(setMazeCompelete({ mazeId: data.mazeId }));
      dispatch(setStatus({ mazeId: data.mazeId, status: "won" }));
    });

    socket.on(SOCKET_EVENTS.STAGE_TWO_DOOR_UNLOCKED, () => {
      navigate(VIDEO_ROUTES.FIVE);
    });

    return () => {
      socket.off(SOCKET_EVENTS.CONNECT);
      socket.off(SOCKET_EVENTS.COMBINATION_LOCK_ONE_SOLVED);
      socket.off(SOCKET_EVENTS.COMBINATION_LOCK_TWO_SOLVED);
      socket.off(SOCKET_EVENTS.TAKE_CONTROL);
      socket.off(SOCKET_EVENTS.PARTICIPANT_JOINED);
      socket.off(SOCKET_EVENTS.PATH_UPDATE);
      socket.off(SOCKET_EVENTS.TAKE_CONTROL_TWO);
      socket.off(SOCKET_EVENTS.STAGE_FOUR_MAZE_SOLVED);
      socket.off(SOCKET_EVENTS.STAGE_TWO_DOOR_UNLOCKED);
    };
  }, [navigate, socket, dispatch]);

  useEffect(() => {
    // parse connection recovery data from session storage
    const socket_room_data = JSON.parse(
      sessionStorage.getItem("socket_room_data"),
    );

    const roomCode = socket_room_data?.roomCode;

    if (!roomCode) {
      return;
    }

    socket.emit(SOCKET_EVENTS.SYNC_GAME_STATE, { roomCode }, (data) => {
      if (data.error) {
        console.log("ERROR IN SYNCING GAME STATE", roomCode, data.error);
        // TODO change this to a toast message
        alert("ERROR IN CONNECTING TO SERVER, PLEASE RE LOGIN");
        return;
      }

      console.log("SYNCING GAME STATE", data);
    });
  }, [socket]);

  return null;
}

export default Socket;
