export const StageTwoImages = {
  base_image:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Stage%202%20Base%20Image%20(Ranulf%20was%20here).jpg",
  chess_board_highlighted:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Base%20Image%20(Highlighted%20Chess%20Board)%20(Compressed).png",
  chest_highlighted:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Base%20Image%20(Highlighted%20Chest)%20(Compressed).png",
  table_highlighted:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Base%20Image%20(Highlighted%20Table)%20(Compressed).png",
  door_highlighted:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Stage%202%20Base%20Image%20(Ranulf%20was%20here)%20Highlighted%20Door%20(Compressed).png",
  table_zoom_base:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Stage%202%20Table%20Zoom%20(Compressed).jpg",
  table_zoom_papers_highlighted:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Stage%202%20Table%20Zoom%20(Highlighted%20Papers)%20(Compressed).png",
  table_zoom_box_highlighted:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Stage%202%20Table%20Zoom%20(Highlighted%20Box)%20(Compressed).png",

  // locks
  combinationLockBackground:
    "https://storage.googleapis.com/stq_assets/images/combination_lock/Background%20(Compressed).png",
};

export const CrosswordImages = {
  left_panel_bg:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Left%20Panel.png",
  right_panel_bg:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Right%20Panel%20(Compressed).png",
  hintBoard_bg:
    "https://storage.googleapis.com/stq_assets/images/crossword/hintboard_bg%20(Compressed)png.png",
  key_one:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Medieval%20Key%201%20(Gold).png",
  key_two:
    "https://storage.googleapis.com/stq_assets/images/stage_two/Medieval%20Key%202%20(Silver).png",
};

export const ChessPuzzleImages = {
  chess_pieces: {
    black: {
      bishop:
        "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/black_bishop.png",
      king: "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/black_king.png",
      knight:
        "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/black_knight.png",
      pawn: "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/black_pawn.png",
      queen:
        "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/black_queen.png",
      rook: "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/black_rook.png",
    },
    white: {
      bishop:
        "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/white_bishop.png",
      king: "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/white_king.png",
      knight:
        "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/white_knight.png",
      pawn: "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/white_pawn.png",
      queen:
        "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/white_queen.png",
      rook: "https://storage.googleapis.com/stq_assets/images/chess_puzzle/Chess%20Pieces/white_rook.png",
    },
  },
};

export const DrawTheKingImages = {
  king: "https://storage.googleapis.com/stq_assets/images/draw_the_king/Painting%20(Compressed).png",
  eraser:
    "https://storage.googleapis.com/stq_assets/images/draw_the_king/earase.png",
};
