import React from "react";

import "./TheEnd.scss";

function TheEnd() {
  return (
    <>
      <div className="endScreen">
        <h1>THE END</h1>
      </div>
    </>
  );
}

export default TheEnd;
