import React, { useContext, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "context/socket";
import { SOCKET_EVENTS } from "Constants/Common";
import { updateInControl } from "Store/Common";

import "./TakeControl.scss";

function TakeControl({
  topBtn = 0,
  leftBtn = 0,
  topHeading = 0,
  leftHeading = 0,
  position = "absolute",
  dark = false,
}) {
  const { inControl, inControlPlayerName, playerName, isHost, participants } =
    useSelector((state) => state.common);

  const socket = useContext(SocketContext);

  const dispatch = useDispatch();

  const handleTakeControl = () => {
    socket.emit(
      SOCKET_EVENTS.TAKE_CONTROL,
      {
        playerName,
        inControl: true,
        participants: participants.length
          ? participants.map((participant) => participant)
          : [playerName],
      },
      (data) => {
        if (data.error) {
          console.error(data.error);
          return;
        }

        dispatch(
          updateInControl({
            inControl: true,
            inControlPlayerName: playerName,
          }),
        );
      },
    );
  };

  useEffect(() => {
    // parse connection recovery data from session storage
    const socket_room_data = JSON.parse(
      sessionStorage.getItem("socket_room_data"),
    );

    const roomCode = socket_room_data?.roomCode;

    socket.emit(SOCKET_EVENTS.SYNC_TAKE_CONTROL, { roomCode }, (data) => {
      if (data.error) {
        console.error(data.error);
        return;
      }

      dispatch(
        updateInControl({
          inControl: data.data.inControl === playerName,
          inControlPlayerName: data.data.inControl,
        }),
      );
    });
  }, [socket, inControl, playerName, dispatch]);

  return (
    <div
      className="takeControlInfoAndBtn"
      style={{
        position,
        top: topBtn,
        left: leftBtn,
      }}
    >
      <div
        className={`common_btn takeControlBtn ${inControl || isHost ? "takeControlBtnDisbaled" : ""}`}
        onClick={handleTakeControl}
      >
        Take Control
      </div>
      <div
        className={`takeControlInfo ${dark ? "takeControlInfoDark" : ""}`}
        style={{
          top: topHeading,
          left: leftHeading,
        }}
      >
        <span className="takeControlInfo_label">Currently in control</span>
        <span
          className={`takeControlInfo_value ${dark ? "takeControlInfo_value_dark" : ""}`}
        >
          {inControlPlayerName}
          {inControl ? " (Me)" : ""}
        </span>
      </div>
    </div>
  );
}

export default TakeControl;
