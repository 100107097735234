export const checkIsInside = (draggable, target) => {
  const draggableRect = draggable.getBoundingClientRect();
  const targetRect = target.getBoundingClientRect();

  return (
    draggableRect.top >= targetRect.top &&
    draggableRect.left >= targetRect.left &&
    draggableRect.right <= targetRect.right &&
    draggableRect.bottom <= targetRect.bottom
  );
};

export const checkIsOverlapping = (elementA, elementB) => {
  const rectA = elementA.getBoundingClientRect();
  const rectB = elementB.getBoundingClientRect();

  // Check for horizontal overlap
  const horizontalOverlap =
    rectA.left < rectB.right && rectA.right > rectB.left;

  // Check for vertical overlap
  const verticalOverlap = rectA.top < rectB.bottom && rectA.bottom > rectB.top;

  // Return true if there's overlap both horizontally and vertically
  return horizontalOverlap && verticalOverlap;
};

export const getNextChar = (char) => {
  if (char >= "A" && char <= "Y") {
    // From 'A' to 'Y', move to the next letter
    return String.fromCharCode(char.charCodeAt(0) + 1);
  } else if (char === "Z") {
    // From 'Z' go to '0'
    return "0";
  } else if (char >= "0" && char <= "8") {
    // From '0' to '8', move to the next number
    return String.fromCharCode(char.charCodeAt(0) + 1);
  } else if (char === "9") {
    // From '9' go back to 'A'
    return "A";
  } else {
    return char; // Handle non-expected characters
  }
};

export const getPrevChar = (char) => {
  if (char >= "B" && char <= "Z") {
    // From 'B' to 'Z', move to the previous letter
    return String.fromCharCode(char.charCodeAt(0) - 1);
  } else if (char === "A") {
    // From 'A' go to '9'
    return "9";
  } else if (char >= "1" && char <= "9") {
    // From '1' to '9', move to the previous number
    return String.fromCharCode(char.charCodeAt(0) - 1);
  } else if (char === "0") {
    // From '0' go to 'Z'
    return "Z";
  } else {
    return char; // Handle non-expected characters
  }
};
