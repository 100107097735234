import React from "react";

import "./CombinationLockSolvedPopup.scss";

function CombinationLockSolvedPopup({ message, image, onClose }) {
  return (
    <div className="combinationLockSolvedPopup">
      <h3>{message}</h3>
      <img src={image} alt="" />
      <div
        className="combinationLockSolvedPopup_closeBtn common_btn"
        onClick={onClose}
      >
        OK
      </div>
    </div>
  );
}

export default CombinationLockSolvedPopup;
