import { useSelector } from "react-redux";

import TakeControl from "Components/TakeControl/TakeControl";

import "./HintBoard.scss";
import TakeControlTwo from "Components/TakeControlTwo/TakeControlTwo";

function HintBoard({
  data,
  hintBoardTwo,
  activeHintPanel,
  hintPanels,
  toggleHintPanels,
  updateHintState,
  indexGapLarge = false,
}) {
  const { inControl, inControlTwo } = useSelector((state) => state.common);

  return (
    <div className="hintboard">
      {hintBoardTwo ? (
        <TakeControlTwo
          topBtn={"6%"}
          leftBtn={"12%"}
          topHeading={"-35%"}
          leftHeading={"0%"}
          dark={true}
          position=""
        />
      ) : (
        <TakeControl
          topBtn={"6%"}
          leftBtn={"12%"}
          topHeading={"-35%"}
          leftHeading={"0%"}
          dark={true}
          position=""
        />
      )}
      <div className="hintbaord_active_panel">
        <h2 className="hintboard_active_panel_text">
          {activeHintPanel[0].toUpperCase() + activeHintPanel.slice(1)}
        </h2>
      </div>
      <div className="hints_container">
        {data[activeHintPanel].map((hint, index) => {
          return (
            <div
              key={`hintBoard_${index}`}
              className={`hint hint_state_${hint.state} ${
                !(hintBoardTwo ? inControlTwo : inControl)
                  ? "hint_State_DISBALED"
                  : ""
              } ${hint.isLocked ? "hint_State_LOCKED" : ""}`}
              onClick={() => {
                if (hint.isLocked) return;
                updateHintState(index);
              }}
              style={
                !(hintBoardTwo ? inControlTwo : inControl)
                  ? {
                      pointerEvents: "none",
                    }
                  : {}
              }
            >
              <span
                className={`hint_index ${indexGapLarge ? "hint_index_gap_large" : "hint_index_gap_small"}`}
              >
                {hint.index}
              </span>
              <span className="hint_text">{hint.text}</span>
            </div>
          );
        })}
      </div>
      <div className="hintboard_btn_container">
        {hintPanels.map((panel, index) => {
          return (
            <div
              key={`hintPanel_${index}`}
              className="hintboard_btn common_btn_secondary"
              onClick={toggleHintPanels[index]}
              style={
                activeHintPanel === panel ? { backgroundColor: "#7f460d" } : {}
              }
            >
              {panel[0]?.toUpperCase() + panel.slice(1)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HintBoard;
