import { ROUTES } from "Constants/Common";

export const videosNavigater = {
  one: {
    onEndPath: ROUTES.STAGE_ONE,
  },
  two: {
    onEndPath: ROUTES.STAGE_ONE_KARAOKE,
  },
  three: {
    onEndPath: ROUTES.STAGE_ONE_KARAOKE,
  },
  four: {
    onEndPath: ROUTES.STAGE_TWO,
  },
  five: {
    onEndPath: ROUTES.DRAW_THE_KING,
  },
  six: {
    onEndPath: ROUTES.DRAW_THE_KING,
  },
  seven: {
    onEndPath: ROUTES.THE_END,
    hideContinueButton: true,
  },
  eight: {
    onEndPath: ROUTES.MAZE,
  },
  nine: {
    onEndPath: ROUTES.THE_END,
    hideContinueButton: true,
  },
  ten: {
    onEndPath: ROUTES.MAZE,
  },
  eleven: {
    onEndPath: ROUTES.THE_END,
    hideContinueButton: true,
  },
  twelve: {
    onEndPath: ROUTES.THE_END,
    hideContinueButton: true,
  },
  thirteen: {
    onEndPath: ROUTES.THE_END,
    hideContinueButton: true,
  },
};
