export const RESOLUTION = {
  ROWS: 50,
  COLS: 50,
};

export const MESSAGES = {
  CLEAR_POPUP: {
    MESSAGE: "Be Warned",
    SUBMESSAGE:
      "You are about to clear the entire board. Are you sure you want to do this?",
    OK_BUTTON_TEXT: "Yes",
  },
  CONFIRM_SUBMIT_POPUP: {
    MESSAGE: "Submit Drawing",
    SUBMESSAGE: "Are you sure you want to submit your drawing?",
    OK_BUTTON_TEXT: "Yes",
    CANCEL_BUTTON_TEXT: "No",
  },
  SUBMIT_POPUP: {
    MESSAGE: "Grade the Art!",
    SUBMESSAGE:
      "Accept the drawing if you think it's good, reject it if you think it's bad.",
    OK_BUTTON_TEXT: "Accept",
    CANCEL_BUTTON_TEXT: "Reject",
  },
  ALLOW_STAGE_FOUR_POPUP: {
    MESSAGE: "What's Next?",
    SUBMESSAGE: "Do you want to proceed to Stage 4 or finish the game?",
    OK_BUTTON_TEXT: "Finish Game",
    CANCEL_BUTTON_TEXT: "Go to Stage 4",
  },
  SCREENSHOT_NUDGE_POPUP: {
    MESSAGE: "Reminder",
    SUBMESSAGE:
      "Now, please take a screenshot of the artwork and then click the Continue button in the bottom right-hand corner of the screen.",
    OK_BUTTON_TEXT: "Ok",
  },
};
